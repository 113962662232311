import React, { useEffect, useState } from "react";
import "./News.css";
import Header from "../components/header";
import Footer from "../components/footer";
import { Fade } from "react-awesome-reveal";


const News = () => {
  const newsData = [
  
    {
      title:
        "Garuda Aerospace Partners with Zuppa to Strengthen India’s Secure Drone Industry",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741344517/ybva9ghk3pzt7urfbgp7.png",
      content:
        "Garuda Aerospace Partners with Zuppa to Strengthen India’s Secure Drone Industry",
      link: "https://apacnewsnetwork.com/2025/03/garuda-aerospace-partners-with-zuppa-to-strengthen-indias-secure-drone-industry/",
      date: "06.03.2025",
    },


    {
      title:
        "Garuda Aerospace Invests In Map my India-Backed Zuppa",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741344942/wcl96ihlj5bdqyljnh6a.png",
      content:
        "Garuda Aerospace Invests In Map my India-Backed Zuppa",
      link: "https://inc42.com/buzz/garuda-aerospace-invests-in-mapmyindia-backed-zuppa/",
      date: "06.03.2025",
    },


    {
      title:
        "Drone company Garuda invests in Zuppa geo Navigation",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741345407/sgp5hbagnlljncshw2hs.png",
      content:
        "Drone company Garuda invests in Zuppa",
      link: "https://timesofindia.indiatimes.com/business/india-business/drone-company-garuda-invests-in-zuppa/articleshow/118765304.cms",
      date: "06.03.2025",
    },


    {
      title:
        "MS Dhoni Backed Garuda Aerospace Invests in Indigenous Defence Drone Startup Zuppa",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741345936/l6w5rjczsuydqsyavwac.png",
      content:
        "MS Dhoni Backed Garuda Aerospace Invests in Indigenous Defence Drone Startup Zuppa",
      link: "https://timestech.in/ms-dhoni-backed-garuda-aerospace-invests-in-indigenous-defence-drone-startup-zuppa/",
      date: "06.03.2025",
    },


    {
      title:
        "Drone-tech company Zuppa eyes $16 mn fundraising",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738735863/ta1x1cc4fyau8e4ejln1.jpg",
      content:
        "Drone-tech company Zuppa eyes $16 mn fundraising",
      link: "https://timesofindia.indiatimes.com/business/india-business/drone-tech-company-zuppa-eyes-16-mn-fundraising/articleshow/118766123.cms",
      date: "06.03.2025",
    },


    {
      title:
        "Garuda Aerospace invests in defence drone start-up Zuppa",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741350346/emmslv8xhsab4nlxyquz.png",
      content:
        "Garuda Aerospace invests in defence drone start-up Zuppa",
      link: "https://www.thehindu.com/business/garuda-aerospace-invests-in-defence-drone-start-up-zuppa/article69299741.ece",
      date: "06.03.2025",
    },


    {
      title:
        "Dhoni-backed Garuda Aerospace invests in  Chennai-based start-up specialising in cyber-secure drones",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741350711/ddgsxacyybdz6d7gvnbe.png",
      content:
        "Dhoni-backed Garuda Aerospace invests in  Chennai-based start-up specialising in cyber-secure drones",
      link: "https://www.thehindu.com/news/national/tamil-nadu/dhoni-backed-garuda-aerospace-invests-in-zuppa/article69299095.ece",
      date: "06.03.2025",
    },


    {
      title:
        "Garuda Aerospace, Zuppa Partner to Advance Secure Drone Technology",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741348817/vvfeqkrllghggyorxdis.jpg",
      content:
        "Garuda Aerospace, Zuppa Partner to Advance Secure Drone Technology",
      link: "https://analyticsindiamag.com/ai-news-updates/garuda-aerospace-zuppa-partner-to-advance-secure-drone-technology/",
      date: "06.03.2025",
    },


    {
      title:
        "MS Dhoni Backed Garuda Aerospace Invests in Indigenous Defence Drone Startup Zuppa",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741350711/ddgsxacyybdz6d7gvnbe.png",
      content:
        "MS Dhoni Backed Garuda Aerospace Invests in Indigenous Defence Drone Startup Zuppa",
      link: "https://www.apnnews.com/ms-dhoni-backed-garuda-aerospace-invests-in-indigenous-defence-drone-startup-zuppa/",
      date: "06.03.2025",
    },



    {
      title:
        "Garuda Aerospace Partners with Zuppa for Indigenous Drone Innovation",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741347714/q0rimn6ofc1nrea3m5im.png",
      content:
        "Garuda Aerospace Partners with Zuppa for Indigenous Drone Innovation",
      link: "https://smestreet.in/sectors/garuda-aerospace-partners-with-zuppa-for-indigenous-drone-innovation-8826793",
      date: "06.03.2025",
    },



    {
      title:
        "Garuda Aerospace Invests in Indigenous Defence Drone Startup Zuppa",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741347738/gxmr8ksuvku75d5p5sqe.png",
      content:
        "Garuda Aerospace Invests in Indigenous Defence Drone Startup Zuppa",
      link: "https://raksha-anirveda.com/garuda-invests-in-defence-drone-startup-zuppa/",
      date: "06.03.2025",
    },


    {
      title:
        "Garuda Aerospace & ZUPPA’s Strategic partnership marks the beginning of a new era of cooperative growth among key Indian drone companies",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738735863/ta1x1cc4fyau8e4ejln1.jpg",
      content:
        "Garuda Aerospace & ZUPPA’s Strategic partnership marks the beginning of a new era of cooperative growth among key Indian drone companies",
      link: "https://www.indiastrategic.in/garuda-aerospace-zuppas-strategic-partnership-marks-the-beginning-of-a-new-era-of-cooperative-growth-among-key-indian-drone-companies/",
      date: "06.03.2025",
    },

    {
      title:
        "Garuda Aerospace & ZUPPA’s Strategic partnership marks the  beginning of a new era of cooperative growth among key Indian drone companies",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741348403/auif2poc3oqrstdnjufq.png",
      content:
        "Garuda Aerospace & ZUPPA’s Strategic partnership marks the  beginning of a new era of cooperative growth among key Indian drone companies",
      link: "https://cxotoday.com/press-release/garuda-aerospace-zuppas-strategic-partnership-marks-the-beginning-of-a-new-era-of-cooperative-growth-among-key-indian-drone-companies/",
      date: "06.03.2025",
    },


    {
      title:
        "MS Dhoni-backed Garuda Aerospace invests in defence drone startup Zuppa",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738735863/ta1x1cc4fyau8e4ejln1.jpg",
      content:
        "MS Dhoni-backed Garuda Aerospace invests in defence drone startup Zuppa",
      link: "https://yourstory.com/2025/03/startup-news-updates-daily-roundup-march-6-2025",
      date: "06.03.2025",
    },
  
  
  
    {
      title:
        "In the rapidly evolving world of drone technology, Mr. Venkatesh Sai, Founder and Technical Director",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738417317/wnsc3ujy7imsaqaqggg5.jpg",
      content:
        "In the rapidly evolving world of drone technology, Mr. Venkatesh Sai...",
      link: "https://sugermint.com/venkatesh-sai/",
      date: "01.03.2025",
    },

    {
      title:
        "Over the past year, Indian Army’s drones procured under the ‘Make in India’ initiative have been hacked in border areas, according to a report.",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741002715/omujrucdojtljwk1oe8u.avif",
      content:
        "Over the past year, Indian Army’s drones procured under the ‘Make in India’ initiative have been hacked in border areas, according to a report.",
      link: "https://www.firstpost.com/india/indian-army-make-in-india-drones-hacked-in-border-areas-report-13859474.html",
      date: "04.02.2025",
    },


    {
      title:
        "Union Budget 2025 Expectations highlights: Halwa ceremony to be held today in North BlocK",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738415874/k5zye0afv6bomyw449nk.webp",
      content:
        "Zuppa Geo Navigations Pvt. Ltd, said, The demand for secure non-Chinese drones...",
      link: "https://orgecs.business-standard.com/budget/news/union-budget-2025-expectations-live-updates-nirmala-sitharaman-tax-education-india-latest-news-125012400281_1.html",
      date: "01.02.2025",
    },
    {
      title:
        "Chinese-made components in Indian drones being supplied to the army runs the risk of compromising national security through the hacking of data.",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738415758/zypsvc4w9ogvaqr5wlnz.avif",
      content:
        "The use of Chinese-made components in Indian drones being supplied to the army runs the risk",
      link: "https://www.indiatoday.in/magazine/defence/story/20250210-chinese-threat-in-indian-drones-2672839-2025-01-31",
      date: "31.01.2025",
    },

    {
      title:
        "What Indian A&M, Tech & M&E Industry Want From Nirmala Sitharaman’s Upcoming Union Budget",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738566772/rblmwagetojpjddqbrxn.avif",
      content:
        "Sai Pattabiram, Founder and MD, Zuppa Geo Navigation Technologies stated that the",
      link: "https://marketingmind.in/what-am-tech-industry-want-from-union-budget/",
      date: "31.01.2025",
    },

    {
      title: "Drone hijacking threats and India’s national security: The case for indigenous technology.In terms of technology, Zuppa is contemporary to global players...",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738416137/cznmr2aaq1l0mgw8uasl.png",
      content:
        "When comparing Zuppa drones to those of Israeli and Turkish manufacturers, Pattabiram notes..",
      link: "https://www.financialexpress.com/business/defence-drone-hijacking-threats-and-indias-national-security-the-case-for-indigenous-technology-3703956/",
      date: "31.01.2025",
    },

    {
      title: "Areas That Need Boost in the Auto and Drone Sector",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738416233/oeddnxmbldtyawd5krjx.jpg",
      content:
        "India has the opportunity to leverage its domestic consumption and global reputation",
      link: "https://www.ceoinsightsindia.com/business-inside/budget-2025-areas-that-need-boost-in-the-auto-and-drone-sector-nwid-19951.html",
      date: "31.01.2025",
    },


    {
      title:
        "Indian startups seek tax reforms, infrastructure development, and more from Nirmala Sitharaman’s Budget 2025.",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738416462/ihmmgv6d21jvjkahclnc.avif",
      content:
        "The demand for secure non-Chinese drones is growing the world over and more so in India.. ",
      link: "https://yourstory.com/2025/01/startups-tax-reforms-infra-development-nirmala-sitharaman-budget",
      date: "31.01.2025",
    },



    {
      title:
        "Expectations of Tech Leaders from Union Budget to Drive Digital Transformation.The demand for secure non-Chinese drones is growing the world over and more so in India",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738396175/ydh4cc48y0yc0sgmkxpw.png",
      content:
        "Expectations of Tech Leaders from Union Budget 2025 to Drive Digital Transformation",
      link: "https://digitalterminal.in/trending/expectations-of-tech-leaders-from-union-budget-2025-to-drive-digital-transformation",
      date: "30.01.2025",
    },

 
    {
      title:
        "A Design linked Incentive ( DLI ) coupled with a PLI that supports use of only Domestically produced components by OEMs will boost the sector in a big way.",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738735863/ta1x1cc4fyau8e4ejln1.jpg",
      content:
        "Expectations of Tech Leaders from Union Budget 2025 to Drive Digital Transformation",
      link: "https://www.aviation-defence-universe.com/industry-reacts-to-budget-2025-2026/",
      date: "29.01.2025",
    },


    {
      title:
        "Drone cyber security industry eyes major pie in the sky.As the industry awaits the Union Budget 2025..",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738416567/uxx4g5jtozgtpodwbsmi.webp",
      content:
        "As the industry awaits the Union Budget 2025, we anticipate strategic initiatives that will..",
      link: "https://www.bizzbuzz.news/technology/drone-cyber-security-industry-eyes-major-pie-in-the-sky-1350016",
      date: "27.01.2025",
    },

    {
      title:
        "Union Budget 2025: Tech experts outline key expectations for a digital-first economy",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738416691/ffnzm5hz6p507xhhbd9f.jpg",
      content:
        "The Government should recognise this huge National as well as global opportunity and",
      link: "https://etedge-insights.com/in-focus/budget-2025/union-budget-2025-tech-experts-outline-key-expectations-for-a-digital-first-economy/",
      date: "27.01.2025",
    },

    {
      title:
        "Strengthening defence, innovation, and Web3 leadership for India’s growth",
      image: "https://shop.zuppa.io/drone-slide3crop.png",
      content:
        "Strengthening defence, innovation, and Web3 leadership for India’s growth",
      link: "https://www.financialexpress.com/budget/budget-2025-expectations-strengthening-defence-innovation-and-web3-leadership-for-indias-growth-3722805/lite/",
      date: "23.01.2025",
    },

    {
      title:
        "What Industry Leaders Anticipate from Union Budget 2025.The demand for secure non Chinese drones is growing the world over and more so in India .",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738416861/haayrpuw4z567cbmoyzb.webp",
      content:
        "The demand for secure non Chinese drones is growing the world over and more so in India.",
      link: "https://techachievemedia.com/business-insights/what-industry-leaders-anticipate-from-union-budget-2025/",
      date: "23.01.2025",
    },

    {
      title:
        "Exploring Sectoral Expectations for Union Budget. This can only be achieved by India moving deeper into the component level...",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738417066/kkfk7xi6oj2uqjwplihb.jpg",
      content:
        "The demand for secure non Chinese drones is growing the world over and more so in India.. ",
      link: "https://cxotoday.com/specials/industry-wishlist-exploring-sectoral-expectations-for-union-budget-2025-26/#google_vignette",
      date: "22.01.2025",
    },

    {
      title:
        "Budget 2025: What the education sector is expecting.The demand for secure non Chinese drones is growing the world over and more so in India . ",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738572953/cyahqybntlf1mmsqssmu.png",
      content:
        "The demand for secure non Chinese drones is growing the world over and more so in India..",
      link: "https://www.educationworld.in/budget-2025-what-the-education-sector-is-expecting/",
      date: "16.01.2025",
    },

    {
      title: "The Curious Case of Cyber Vulnerability of Drones",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738417317/wnsc3ujy7imsaqaqggg5.jpg",
      content:
        "The shock wave of the Hezbollah Pager attack has had a far deeper impact on the Indian security agencies on account",
      link: "https://www.cioinsiderindia.com/cxoinsider/the-curious-case-of-cyber-vulnerability-of-drones-nwid-6732.html#google_vignette",
      date: "08.01.2025",
    },

    {
      title:
        "Indian Army Strengthens Drone Security with Indigenous Cyber Defence Technology",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738417489/j8s4zua9uc9cngvfn4yh.png",
      content:
        "ZUPPA’s Suraksha technology represents a major leap forward in safeguarding India’s drone capabilities.",
      link: "https://www.cxodigitalpulse.com/indian-army-strengthens-drone-security-with-indigenous-cyber-defence-technology/",
      date: "26.12.2024",
    },

    {
      title:
        "After Hijacking of Indian Army Drone Near Pakistan Border—Zuppa Offers Unhijackable...",
      image: "https://shop.zuppa.io/drone-slide3crop.png",
      content:
        "Zuppa claims that its drones are hijack-proof because they are entirely manufactured",
      link: "https://www.indiandefensenews.in/2024/12/after-hijacking-of-indian-army-drone.html",
      date: "25.12.2024",
    },

    {
      title:
        "Indian Army’s strategic move to secure drones with homegrown cyber defence technology: ZUPPA Suraksha",
      image:
        "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735556352/aue4hguurmsygwgrxz0s.avif",
      content: "The Suraksha EMD is engineered to monitor and secure",
      link: "https://www.indiandefensenews.in/2024/12/after-hijacking-of-indian-army-drone.html",
      date: "25.12.2024",
    },

    {
      title:
        "After Hijacking Of Indian Army Drone Near Pakistan Border, Focus On Make-In-India...",
      image: "https://shop.zuppa.io/Trainer_dronecrop.jpeg",
      content:
        "zuppa geo navigation asserts that its drones, built entirely with indigenous parts, are impervious",
      link: "https://swarajyamag.com/news-brief/after-hijacking-of-indian-army-drone-near-pakistan-border-focus-on-make-in-india-without-chinese-partszuppa-offers-solution",
      date: "23.12.2024",
    },

    {
      title:
        "No Chinese parts, no worries: Indian drone maker Zuppa offers hijack-proof systems for India's defence",
      image: "https://shop.zuppa.io/drone-slide3crop.png",
      content:
        "Indian drone maker Zuppa claims its drones are hijack-proof due to no Chinese components",
      link: "https://www.theweek.in/news/defence/2024/12/23/zuppa-claims-its-indian-made-drones-are-hijack-proof-due-to-no-chinese-components-addressing-security-concerns-after-reports-of-pakistan-exploiting-chinese-tech-in-indian-drones.html",
      date: "23.12.2024",
    },
    {
      title:
        "Zuppa claims their drones entirely manufactured in India cannot be hijacked",
      image: "https://shop.zuppa.io/autopilots-slide3crop.png",
      content:
        "Indian army drone that was flying close to the border in the north was reportedly hijacked by Pakistani authorities.",
      link: "https://www.newindianexpress.com/nation/2024/Dec/23/zuppa-claims-their-drones-entirely-manufactured-in-india-cannot-be-hijacked",
      date: "23.12.2024",
    },

    {
      title:
        "चीन की हरकत से आगबबूला हुआ भारत, लगाएगा ड्रैगन की इस कंपनी पर बैन, जानें वजह",
      image: "https://shop.zuppa.io/drone-slide1crop.png",
      content:
        "एक भारतीय ड्रोन कंपनी ने चीनी संस्थाओं द्वारा अपनी ऑटोपायलट तकनीक के कॉपीराइट उल्लंघन का आरोप लगाया है. इसके बाद भारत सरकार से अपने ",
      link: "https://www.abplive.com/news/world/indian-drone-company-zuppa-geo-navigation-technology-claim-china-copy-their-autopilot-technique-2833916",
      date: "01.12.2024",
    },

    {
      title:
        "Indian Drone Company Alleges Copyright Infringement by Chinese Entities, Seeks Government Intervention",
      image: "https://shop.zuppa.io/autopilots-slide2crop.png",
      content:
        "In a significant development highlighting the challenges of intellectural propery (IP) rights protection...",
      link: "https://idrw.org/indian-drone-company-alleges-copyright-infringement-by-chinese-entities-seeks-government-intervention/",
      date: "29.11.2024",
    },
    {
      title: "Desi drone maker hums Chinese piracy Chennai-based Zuppa Geo Navigation Technology has written to Directorate General of Foreign Trade (DGFT), seeking a ban on imports from Chinese companies that..",
       image: "https://shop.zuppa.io/Ajeetscoutcrop.png",
      content:
        "Chennai-based Zuppa Geo Navigation Technology has written to Directorate General of Foreign Trade (DGFT)..",
      link: "https://economictimes.indiatimes.com/news/defence/desi-drone-maker-hums-chinese-piracy/articleshow/115742605.cms?from=mdr",
      date: "27.11.2024",
    },

    {
      title:
        "Building Secure Bharatiya Cyber Physical Drone Stack ( SEP 2024) Key to Atmanirbharta and Global Drone Hub Vision 2030",
      image: "https://shop.zuppa.io/defensebook.jpg",
      content:
        "Unmanned systems like drones are being deployed across battlefields the world over..",
      link: "https://www.idsa.in/wp-content/uploads/2024/11/16-jds-18-3-2024-Sai-Pattabiram.pdf",
      date: "26.11.2024",
    },


    // {
    //   title:
    //     "Indian Army's 'Make in India' drones hacked in border areas: Report.The news comes at a times when militaries across the world are using drones for intelligence-gathering, reconnaissance, and offensive purposes.",
    //   image: "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1738734980/wptf8ta3wwhmz73asf8i.avif",
    //   content:
    //     "Unmanned systems like drones are being deployed across battlefields the world over..",
    //   link: "https://www.firstpost.com/india/indian-army-make-in-india-drones-hacked-in-border-areas-report-13859474.html",
    //   date: "28.10.2024",
    // },





  ];

  return (
    <>
      <Header />
      <br/>
      <div className="newsevent-header text-center">
        <h2>Our Company in News</h2>
      </div>
      <br/>
 
 <Fade direction="left" duration={1800}>
 <div className="zuppa-news-container">
      {newsData.map((news, index) => (
        <div key={index} className="zuppa-news-card">
          <div className="zuppa-news-image-container">
            <img
              src={news.image}
              alt={news.title}
              className="zuppa-news-image"
            />
          </div>
          <h3 className="zuppa-news-title">{news.title}</h3>
          <hr className="gradient-line" />
          <div className="news-date-link">
            <p className="datenewspara">{news.date}</p>
            <a class="btn btn-outline-warning" target="_blank" href={news.link}>
              View More
            </a>
          </div>
        </div>
      ))}
    </div>

 </Fade>


<Footer/>
    </>
  );
};

export default News;

