

import React, { useEffect } from "react";
import Header from "../header";
import IIotPic from "../../assets/images/IIOT-pic.png";
import iiotlap from "../../assets/images/iiotlap.png";
import ZuppaTrackingSystem from "../../assets/images/ZUPPA Vehicle Tracking System.mp4";
import "./IIOT.css";
import Footer from "../footer";

const IIOT = () => {
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView({
      behavior: "smooth"
    }, 500);
  }, []);

  return (
 <>
    <div className="container">
      <Header />
      <div className="about-header text-center ">
        <h4>IIOT</h4>
        <h5>Internet Of Military Things IoMT</h5>
      </div>

      <div className="row">
        <div className="col-md-6 mt-4">
          <img className="iiot-content img-fluid" src={IIotPic} alt=""    style={{ height: "350px", width: "450px" }}/>
        </div>

        <div className="col-md-6 iiot mt-4">
          <h3>IIOT</h3>
          <br />
          <p>Zuppa Edge</p>
          <p>PCBS: Fully Assembled PCB with Firmware preloaded: MOQ:1000 pcs</p>
          <p>White Labeled product: Box Assembled Product without SIM: MOQ:500 pcs</p>
          <p>Cable Harness Design</p>
          <p>Box Design</p>
          <p>CAN / OBD2 Enabled Expansion Option</p>
        </div>
      </div>
      <br />

      <div className="row">
        <div className="col-md-6 iiot">
          <h3>SPECIFICATION</h3>
          <br />
          <p>Chipset = STM v7 ARM - x32 processor</p>
          <p>Operating System = OPEN-LINUX</p>
          <p>Processor = Media Tek - MTK 3133</p>
          <p>Processor Speed = 260Mhz</p>
          <p>RAM Type = NVRAM</p>
          <p>RAM Speed = 128</p>
          <p>Operating Voltage = 7 - 33 V (default)</p>
          <p>Support = 90 V for EV with Additional Board</p>
          <p>Built-in Charger Circuit = 250 mah – 2000 mah</p>
          <p>Minimum Operating Temperature = -20</p>
          <p>Maximum Operating Temperature = 105</p>
          <p>GSM/GPRS : 2G Support</p>
          <p>Quad Band support</p>
          <p>GPS /IRNSS & Maximum Sensitivity of -160 dBm</p>
        </div>
        <div className="col-md-6 mt-4">
          <img className="iiot-content img-fluid" src={iiotlap} alt="iiot" 
             style={{ height: "350px", width: "450px" }} />
        </div>
      </div>
      <br />
   
      </div>
      <br />
      <br />
      <br />
      <Footer />
   
 </>
  );
}

export default IIOT;
