// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Armed-para{
    background-image: url("https://zuppa.io/img/slider1.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    
      }
    
    .Armed-para-container {
        background: transparent;
      color: #ffffff;
      /* backdrop-filter: blur(8px); */
      font-weight: 700;
    }


    .Armed-para-container:hover {
        background: transparent;
      color: #ffffff;
      -webkit-backdrop-filter: blur(48px);
              backdrop-filter: blur(48px);
      font-weight: 700;
    }`, "",{"version":3,"sources":["webpack://./src/components/ArmedForces.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;IACzD,4BAA4B;IAC5B,4BAA4B;IAC5B,sBAAsB;;MAEpB;;IAEF;QACI,uBAAuB;MACzB,cAAc;MACd,gCAAgC;MAChC,gBAAgB;IAClB;;;IAGA;QACI,uBAAuB;MACzB,cAAc;MACd,mCAA2B;cAA3B,2BAA2B;MAC3B,gBAAgB;IAClB","sourcesContent":[".Armed-para{\r\n    background-image: url(\"https://zuppa.io/img/slider1.jpg\");\r\n    background-repeat: no-repeat;\r\n    background-attachment: fixed;\r\n    background-size: cover;\r\n    \r\n      }\r\n    \r\n    .Armed-para-container {\r\n        background: transparent;\r\n      color: #ffffff;\r\n      /* backdrop-filter: blur(8px); */\r\n      font-weight: 700;\r\n    }\r\n\r\n\r\n    .Armed-para-container:hover {\r\n        background: transparent;\r\n      color: #ffffff;\r\n      backdrop-filter: blur(48px);\r\n      font-weight: 700;\r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
