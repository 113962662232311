import React from 'react';
import "./MroLab.css";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Header from "../../components/header";
import Footer from "../footer";

const MroLab = () => {
  const navigate = useNavigate();

  return (
    <>
  
      <div className="mro-lab-container">
        <img
          src="https://res.cloudinary.com/do7bct4kg/image/upload/v1724308997/bxtsxiw77pfrmj9qnjhm.jpg"
          alt="MRO Lab Modules"
          className="responsive-image"
        />
      
      </div>
      
    </>
  );
}

export default MroLab;
