

import React, { useEffect } from "react";
import Header from "../header";
import gpstracker from "../../assets/images/GPS_Tracker.png";
import iiotpic from "../../assets/images/Edgepic.png";
import ZuppaTrackingSystem from "../../assets/images/ZUPPA Vehicle Tracking System.mp4"
import "./ColdChain.css";
import { Fade } from "react-awesome-reveal";
import Footer from "../footer";

const ColdChain = () => {
  useEffect(() => {
    const body = document.querySelector('#root'); 
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }, []);

  return (
    <div>
      <Header />
      <div className="container">
        <div className="about-header text-center">
          <h4>COLD CHAIN GPS</h4>
          <h5>COLD Storage Logistic Telematics Solutions</h5>
        </div>

        <div className="row">
          <div className="col-md-6">
            <img
              className="coldChain-content img-fluid"
              src={gpstracker}
              alt=""
              style={{ height: "350px", width: "450px" }}
            />
          </div>

          <div className="col-md-6 coldChain">
            <Fade direction="left">
              <h3 className="container mt-4">COLD CHAIN GPS</h3>
            </Fade>
            <br />
            <p>Highly Customisable and Scalable Product</p>
            <p>Made in India from Design to Product</p>
            <p>Excellent Post-Sales Product</p>
            <p>Cuts Transist Losses and Saves Money</p>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 coldChain">
            <Fade direction="right">
              <h3>SPECIFICATION</h3>
            </Fade>
            <br />
            <p>Customized solution with AIS 140 Zuppa Hardware for Tracking.</p>
            <p>Zone wise Temperature Monitoring within the container with alerts in case of deviations.</p>
            <p>Configurable Geo Fenced Door Opening alerts</p>
            <p>Mobile App and Alert System to take preventive actions</p>
          </div>
          <div className="col-md-6 coldChain-box">
            <img
              className="coldChain-content img-fluid"
              src={iiotpic}
              alt="coldchain"
              style={{ height: "310px", width: "450px" }}
 />
          </div>
        </div>

        <div className="text-center">
          <video autoPlay loop muted >
            <source src={ZuppaTrackingSystem} type="video/mp4"      />
        
          </video>
        </div>
      
      </div>
      <br />
        <br />
        <br />
      <Footer/>
     
      
    </div>
  );
};

export default ColdChain;
