import React from 'react';
import './JobSkill.css';
import { useNavigate } from 'react-router-dom';
import job from "../../assets/job.jpg";


const JobSkill = () => {
  const navigate = useNavigate();

  const homePage = () => {
    navigate("/");
  }

  return (
    <>
      <div className="jobs-skills-container">
        <img src={job} alt="Job Skill" />
      </div>
    </>
  );
}

export default JobSkill;
