import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../pages/Navigati.css";
import zuppatrainer from "../assets/images/zuppatrainer.png";
import zuppascout from "../assets/images/zuppascout.png";
import autopilot from "../assets/images/Autopilot.png";
import { Fade, Zoom } from "react-awesome-reveal";

function Navigati() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
    <div>
      <Header />

      <div className="container">
        <div className="about-header text-center">
          <h3>NAVIGATI</h3>
          <h4>MOTHERBOARD FOR UNMANNED SYSTEMS</h4>
        </div>
      </div>
      <br />

      <div className="main-content" style={{ fontFamily: "inherit" }}>
        Replaces Man to enable Unmanned Electronic Control Systems. DPCC
        Technology applied to Image Processing.
        <br></br>Geo Located Data Acquisition & Transmission from sensors.
        Applications : UAV, UGV, USV
      </div>
      <Fade
                  className="container-one"
                  duration={2000}
                  direction="left"
                >
      <div className=" mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="black-box ">
              <div className="center-content ">
               
                  <img
                    src={zuppatrainer}
                    alt="Drone"
              
                  className="trainerimg"
                  />
               
              </div>

              <div
                className={`glow-text ${isHovered ? "glow-effect" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
               
              </div>
            </div>
            <br></br>
            <div className="scout-content">
              <a  href="/Zuppatrainer" style={{ textDecoration: 'none' }}>
              <h2 className="glowing-border">
              Ajeet Mini Trainer
              </h2>
              </a>
              <p> DGCA Compliant Indian Drone for RPTO with Economical Price</p>
            </div>
          </div>
        </div>
      </div>
      </Fade>

      <br></br>
      <br></br>


      <Fade
                  className="container-one"
                  duration={2000}
                  direction="left"
                >
      <div className=" mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="black-box ">
              <div className="center-content ">
               
                  <img
                    src={"https://res.cloudinary.com/dmv2tjzo7/image/upload/v1725862706/r4gjragpj97xn2ry85gv.png"}
                    alt="Drone"
                  style={{objectFit:"fill", marginTop:"0px"}}
                  className="trainerimg"
                  />
               
              </div>

              <div
                className={`glow-text ${isHovered ? "glow-effect" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
               
              </div>
            </div>
            <br></br>
            <div className="scout-content">
              <a  href="/Zuppatrainerplus" style={{ textDecoration: 'none' }}>
              <h2 className="glowing-border">
              Ajeet Mini Trainer Plus
              </h2>
              </a>
              <p> DGCA Compliant Indian Drone for RPTO with Economical Price</p>
            </div>
          </div>
        </div>
      </div>
      </Fade>
      <br></br>
      <br></br>



      <Fade
                  className="container-one"
                  duration={2000}
                  direction="left"
                >
      <div className=" mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="black-box ">
              <div className="center-content ">
         
                  <img
                    src={zuppascout}
                    alt="Drone"
                  
                    style={{ marginTop: "-1px" }}
                  />
           
              </div>

              <div
                className={`glow-text ${isHovered ? "glow-effect" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
              
              </div>
            </div>
            <br></br>
            <div className="scout-content">
              <a  href="/Zuppascout" style={{ textDecoration: 'none' }}>
              <h2 className="glowing-border">
                Ajeet Scout
              </h2>
              </a>
              <p>
                Camera Drone with Indigenous Flight Controller labeled as Class
                I supplier for Indian
              </p>
            </div>
          </div>
        </div>
      </div>
      </Fade>

      <br></br>
      <br></br>
      <Fade
                  className="container-one"
                  duration={2000}
                  direction="left"
                >
      <div className=" mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="black-box ">
              <div className="center-content ">
             
                  <img
                    src={autopilot}
                    alt="Drone"
                 
                    style={{ marginTop: "-130px" }}
                  />
              
              </div>

              <div
                className={`glow-text ${isHovered ? "glow-effect" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {/* <Fade
                  className="container-one"
                  duration={3000}
                  direction="left"
                >
                  <span style={{fontSize:"50px"}}>AUTOPILOT</span>
                </Fade> */}
              </div>
            </div>
            <br></br>
            <div className="scout-content">
              <a href="/Navgati_autopilot" style={{ textDecoration: 'none' }}>
                <h2 className="glowing-border">
                  Navgati Autopilot
                </h2>
              </a>
              <p>Replaces Man to enable Unmanned Electronic Control Systems.</p>
            </div>
          </div>
        </div>
    
        <br></br>
        <br></br>
      </div>
      </Fade>
      <Footer />
    </div>
  );
}

export default Navigati;
