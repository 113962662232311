import React, { useEffect } from "react";
import "./EyeBalls.css";
import Header from "../components/header";
import { Fade } from "react-awesome-reveal";
import Footer from "./footer";

const EyeBalls = () => {
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
    <>
      <Header />
      <div className="container">
      <Header />
      <div className="about-header text-center">
        <h4>THE SHOW STOPPER @AIDEF 2022 GENERATES EYEBALLS</h4>
      </div>
      <img
        className="img-fluid "
        src="https://zuppa.io/product-img/Zuppas-Ajeet-Mini-%E2%80%93-the-Show-Stopper-@-AIDef-2022-Generates-Eyeballs.jpg"
        alt="Responsive image"
        style={{ height: "auto", width: "60%" }}
      />




  
<div className="row">
        <div className="col-lg-6 mt-5 EyeballsOne">

     <Fade direction="up" duration={1800}>
     <p className="EyeballsOneText">
            {" "}
            <b style={{fontWeight:"bold",color:"black"}}>New Delhi:</b> The first-ever
            ‘Artificial Intelligence in Defence’ (AIDef) symposium and
            exhibition organised by the Ministry of Defence in New Delhi last
            week showcased India’s stride towards achieving self-reliance in
            defence technologies.   </p>
     </Fade>
            <Fade direction="left" duration={1800}>
            <p className="mt-5 EyeballsOneText"> At the event, 75 defence products powered by
            artificial intelligence were launched by the Defence Minister
            Rajnath Singh as part of ‘Azadi Ka Amrit Mahotsav’ celebrations.
       </p>
       </Fade>


        </div>       
      
        <div className="col-lg-6 mt-5 EyeballsTwo">
          <Fade direction="up" duration={2000}>
          <p className="EyeballsTwoText">
            {" "}
            These 75 products are in the domains of robotics systems, cyber
            security, human behaviour analysis, intelligent monitoring system,
            supply chain management, voice analysis and C4ISR (command, control,
            communication, computer and intelligence, surveillance and
            reconnaissance) and operational data analytics. Some of these
            products are already being used by the armed forces while the rest
            are in the process of deployment.
          </p>
          </Fade>
          <Fade direction="right" duration={1800}>
          <p className="EyeballsTwoText"> Describing AI as a revolutionary
            step in the development of humanity; proof that a human being is the
            most evolved creature in the universe, Defence Minister Singh said
            that AI had built inroads in almost every sector, including defence,
            health, medicine, agriculture, trade and commerce and transport.</p>
            </Fade>
        </div>

        <div className="col-lg-6 mt-5 EyeballsThree">
      <Fade direction="up" duration={1200}>
      <p className="EyeballsThreeText" style={{color:"black"}}>
            {" "}
            “When there has been full human participation in wars, new
            autonomous weapons/systems have been developed with the help of AI
            applications. They can destroy enemy establishments without human
            control. AI-enabled military devices are capable of handling large
            amounts of data efficiently. 
          </p>
      </Fade>
    <Fade direction="left">
    <p className="EyeballsThreeText" >It is also proving to be very helpful
            in training the soldiers. In the coming times, Augmented and Virtual
            Reality technologies will also be used effectively,” he said.
            “Although India believes in the principle of ‘Vasudhaiva Kutumbakam’
            (the whole world is one family) and has no intention to rule the
            world, we must develop the capability of our AI technology so that
            no country can even think of ruling us,” added Singh.{" "}</p>
    </Fade>
        </div>

        <div className="col-lg-6 mt-5 EyeballsFour">
    <Fade direction="up" duration={1200}>
    <p className="EyeballsFourText" style={{color:"black"}}>
            {" "}
            India needs to work on the artificial intelligence mechanism
            “extremely carefully” and it must be ready to face legal, ethical,
            political and economic upheaval that may follow due to this
            technology, Singh said. He emphasised the need to think about
            humanity and world peace along with one’s own defence and security.
          
          </p>
    </Fade>
 <Fade direction="right" duration={1300}>
 <p className="EyeballsFourText">  “We have to use artificial intelligence (AI) for humanity’s progress
            and peace. It should not be the case that a country or a group of
            countries establish their dominance on this technology just like
            nuclear power and the remaining countries are not able to enjoy the
            fruits of this technology,” he mentioned.</p>
 </Fade>
        </div>

        <div className="col-lg-6 mt-5 EyeballsFive">
     <Fade direction="up" duration={1200}>
     <p className="EyeballsFiveText">
            AIDef, an event conducted by Ministry of Defence was conceptualised
            to understand the countries readiness for the huge technological
            leaps that are required in the defence of the country. Among the 75
            products/solutions that were at display, Zuppa’s Ajeet Mini drone
            caught attention of the Defence Minister Rajnath Singh. 
          </p>
     </Fade>
     <Fade direction="left" duration={1200}>
     <p className="EyeballsFiveText">The Defence
            Minister observed Ajeet Mini as the first Indian drone that is
            engineered to match any global product. He was elated and impressed
            with the uniqueness of Ajeet’s fully Aatmanirbhar Autopilot –
            NavGati which is also being supplied to multiple DRDO’s and DPSU’s
            for unmanned products being developed by them.</p>
     </Fade>
        </div>

        <div className="col-lg-6 mt-5 EyeballsSix">
      <Fade direction="up" duration={1200}>
      <p className="EyeballsSixText">
            Unmanned Aerial Vehicles (UAVs) have completely revolutionised and
            skewed modern warfare. Their ability to enhance the situational
            awareness of troops, remain undetected and effect lethal strikes
            remotely on even high value assets like tanks, APCs etc. are clear
            pointers towards future battle field needs. 
          </p>
      </Fade>
      <Fade direction="right" duration={1200}>
          <p className="EyeballsSixText">The fact that such core
            home grown Aatmanirbhar Unmanned technology capable of producing
            world class products like Ajeet exists within the country was a huge
            eye opener for both the Defence Minister as well as many other
            Defence industry stakeholders including the major DPSUs.</p>
            </Fade>
        </div>

        <div className="col-lg-6 mt-5 EyeballsSeven">
          <Fade direction="up" duration={1200}>
          <p className="EyeballsSevenText">
            {" "}
            Generation 5 warfare requires that all military field personnel be
            capable of operating unmanned systems especially UAVs as they
            enhance their situational awareness like none other given their
            bird’s eye view especially of the immediate vicinity. Ajeet Mini in
            its avatar as a trainer is unmatched in this role as it offers
            Military Training organizations a very economical, rugged and value
            for money option to train future ready troops.
          </p>
          </Fade>
<Fade direction="left">
<p className="EyeballsSevenText"> Ajeet Mini equipped
            with Zuppa’s unique dual camera payload of a 4K day time and an IR
            night camera ensures that it can be operated 24 x 7 without the
            requirement of switching payloads. Ajeet is a small backpack multi
            mission drone. The Defence Minister quickly realised the potential
            of adapting Ajeet across several frontline military use cases.</p>
</Fade>

      

        </div>
   
        <div className="col-lg-6 mt-5 EyeballsEight">
<Fade direction="up" duration={1200}>
<p className="EyeballsEightText">
            {" "}
            Developed by Zuppa Geo Navigation Technologies Pvt Ltd. Ajeet Mini –
            the first in line of Indian Buy and Fly Drones was unveiled at the
            Drone Festival of India 2022 in May 2022. Ajeet Mini true to its
            tagline “World Class Performance, Made in India” promises to deliver
            unmatched features like stability, ease of flying, application
            specific variants, end to end service and more.
          </p>
</Fade>
   <Fade direction="right">
   <p className="EyeballsEightText"> He noted that AI’s
            ethics and dangers must be properly thought out. “We cannot stop the
            progress of artificial intelligence and we should not try to stop
            its progress. But we need to be careful about it,” he mentioned.
            When a new technology brings a huge change, its transition period is
            also as huge and serious. We need to work on AI extremely carefully
            so that this (technology) does not go out of our control in the
            coming times,” he concluded.</p>
   </Fade>
        </div>
      </div>

</div>

      <Footer />
    </>
  );
};

export default EyeBalls;
