// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.container {
    margin-top: 5rem;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    transition: transform 0.3s ease-in-out;
  }
  
  img:hover {
    transform: scale(1.05);
  }
  
  .card-paragraph {
    padding: 1rem;
    border-radius: 10px;
    background-color: #f6d8b6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   /* border: 2px solid rgb(238, 174, 101); */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: fadeIn 2s ease-in-out forwards;
  }
  
  .card-paragraph:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-paragraph a {
    color: #054896;
    text-decoration: none;

    font-weight: 800;
    letter-spacing: 0.1rem;
  }
  
  .card-paragraph a:hover {
    text-decoration: none;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Partners/Partner.css"],"names":[],"mappings":";;;AAGA;IACI,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,cAAc;IACd,cAAc;IACd,sCAAsC;EACxC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,wCAAwC;GACzC,0CAA0C;IACzC,mEAAmE;IACnE,yCAAyC;EAC3C;;EAEA;IACE,4BAA4B;IAC5B,yCAAyC;EAC3C;;EAEA;IACE,cAAc;IACd,qBAAqB;;IAErB,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":["\r\n\r\n\r\n.container {\r\n    margin-top: 5rem;\r\n  }\r\n  \r\n  img {\r\n    max-width: 100%;\r\n    height: auto;\r\n    display: block;\r\n    margin: 0 auto;\r\n    transition: transform 0.3s ease-in-out;\r\n  }\r\n  \r\n  img:hover {\r\n    transform: scale(1.05);\r\n  }\r\n  \r\n  .card-paragraph {\r\n    padding: 1rem;\r\n    border-radius: 10px;\r\n    background-color: #f6d8b6;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n   /* border: 2px solid rgb(238, 174, 101); */\r\n    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;\r\n    animation: fadeIn 2s ease-in-out forwards;\r\n  }\r\n  \r\n  .card-paragraph:hover {\r\n    transform: translateY(-10px);\r\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\r\n  }\r\n  \r\n  .card-paragraph a {\r\n    color: #054896;\r\n    text-decoration: none;\r\n\r\n    font-weight: 800;\r\n    letter-spacing: 0.1rem;\r\n  }\r\n  \r\n  .card-paragraph a:hover {\r\n    text-decoration: none;\r\n  }\r\n  \r\n  @keyframes fadeIn {\r\n    from {\r\n      opacity: 0;\r\n    }\r\n    to {\r\n      opacity: 1;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
