
import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./Fascinatingjourney.css";

const Fascinatingjourney = () => {


  useEffect  (() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
        behavior: 'smooth'
    }, 500)

  }, []);




  return (
    <>
      <Header />
      <div className="container">
        <h2 className="about-header text-center">
          SARAVANA RAJU - FISHING BOATS TO PILOTING DRONES
        </h2>
        <div className="fascinating-journey-page">
          <div className="container newspaper-container">
            <div className="row">
              <div className="col-lg-4 newspaper-column">
                <h6 className="newspaper-text">
                  This is the journey of Saravana Raju who hails from a fishing
                  hamlet in Chennai to being one among the experience drone
                  pilot today training future drone pilots at CASR RPTO MIT Anna
                  University. The story of Saravana has its origins in MR MOSS a
                  simple, rugged, crash resistant trainers, easy to repair RC
                  Plane that was marketed by RCFORALL an earlier Avatar of ZUPPA
                  GeoNavTech.
                </h6>
              </div>

              <div className="col-lg-4 newspaper-column">
                <img
                  src="https://shop.zuppa.io/saravanaraju.jpg"
                  alt="Saravana Raju"
                  className="img-fluid animated-image animated-border newspaper-image"
                />
              </div>

              <div className="col-lg-4 newspaper-column">
                <h6 className="newspaper-text">
                  Saravana has evolved today to be one among India’s versatile
                  Drone Pilot capable of operating a range of drones like heavy
                  pesticide spraying multicopters, Hybrid VTOL’s, Helicopters
                  etc. Today Saravana performs a range of drone-related
                  activities including training, survey/mapping, pesticide
                  spraying to name a few Understanding factors that enabled
                  Saravana and many others like him to shift from their
                  traditional occupations to Drone piloting is critical to
                  unlocking the huge potential offered by the evolving drone
                  ecosystem in India.
                </h6>
              </div>
            </div>
          </div>
          <br />
          <h5 className="fascinatHead5Tag" >
            The most important factor was the Aircraft Mr MOSS which was radical
            different from other trainers in the following ways:
          </h5>

          <div className="facinatiPara">
            <p>
              <span>
                🔶It was made of crash resistant, low cost, easy to replace
                plastic parts like the glass fibre stick fuselage and
                unbreakable COROPLAST boards (no parking signs tied on lampposts
                and gates) unlike the fragile and easily breakable balsa wood
                used on other Trainer aircrafts of those times.
              </span>
            </p>

            <p>
              <span>
                {" "}
                🔶 Its electric power system cost around a fifth the cost of a
                IC Engines being used on conventional trainers of that time.
              </span>
            </p>
            <p>
              {" "}
              🔶 Overall the cost of Mr MOSS as a Trainer plane was 20% the cost
              of any other trainer RC Plane being used at that time.
            </p>
            <p>
              {" "}
              🔶 The aircraft was light in weight as compared to other trainers
              while being more crash resistant.
            </p>
            <p>
              🔶 Modular fully aatmanirbhar design and economical parts
              availability.
            </p>
         
          </div>
          <div className="row newspaper-content">
  <div className="col-lg-6 newspaper-left">
    <p>
      <span>
        Retaining its passion to expand the adoption of drones ZUPPA once again
        has dug deep into its past by developing a Trainer drone that meets
        these requirements.
      </span>
    </p>
    <p>
      <span>The soon to be launched AJEET MINI is the result</span>
    </p>
    <p>
      <span>
        AJEET MINI ZUPPA’s Trainer drone meets all these criterion being
      </span>
    </p>
  </div>
  <div className="col-lg-6 newspaper-right">
    <p>
      <span>Rugged, crash resistant</span>
    </p>
    <p>
      <span>Low cost of drones and replacement parts.</span>
    </p>
    <p>
      <span>Lightweight</span>
    </p>
    <p>
      <span>Easy to fly, repair and maintain</span>
    </p>
  </div>
</div>
        </div>
     
<div className="row AJEET-MININ">
<div className="col-lg-6">
  <p><span>AJEET MININ shall be a huge boon to RPTO’s across India enabling them to layer their training programs into Flight training and Application training phases.</span></p>
  <p><span>The Flight Training phase shall familiarize, instill confidence in trainees to fly drones and impart basic control techniques and operate drones in various modes from manual to fully autonomous.</span></p>
  <p><span>On completion of the flight training phase the trainees could then graduate to higher category application specific drone training.</span></p>
  </div>
  <div className="col-lg-6">
  <p><span>This would reduce losses for RPTO’s by way of crashes on costly application specific drones. </span></p>
  <p><span>It will also ensure trainee competency when entrusted with high value application specific drones for use cases like pesticide spraying, mapping, logistics etc.</span></p>
  <p><span>It would introduce a system of graded training like the one that exists in commercial pilot licensing.</span></p>
  </div>
</div>
<h4 className="conclusion">CONCLUSION</h4>
<p className="conclusionPara">By layering Drone Training into Flight and Category specific Training the number of RPTOs for flight training can be rapidly increased thanks to the lower entry costs.

This would be a huge advantage for Category specific RPTO's requiring higher levels of investment by providing them proven flight trained and certified pilots there by reducing their losses on account of pilot errors.

This layering will be ideally suited for the mass skill development initiative envisaged by the government fast tracking the vision of India as a GLOBAL DRONE HUB much before 2030.</p>
      </div>
      <Footer />
    </>
  );
};

export default Fascinatingjourney;
