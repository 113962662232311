// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-box {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  
  .image-box.zoomed {
    transform: scale(1.1); 
    z-index: 1; 

  }
  
  .gps{
    font-size: '70px';
  }
.tracker-heading{
font-family:'Roboto, sans-serif'; 
}
.tracker-paratag{
  font-family:'Roboto, sans-serif'; 
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Edge.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;EAC3B;;;EAGA;IACE,qBAAqB;IACrB,UAAU;;EAEZ;;EAEA;IACE,iBAAiB;EACnB;AACF;AACA,gCAAgC;AAChC;AACA;EACE,gCAAgC;EAChC,eAAe;AACjB","sourcesContent":[".image-box {\r\n    position: relative;\r\n    cursor: pointer;\r\n    transition: all 0.3s ease;\r\n  }\r\n\r\n  \r\n  .image-box.zoomed {\r\n    transform: scale(1.1); \r\n    z-index: 1; \r\n\r\n  }\r\n  \r\n  .gps{\r\n    font-size: '70px';\r\n  }\r\n.tracker-heading{\r\nfont-family:'Roboto, sans-serif'; \r\n}\r\n.tracker-paratag{\r\n  font-family:'Roboto, sans-serif'; \r\n  font-size: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
