import React, { useEffect, useRef, useState } from "react";
import "./WarrantyTerm.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Fade } from "react-awesome-reveal";

const WarrantyTerm = () => {
  const [activeSection, setActiveSection] = useState("");
  const contentRef = useRef(null);

  const toggleSection = (sectionName) => {
    if (activeSection === sectionName) {
      setActiveSection("");
    } else {
      setActiveSection(sectionName);

      setTimeout(() => {
        contentRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    }
  };

  const warrentyTerm = [
    {
      Warranty: "Manifucturing Defects",
      details: "Covers manufacturing defects and faulty components.",
      period: "12 Months from the date of invoice",
      Inclusions:
        "Applies only to drones used under normal conditions as per the user manual.",
      Exclusions:
        "Circuit burns/Physical and Component damage, or unauthorized modifications.  ",
      ClaimProcess:
        "Contact Zuppa Support with- 1. Photos or videos of the damage the issue 2. Ship the defective drone for Zuppa diagnosis",
    },
    {
      Warranty: "Shipping Damage Replacement",
      details:
        "This clause applies to drones and accessories that sustain damage during shipping from the seller to the customer (Only if the shipment is arranged by Zuppa).",
      period: "3 Calendar days from the date of delivery",
      Inclusions:
        "The damage must be reported within 3 Calendar days from the date of delivery",
      Exclusions:
        "1. The damage is reported after the 3-day window. 2. The product was damaged after delivery due to improper handling by the customer. 3.The product was purchased from an unauthorized retailer.",
      ClaimProcess:
        "1. Proof of damage must be provided, including photographs/unboxing videos of the packaging and product. 2. A copy of the shipping invoice is required to verify transit. 3. The original packaging must be retained for inspection purposes.",
    },
    {
      Warranty: "Battery",
      details: "Covers malfunction or failure of the battery under normal use.",
      period:
        "1.6 Months OR 200 charge cycles, whichever comes first from the date of purchase. 2. Replacement batteries will continue under the original warranty period or have a minimum of 3 months warranty, whichever is longer.",
      Inclusions:
        "Valid only for batteries used and charged with recommended charging equipment.",
      Exclusions:
        "1. Physical damage, exposure to liquids, or improper handling.2. Damage due to overcharging or using non-recommended chargers. 3.Normal wear and tear of the battery. 4.Batteries that have been tampered with or repaired by unauthorized parties. ",
      ClaimProcess:
        "Contact Zuppa Support with- 1.Submit a warranty claim with battery’s serial number. 2. Ship the drone for Zuppa diagnosis.",
    },
    {
      Warranty: "Consumables",
      details:
        "Consumables includes Propellers, Propeller Guards, landing gears, batteries, Charging Cables, Memory Cards",
      period: "🚫",
      Inclusions: "🚫",
      Exclusions: "🚫",
      ClaimProcess: "🚫",
    },
    {
      Warranty: "Accidental Damage operator error",
      details:
        "1. Crashes resulting in structural damage. 2. Impact during normal use causing failure of critical components such as motors, propellers, or camera systems. 3. Damage from collisions with stationary objects.",
      period: "🚫",
      Inclusions: "🚫",
      Exclusions: "🚫",
      ClaimProcess: "🚫",
    },
    {
      Warranty: "Camera and Glmbals",
      details:
        "Covers issues with the camera’s functionality or gimbal stabilization issues.",
      period:
        "12 Months from the date of the invoice If a replacement is issued, the new unit will continue under the original warranty period",
      Inclusions:
        "'1.Malfunctioning camera sensors or lenses. 2.Gimbal motor failures or alignment issues caused by production faults.",
      Exclusions:
        "'1.Damage caused by crashes, drops, or collisions. 2.Normal wear and tear (e.g., scratches on the camera lens). 3.Damage due to unauthorized modifications, repairs, or disassembly. 4.Gimbal misalignment caused by improper calibration or excessive force. ",
      ClaimProcess:
        "Contact Zuppa Support with- 1. Photos or videos of the camera or gimbal showing the issue 2. Ship the defective drone for Zuppa diagnosis",
    },
    {
      Warranty: "Gps Module",
      details:
        "Covers GPS module failure or poor performance under standard operating conditions. ",
      period: "12 Months from the date of invoice",
      Inclusions:
        "'1.Malfunctioning camera sensors or lenses. 2.Gimbal motor failures or alignment issues caused by production faults.",
      Exclusions:
        "'1.Damage caused by crashes, drops, or collisions. 2.Normal wear and tear (e.g., scratches on the camera lens). 3.Damage due to unauthorized modifications, repairs, or disassembly. 4.Gimbal misalignment caused by improper calibration or excessive force.",
      ClaimProcess:
        "Contact Zuppa Support with- 1. Photos or videos of the camera or gimbal showing the issue2. Ship the defective drone for Zuppa diagnosis",
    },
    {
      Warranty: "Remote Controller",
      details:
        "Covers defects in the remote controller’s functionality and connection with the drone.",
      period: "12 Months from the date of invoice",
      Inclusions:
        "1.Malfunctioning buttons, joysticks, or switches due to manufacturing defects. 2. Failure of the wireless communication between the remote control and the drone. 3. Power issues such as failure to turn on or irregular power supply when using the remote.",
      Exclusions:
        "1.Damage caused by crashes, drops, or impacts. 2. Battery issues, including wear and tear or damage from overcharging. 3. Damage caused by improper handling, unauthorized modifications, or tampering. 4. Physical damage to the remote control housing, buttons, or joysticks due to user neglect. ",
      ClaimProcess:
        "Contact Zuppa Support with-1. Photos or videos demonstrating the issue with the remote control. 2. Ship the drone for Zuppa diagnosis, along with the last flight log file for debugging. ",
    },
    {
      Warranty: "Autopilot",
      details:
        "Covers Autopilot module failure or malfunctioning under standard operating conditions.",
      period: "12 Months from the date of invoice",
      Inclusions:
        "1.Malfunction of the flight controller or autopilot software that causes unintended flight behavior. 2.Failure of onboard sensors (e.g., GPS, IMU, barometer, compass) that prevent accurate autonomous navigation.3.Software bugs or errors that interfere with autopilot functionalities like waypoint navigation, obstacle avoidance, or altitude hold. 4.Faults in the communication between the autopilot system and other drone components (e.g., motor control or GPS).",
      Exclusions:
        "1. Malfunctions caused by user errors, improper calibration, or configuration.2. Software-related issues that result from third-party applications or unauthorized firmware updates.3. Interference with the autopilot system due to external factors like extreme weather, electromagnetic interference, or faulty GPS signals.4. Damage resulting from unauthorized repairs, modifications, or use of non-recommended equipment.",
      ClaimProcess:
        "Contact Zuppa Support with-1.Provide a detailed description of the issue, including any error messages, abnormal flight behavior, or other symptoms of autopilot malfunction. 2.Photos or videos of the issue, including flight data logs 3.Ship the drone for Zuppa diagnosis, along with the last flight log file for debugging.",
    },
    {
      Warranty: "Firmware Software",
      details:
        "This warranty covers defects in the firmware and software that are pre-installed or provided by the manufacturer for the drone’s operation, including the autopilot system, flight control, and any other software or applications required to operate the drone.",
      period: "12 Months from the date of invoice",
      Inclusions:
        "1. Firmware and software defects that prevent the drone from operating as designed or intended.2. Software crashes, malfunctions, or freezes 3. Incorrect data reporting, and failure of features (e.g., GPS lock, flight modes).",
      Exclusions:
        "1.Corruption or damage caused by third-party software, apps, or firmware updates. 2.Software or firmware bugs that do not result in significant operational failure or safety concerns. 3.The issue results from the installation of unofficial firmware or software.",
      ClaimProcess:
        "Contact Zuppa Support with-1.Detailed description of the problem, including any steps taken to resolve it (e.g., reboots, reinstallations).2.Logs or screenshots of error messages, if available. 3.Ship the  drone for Zuppa diagnosis, along with the last flight log file for debugging.   ",
    },
    {
      Warranty: "Extended Warranty",
      details:
        "Optional extension of coverage for hardware defects and repairs.",
      period: "Additional 12 months along with Standard coverage 12 months",
      Inclusions: "Must be purchased at the time of purchase of the drone.",
      Exclusions:
        "Coverage does not extend to items explicitly excluded in standard warranty",
      ClaimProcess: "Contact Zuppa Support for more details.",
    },
  ];

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header />
      {/* Parallax Header */}
      <div className="WarrantyTerm-headercanva">
        <img
          className="WarrantyTermTechno"
          src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1736245746/rdtpx33bw06nlou21ykm.png"
          alt="Warranty Terms"
        />
      </div>

      <div className="WarrantyTerm-container">
      <Fade direction="left" duration={2400}>
        <h2 className="WarrantyTerm-title">
          WARRANTY INFORMATION & TERMS OF SERVICE
        </h2>
        </Fade>
        <br />
    

        {/* Collapsible Sections */}
<Fade direction="up" duration={1400}>
<div>
          <div className="WarrantyTerm-section">
            <div
              className="WarrantyTerm-header"
              onClick={() => toggleSection("terms")}
            >
              <h5 className="maintitle">General Terms</h5>
              <h2 className="plusebutton">
                {activeSection === "terms" ? "-" : "+"}
              </h2>
            </div>
          </div>

          {activeSection === "terms" && (
            <div className="WarrantyTerm-content" ref={contentRef}>
              <h4>Diagnosis and Resolution:</h4>
              <p>
                Zuppa will provide troubleshooting support via phone, e-mail, or
                online chat.
              </p>
              <p>support@zuppa.io / 9952081655</p>
              <h4>Repair or Replacement:</h4>
              <p>
                Issues for products which are within Warranty period, will be
                repaired or replaced by Zuppa at no cost.
              </p>
              <h4>Exclusions:</h4>
              <p>
                This warranty does not cover damages from non-manufacturing
                factors, unauthorized modifications, damages or malfunctions due
                to misuse, external environment factors, or use of
                unauthenticated third- party parts.
              </p>

              <h4>Limitation of Liability</h4>
              <h5>Responsibility</h5>
              <p>
                Zuppa is responsible for your product only while in its
                possession or during Zuppa-arranged transit.
              </p>
              <h5>Exclusions</h5>
              <p>
                Zuppa is not liable for any data loss, third-party claims, or
                indirect damages. Liability is limited to the purchase price of
                the product.
              </p>
              <h5>Return &amp; Refund</h5>
              <p>
                No return &amp; Refund clause is applicable, under any
                circumstances.
              </p>
              <h5>Return &amp; Replacement Service</h5>
              <p>
                Return &amp; Replacement Service is available only if claimed
                within 3 calendar days of product delivery, on below conditions
              </p>
              <ul>
                <li>
                  In-case of significant transit damage (only If Zuppa arranges
                  for the delivery)
                </li>
                <li>
                  Discrepancies with the description between proposed and final
                  product features
                </li>
                <li>
                  Performance related failures (To be proven to Zuppa basis
                  flight logs). Terms and conditions apply.*
                </li>
              </ul>
              <h5>Unboxing and Do’s &amp; Don’ts</h5>
              <p>
                A detailed video explanation of Unboxing and Do’s and Don’ts are
                provided in the below link. Customers and users are requested to
                go through the same and understand it thoroughly.
              </p>

              <a
                target="_blank"
                href="https://youtube.com/playlist?list=PLvg5kmiEqEtUHtu7DR1UB6oDK4d9uMBAC&amp;si=SqtmefOVtpWGAmGZ"
              >
                Packaging and Unboxing Video
              </a>
            </div>
          )}
        </div>

        </Fade>
        <Fade direction="up" duration={1600}>
        <div>
          <div className="WarrantyTerm-section mt-2">
            <div
              className="WarrantyTerm-header"
              onClick={() => toggleSection("privacy")}
            >
              <h5 className="maintitle">
                Exclusions to Warranty and After-Sales-Support Terms
              </h5>
              <h2 className="plusebutton">
                {activeSection === "privacy" ? "-" : "+"}
              </h2>
            </div>
          </div>
          {activeSection === "privacy" && (
            <div className="WarrantyTerm-content" ref={contentRef}>
             <p>
                Crashes or fire damage caused by factors other than
                manufacturing issues, such as pilot- related mistakes or misuse.
              </p>
              <p>
                Damage caused by improper installation, misuse, or operation.
              </p>
              <p>
                Damage from unauthorized modifications to the circuits or using
                incompatible or unauthorised batteries and chargers.
              </p>
              <p>Damage from flying the product in ways not recommended.</p>
              <p>
                Damage from using the product in bad weather (e.g., strong
                winds, rain, sandstorms, etc.).
              </p>
              <p>
                Damage from using the product in places with strong
                electromagnetic interference (e.g., near mines, radio towers, or
                power lines).
              </p>
              <p>
                Damage from using the product in areas with wireless
                interference (e.g., transmitters, video links, Wi-Fi devices).
              </p>
              <p>
                Damage from flying the product over the maximum weight limit.
              </p>
              <p>
                Damage caused by using unauthorised or incompatible third-party
                parts.
              </p>
              <p>
                Damage from using a faulty battery or flying with a low battery.
              </p>
              <p>
                Failure to provide flight logs needed for accidental damage
                diagnosis.
              </p>
              <p>
                Products or parts with altered or removed identification labels.
              </p>
              <p>
                Zuppa reserves the right to inspect the equipment for damage or
                tampering. Should damage or tampering be evident, or if the
                instrument has in any way been opened for inspection, self-
                installed, etc., without authorization, then the warranty will
                be void.
              </p>
              <p>
                Damage caused by normal wear of parts, scratches, surface rust
                or deterioration, improper use, improper storage, improper
                testing, negligent use of improper voltage or current,
                unauthorized installation, or lack of regular maintenance.
              </p>
              <p>
                Product repaired, dismantled, or altered by unauthorized
                technical personnel.
              </p>
              <p>
                Removal of warranty seals or serial number stickers which will
                void our warranty.
              </p>
              <b>
                Return &amp; Replacement Service will not be provided under the
                following conditions:
              </b>
              <ol type="a">
                <li>
                  The request is made more than (3) calendar days after
                  receiving the product.
                </li>
                <li>
                  The product is not returned to Zuppa within seven (7) calendar
                  days after replacement confirmation is issued.
                </li>
                <li>
                  A valid proof of purchase, receipt, or invoice is not
                  provided.
                </li>
                <li>
                  If the product is suspected to be forged or altered, basis the
                  photographic / Video evidences provided.
                </li>
                <li>
                  The product returned for replacement is missing any original
                  accessories, attachments, or packaging, or contains items
                  damaged due to user error.
                </li>
                <li>
                  After receipt of the unit, Zuppa will test the unit to
                  determine that none of the Exclusions are applicable.
                </li>
                <li>
                  Proof of damage during transit, issued by the carrier, is not
                  provided.
                </li>
              </ol>
            </div>
          )}
        </div>
        </Fade>
        <Fade direction="up" duration={1800}>
        <div>
          <div className="WarrantyTerm-section mt-2">
            <div
              className="WarrantyTerm-header"
              onClick={() => toggleSection("support")}
            >
              <h5 className="maintitle">
                Guidelines regarding Operating Limitations
              </h5>
              <h2 className="plusebutton">
                {activeSection === "support" ? "-" : "+"}
              </h2>
            </div>
          </div>
          {activeSection === "support" && (
            <div className="WarrantyTerm-content" ref={contentRef}>
              <p>
                This Section includes operating limitations that are necessary
                for the safe operation of the Ajeet drones, motors, standard
                equipment, and standard payload.
              </p>

              <p>
                All limitations given in this Section must be strictly complied
                with, during every flight operation.
              </p>
              <b> 1. Airspeed Limitations:</b>
              <ol type="a">
                <li>Operating Manoeuvring Speed = 9 m/s </li>
                <li>Never Exceed Speed of 11 m/s</li>
                <p>
                  Note: Flight speeds and flight envelope are limited by the
                  flight controller.
                </p>
              </ol>

              <b>2. Battery Limitations:</b>
              <ol type="a">
                <li>Approved Types of Batteries: Li-ion 4200mAH </li>
                <li>Minimum Operation Voltage: 20V</li>
                <li>Recommended Operation Voltage: 25V</li>
                <li>Maximum Number of Charge Cycles: 200</li>
              </ol>

              <b>3. Weather Limitations:</b>
              <ol type="a">
                <li>
                  Do not fly Ajeet Mini drones in temperatures Exceeding 60°C or
                  Lower than or Equal to 10°C
                </li>
                <li>
                  Do not fly Ajeet Mini drones at wind speeds Exceeding 30 km/s
                </li>
                <li>
                  Do not fly Ajeet Mini drones in the following weather
                  conditions: Snow/Rain/Fog/ Wind/Cyclone/Storms, etc.,
                </li>
              </ol>

              <b>4. Range and Control Link Limitations:</b>
              <ol type="a">
                <li>
                  a. Attempting to fly the aircraft beyond the provided
                  limitations as per specification (Technical Specification
                  Sheet) may result in aircraft failure, and hazardous
                  situations, including resulting in personal injury and damage
                  to product and/or property.
                </li>
              </ol>
            </div>
          )}
        </div>
        </Fade>
        <Fade direction="up" duration={1900}>
        <div>
          <div className="WarrantyTerm-section mt-2">
            <div
              className="WarrantyTerm-header"
              onClick={() => toggleSection("Disclaimer")}
            >
              <h5 className="maintitle">Disclaimer</h5>
              <h2 className="plusebutton">
                {activeSection === "Disclaimer" ? "-" : "+"}
              </h2>
            </div>
          </div>
          {activeSection === "Disclaimer" && (
            <div className="WarrantyTerm-content " ref={contentRef}>
              <p>
                By using the AJEET MINI DRONES, you acknowledge that you have
                read, understand, and agree to this disclaimer.
              </p>
              <p></p>
              <p>
                You agree that you are solely responsible for your conduct while
                using the AJEET MINI DRONES, and for any direct or indirect
                consequences that may result from its use.
              </p>
              <p>
                You agree to only use the AJEET MINI DRONES for proper purposes
                that are following local and airspace rules and regulations.
              </p>

              <ol type="1">
                <li>
                  AJEET MINI DRONES should be operated with extreme care, as
                  improper operation can cause damage to property and/or product
                  and serious personal injury as well.
                </li>
                <li>
                  As with any multi-rotor aircraft, AJEET MINI DRONES is a
                  technical machine. Novice pilots should invest sufficient time
                  in a flight simulator and seek training from an experienced
                  pilot before operation.
                </li>
                <li>
                  Always check the AJEET MINI DRONES and its components before
                  operation. Ensure that all parts are in good condition before
                  each flight. DO NOT fly with worn or damaged parts.
                </li>
                <li>
                  Never attempt to touch the AJEET MINI DRONES when the
                  propellers are moving.
                </li>
                <li>
                  Ensure that the propellers are installed correctly before each
                  flight.
                </li>
                <li>
                  Maintain a safe distance from people, buildings, high voltage
                  power lines, tall trees, water, and other hazards when flying
                  the aircraft.
                </li>
                <li>
                  Never fly with any propellers that have visible imperfections
                  or damage.
                </li>
                <li>Only use compatible and authorized Zuppa parts.</li>
                <li>
                  Always remove the propellers when making a change to the
                  configuration of the AJEET MINI DRONES to prevent propeller
                  strikes in the event of unintentional motor starts.
                </li>
                <li>
                  10. It is your responsibility to perform a full system check
                  of the AJEET MINI DRONES before every flight.
                </li>
                <li>
                  It is your responsibility to learn how to safely operate the
                  AJEET MINI DRONES and to adhere to all applicable rules and
                  regulations.
                </li>
                <li>Fly at your own risk.</li>
                <li>
                  The AJEET MINI DRONES is a tuned system with custom components
                  selected for each application. Modification to, removal, or
                  substitution of the AJEET MINI DRONES components will void the
                  warranty and can lead to unsafe operating conditions.
                </li>
                <li>14. DO NOT fly in rainy or stormy or snowy weather.</li>
                <li>DO NOT overload the drone.</li>
              </ol>
            </div>
          )}
        </div>

        </Fade>
        <Fade direction="up" duration={1800}>
          <div className="WarrantyTerm-section mt-2">
            <div
              className="WarrantyTerm-header"
              onClick={() => toggleSection("Claim Process")}
            >
              <h5 className="maintitle">
                Details of Warranty Period, Inclusions, Exclusions &amp; Claim
                Process
              </h5>
              <h2 className="plusebutton">
                {activeSection === "Claim Process" ? "-" : "+"}
              </h2>
            </div>
          </div>
</Fade>



          {activeSection === "Claim Process" && (
            <div className="WarrantyTerm-contenttable" ref={contentRef}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Warranty Term</th>
                    <th>Details</th>
                    <th>Warranty Period</th>
                    <th>Inclusions</th>
                    <th>Exclusions</th>
                    <th>Claim Process</th>
                  </tr>
                </thead>
         
  <tbody>
    {warrentyTerm.map((term, index) => (
      <tr key={index}>
        <td data-label="WarrantyTerm" style={{ textAlign: "justify" }}>
          {term.Warranty}
        </td>
        <td data-label="Details" style={{ textAlign: "justify" }}>
          {term.details}
        </td>
        <td
          data-label="Warranty Period"
          style={{
            textAlign: term.period === "🚫" ? "center" : "justify",
          }}
        >
          {term.period}
        </td>
        <td
          data-label="Inclusions"
          style={{
            textAlign: term.Inclusions === "🚫" ? "center" : "justify",
          }}
        >
          {term.Inclusions}
        </td>
        <td
          data-label="Exclusions"
          style={{
            textAlign: term.Exclusions === "🚫" ? "center" : "justify",
          }}
        >
          {term.Exclusions}
        </td>
        <td
          data-label="Claim Process"
          style={{
            textAlign: term.ClaimProcess === "🚫" ? "center" : "justify",
            
          }}
        >
          {term.ClaimProcess}
        </td>
      </tr>
    ))}
    <tr>
      <th scope="row">Shipping Costs</th>
      <td colSpan="5" style={{ textAlign: "justify" }}>
   
    <ol>
      <li> Shipping fees for sending the drone for repair, as well as for its return after repair, must be paid by the customer. These costs will vary depending on the size, weight, and destination of the shipment.
      </li>
      <li>It is the customer’s responsibility to ensure that the drone is packed securely for shipping to prevent any damage during transit.
      </li>
      <li>To further ensure the safe handling of your drone, Zuppa encourages customers to send a representative to direct in-person pick-up from the Zuppa place, eliminates shipping risks, and ensures the drone is safely delivered after repair.
      </li>
      <li>If a representative is sent, then Zuppa could provide a detailed orientation on proper drone usage, focusing on safety measures and best practices for handling.
      </li>
    </ol>
    
      </td>
    </tr>
  </tbody>







              </table>
              <div className="text-start">
                <b>Key points to Note:</b>
                <ol type="a">
                  <li>
                    Warranty Period refers to the length of time that a specific
                    part is covered.
                  </li>
                  <li>
                    Inclusions outline the requirements that customer must
                    follow for the Warranty claim to be valid.
                  </li>
                  <li>
                    Exclusions detail the circumstances under which the Warranty
                    claim will not apply.
                  </li>
                  <li>
                    Claim process explains how customers should initiate the
                    Warranty claim.
                  </li>
                  <li>
                    Details column provides explanations to clarify the meaning
                    of the terms used in the Warranty Terms Column.
                  </li>
                </ol>
              </div>
            </div>
          )}

      </div>
      <Footer />
    </div>
  );
};

export default WarrantyTerm;
