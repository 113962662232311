import React, { useState, useContext, useEffect } from "react";
import "./Login.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import userContext from "../../context/userContext";
import EmailIcon from "@mui/icons-material/Email";


const Login = () => {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(userContext);

  const isValid = email !== "" && password !== "";

  const postLoginMethod = async () => {
    const newLoginData = {
      email: email,
      password: password,
    };

       const response = await fetch("https://shop.zuppa.io:4000/login", {
        method: "POST",
        body: JSON.stringify(newLoginData),
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();

      if (data.message === "Invalid Email" || data.message === "Invalid Password") {
        alert("Invalid Credentials");
      } else if (data.message === "Successfully Login") {
        localStorage.setItem("zuppa", data.zuppa);
        localStorage.setItem("id", data._id);
        localStorage.setItem("username", data.username);

        alert(data.message);
        navigate("/ShopNow");
      }

  };

  const registerHandle = () => {
    navigate("/Register");
  };

  const forgotHandle = () => {
    navigate("/Forgotpass");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header />
      <div className="login-container">
        <div className="wrapper">
          <div className="loginpara">
            <h1>Login</h1>
            <div className="input-box">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter Your Email"
                required
              />
              <EmailIcon fontSize="large" className="loginpageicon" />
            </div>

            <div className="input-box">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                placeholder="Enter Your Password"
                required
              />
              {showPassword ? (
                <VisibilityIcon
                  fontSize="large"
                  color="action"
                  className="loginpageicon"
                  onClick={handleTogglePasswordVisibility}
                  title="Hide Password"
                />
              ) : (
                <VisibilityOffIcon
                  fontSize="large"
                  className="loginpageicon"
                  onClick={handleTogglePasswordVisibility}
                  title="Show Password"
                />
              )}
            </div>

            <div className="remember-forgot">
              <label>
                <input type="checkbox" /> Remember me
              </label>
              <a href="# " onClick={forgotHandle}>
                Forgot password
              </a>
            </div>
            <button type="submit" onClick={postLoginMethod} disabled={!isValid}>
              Login
            </button>

            <div className="register-link">
              <p>
                Don't have an Account ?{" "}click here 👇
                <a href="# " onClick={registerHandle} >
                <h5 className="register-button-tag">Register</h5>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
