

import React from "react";
import "./Industry.css";
import growth from "../../assets/Growth.png";
const Industry = () => {
  return (
    <>
      <div className="container mt-3">
        <h2 className="drone-title about-header" style={{marginTop:"-40px"}}>
          INDIAN DRONE INDUSTRY GROWTH
        </h2>

    
<br/>
        <div className="row">
          <div className="col-12 Industry-Growth">
            <img src={growth} alt="Industry Growth" className="growthImage" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Industry;

