import React, { useState, useEffect } from "react";
import covervideo from "../assets/images/scout.mp4";
import Autopilot from "../assets/images/Autopilot.png";
import drishyapic from "../assets/images/drishya.png";
import edgepic from "../assets/images/Edge1.png";
import ProductSlider from "../components/ProductSlider";
import "./Home.css";
import { Container, Row, Col } from "react-bootstrap";
import Fade, { Zoom } from "react-awesome-reveal";
import Reveal from "react-awesome-reveal";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Navgati from "../assets/images/navigati.png";
import pantent_cer from "../assets/images/Patent_certificate.jfif";
import CustomerReview from "./CustomerReview";
import { useNavigate } from "react-router-dom"; 
import ScoutProduct from '../assets/images/scou.png';
//import OfferPopup from '../components/OfferPopup';

import image1 from '../assets/images/Picture1.png'
import image2 from '../assets/images//Picture2.png'
import image3 from '../assets/images/Picture3.png'
import image4 from '../assets/images/Picture4.png'
import image5 from '../assets/images/Picture5.png'
import image6 from '../assets/images//Picture6.png'
import image7 from '../assets/images//Picture7.png'
import image8 from '../assets/images/Picture8.png'
import image9 from '../assets/images/Picture9.jpg'
import image10 from '../assets/images/Picture10.png'
import image11 from '../assets/images/pptlogo.jpg'
import image12 from '../assets/images/Hyundailogo.png'
import image13 from '../assets/images/Picture1.png'
import image14 from '../assets/images//Picture2.png'
import image15 from '../assets/images/Picture3.png'
import image16 from '../assets/images/Picture4.png'
import image17 from '../assets/images/Picture5.png'
import image18 from '../assets/images//Picture6.png'
import image19 from '../assets/images//Picture7.png'
import image20 from '../assets/images/Picture8.png'
import image21 from '../assets/images/Picture9.jpg'
import image22 from '../assets/images/Picture10.png'
import image23 from '../assets/images/pptlogo.jpg'
import image24 from '../assets/images/Hyundailogo.png'
import image25 from '../assets/images/Picture1.png'
import image26 from '../assets/images//Picture2.png'
import image27 from '../assets/images/Picture3.png'
import image28 from '../assets/images/Picture4.png'
import image29 from '../assets/images/Picture5.png'
import image30 from '../assets/images//Picture6.png'
import image31 from '../assets/images//Picture7.png'
import image32 from '../assets/images/Picture8.png' 
import image33 from '../assets/images/Picture9.jpg'
import image34 from '../assets/images/Picture10.png'
import image35 from '../assets/images/pptlogo.jpg'
import image36 from '../assets/images/Hyundailogo.png'
import Dialog from "../components/Dialog";



const Projects = [
  {
    name: "Ajeet Drones",
    image: ScoutProduct,
    description: "Camera Drone with Indigenous Flight Controller labeled as Class I supplier for Indian Indian forces and PSUs such as DRDO,BEL & HAL.",
    path:"/Navigati"
   
  },
  {
    name: " Autopilots",
    image: Autopilot,
    description: "NavGati Autopilot for Unmanned Systems ",
    path:"/Navgati_autopilot"
  
  },
  {
    name: "EDGE Wireless Connectivity ",
    image: edgepic,
    description:"Vehicle Location Telematics Device",
    path:"/Edge"
  },
];


const Ticker = ({ items }) => {
  // Duplicate items to create a seamless loop
  const loopedItems = [...items, ...items];

  return (
    <div className="ticker-container">
      <div className="ticker-content">
        {loopedItems.map((item, index) => (
          <div key={index} className="ticker-item">
            <img src={item} alt={`item-${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};
const Home = () => {

const navigate=useNavigate()

useEffect(() => {
  const body = document.querySelector('#root');

  body.scrollIntoView({
      behavior: 'smooth'
  }, 500)

}, []);
const Thechasmbutton =()=>{
  navigate("/Thechasm")
}
  const Fascinatingjourneybutton=()=>{
    navigate("/Fascinatingjourney")
  }



  // -----pop up -------------javascript------------------
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useEffect(()=>
  {
      
  setTimeout(function() {startTime()}, 1000);

  },[])

  function startTime()
  {
     setIsPopupOpen(true);
  }

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const items = [
    image1, image2, image3, image4, image5,
    image6, image7, image8, image9, image10,
    image11, image12, image13, image14, image15,
    image16, image17, image18, image19, image20,image21,image22,image23,
    image24,image25,image26,image27,image28,image29,image30,
    image31,image32,image33,image34,image35,image36
  ];

  //--------------------------Dialog.js---------------------
const [isPopOpen, setIsPopOpen] = useState(false);
useEffect(()=>
{
    
setTimeout(function() {startTime()}, 3000);

},[])

function startTime()
{
   setIsPopupOpen(true);
}

const closePop = () => {
  setIsPopupOpen(false);
};
  return (
    <div>
    
      {/* <Container fluid style={{ marginTop: "-40px" }}> */}
        <Row style={{ marginTop: "-40px" }}>
          <Col>
            <div
              className="video-container"
              style={{
                width: "100%",
                position: "relative",
                paddingBottom: "56.25%",
              }}
            >
              <video
                autoPlay
                loop
                muted
                style={{
                  position: "absolute",
                  minWidth: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              >
                <source src={covervideo} type="video/mp4" />
              </video>
           
            </div>
          </Col>
        </Row>
      {/* </Container> */}

      <Fade duration={1200} direction="left">

{isPopupOpen ? <Dialog closePopup={closePopup}  /> : null}
</Fade>

      
{/*     
    {isPopupOpen && <OfferPopup closePopup={closePopup} />} */}
      <div className="row justify-content-center" style={{marginTop:'80px'}}>
        <div className="col-12 col-md-10 col-lg-8">
          <h3
            className="text-center"
            style={{
              fontFamily: ' "Orbitron", sans-serif',
              fontSize: "40px",
              fontWeight: "600",
            }}
          >
            "Indian's Only deep Tech cyber physical Drone Stack Solution Provider"
          </h3>
        </div>
      </div>
      {/*******************  Products ***********************/}

      <div className="container">
     
      <div style={{marginTop:"30px"}}> <h1 className="product-heading">PRODUCTS</h1></div>
      
  
          <Zoom duration={8000}> 
          <hr className="Break-line-one" style={{marginTop:"30px"}}></hr> 
          </Zoom>
      
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            {Projects.map((Project, index) => (
              <Fade key={index}  >
                <div className="col" >
                  <Card sx={{ maxWidth: 345, borderRadius:"15px" }}>
                    <div className="project-name-image">
                      <h4 className="text-center" style={{fontSize:"22px",}}>{Project.name}</h4>
                      <CardMedia
                        component="img"
                        height="194"
                        image={Project.image}
                        alt="Paella dish"
                      />
                    </div>
                    <div className="reveal-des">
                      <Reveal
                    
                        className="home-product-description"
                        direction="up"
                        duration={1800}
                      >
                        <CardContent>
                          <Typography variant="body1" className="text-center">
                            {Project.description}
                          </Typography>
                          <button className="projectButton" onClick={()=>navigate(`${Project.path}`)}>Description</button>
                        </CardContent>
                      </Reveal>
                    </div>
                  </Card>
                </div>
              </Fade>
            ))}
          </div>
  
      </div>
      <br />
      <br />
      {/* *************************BLOGS AND EVENTS************************** */}
      <div className="container">
    <div class="col-sm-12  text-center bouncing-header">
      <h1 class="bouncing-text ">BLOGS: News & Events...</h1>
    </div>

<div id="card-area" className="container">
  <div className="row">
    <div className="col-md-6 mb-3" >
      <div className="box">
<img
          src="https://zuppa.io/product-img/blog.jpg"
          alt=""
          srcset=""
        />
<div class="overlay">
          <h3>AH !! THE CHASM</h3>
          <p className="ThechasPara">
            Crossing the chasm and diffusion of technology were phrases I
            heard of quite late in the diffusion of our proprietary globally
            unique technology of  SYSTEM OF DISSEMINATED PARALLEL CONTROL
            COMPUTING IN REAL TIME.
          </p>
          <button class="Thechasmbutton" onClick={Thechasmbutton}>view More</button>
        </div>

      </div>
    </div>
    <div className="col-md-6">
      <div className="box">
<img src="https://zuppa.io/img/saravanaraju.jpg" alt="" srcset="" />
        <div class="overlay">
          <h3>THE FASCINATING JOURNEY</h3>
          <p className="ThechasParaOne">
            This is the journey of Saravana Raju who hails from a fishing
            hamlet in Chennai to being one among the experience drone pilot
            today training future drone pilots at CASR RPTO MIT Anna
            University .
          </p>
          <button class="Thechasmbutton" onClick={Fascinatingjourneybutton}>view More</button>
        </div>
      </div>
    </div>
  </div>
</div>


      </div>



      {/* /* ******************** Patent Certify *********************** */}
     
      <div className="container mt-5">
      <div className="row">
        <div className="col-md-6 certificateHome">
          <img src={pantent_cer} alt="Left Image" style={{height:"350px"}} />
        </div>
        <br/>
        <br/>
     
        <div className="col-md-6 d-flex flex-column justify-content-center">
        <br/>
          <h2 className="patent-certifyHead">PATENT CERTIFICATE</h2>
          <br/>
          <p className="patent-certify">
            Exciting News: India's First-Ever Patent for Computing Architecture! 🎉 Venkatesh Sai secures India's inaugural patent
            for computing architecture. Filed in 2015 under Sree Sai Aerotech Innovations Pvt Ltd (now Zuppa Geo Navigation Technologies Pvt
            LTD), the architecture has been successfully implemented in the
            #navgati autopilot system. A significant milestone for India's tech scene! 🚀
          </p>
        </div>
      </div>
    </div>
<br/>

{/* ******************* motherboard *********************** */}
      <div className="Motherboard">
        <h1 className="Motherboard-heading text-center">
          GENERATION 5 MOTHERBOARDS MADE IN INDIA
        </h1>
        <div className="row">
          <div className="col-md-6">
            <div className="rectangle transparent">
              <p className="rectangle-text">
                Intels Computing Architectures like i3, i5, i7 or QUALCOMM's s4,
                s6, s7, s8 have revolutionised our lives by powering devices
                like laptops, Mobile phones;
              </p>
            </div>
            <div className="rectangle transparent">
              <p className="rectangle-text">
                Presenting ZUPPA's "made in india dpcc Computing Architectures"
                that is powering future ready devices for 5th Generation
                industries like Drones, Unmanned Systems, ADAS, IOT and
                Connected Mobility.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-container">
              <img src={Autopilot} alt="Image" className="rectangle-image" />
            </div>
          </div>
        </div>
      </div>

{/* ------------------------------------ Our Clients ---------------------------------- */}

      
      <div className="client">
      <h1 className="text-center client-heading"> Our Clients </h1>
      <Ticker items={items} />
        </div>
    
{/* *****************************************  product Review  ********************************** */}
 {/* <div style={{marginTop:"20px"}}>
 <ProductSlider />
 </div> */}
 {/* *****************************************  customer Review  ********************************** */}


 <div className="container mt-n3">
  <Col>
    <h1 className="text-center Motherboard-heading">What Our Customers Are Saying</h1>
    <Zoom duration={1800}><hr className="Break-line-one"></hr></Zoom>
  </Col>
</div>
  
<CustomerReview />

    </div>
  );
};

export default Home;
