import React, { useEffect } from "react";
import "./ArmedForces.css";
import Header from "../components/header";
import { Fade } from "react-awesome-reveal";
import Footer from "./footer";




const ArmedForces = () => {
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
    <>
      <Header />
      <div className="container">
        <div className="about-header text-center">
          <h4>ARMED FORCES GET READY FOR FUTURE WARFARE!</h4>
        </div>
      </div>
   {/* <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="marquee-container">
              <marquee
                class="ajeetheader"
                behavior="alternate"
                direction="left"
              >
                <h1>ARMED FORCES GET READY FOR FUTURE WARFARE!</h1>
              </marquee>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div
          className="col-sm-4"
          style={{ marginLeft: "15%", width: "70%", height: "auto" }}
        >
          <img
            className="img-fluid"
            src="https://zuppa.io/product-img/Armed%20forces%20get%20ready%20for%20future%20warfare!.jpg"
            alt="Responsive image"
            style={{ height: "auto", width: "100%" }}
          />
        </div>
      </div>
      <br />

      <div className="container">
        <div className="row">
          <div className="Armed-para">
            <div className="col-md-8 offset-md-2 Armed-para-container ">
       <Fade duration={1200} direction="left">
       <p style={{ marginTop: "15px" }}>
                New Delhi: Defence Minister Rajnath Singh said on Monday that
                India needs to work on artificial intelligence mechanism
                "extremely carefully". Inaugurated the first-ever Artificial
                Intelligence in Defence (AIDef) symposium and exhibition of
                AI-enabled solutions, the Defence minister said that drawing
                lessons from the ongoing Russia-Ukraine war, the Indian Armed
                Forces are pushing for adoption of new technologies.
              </p>
       </Fade>
       <Fade duration={1800} direction="left">
              <p style={{ marginTop: "15px" }}>
                From focusing on Artificial Intelligence (AI), Quantum Labs,
                Industry 4.0, Machine Learning, Neural Networks and Deep
                Learning algorithms, Robotics and much more are gradually being
                introduced and adopted to ensure that the Indian forces are more
                tech savvy and geared to meet new threats and ready for future
                wars. He said India should be ready to face the "legal, ethical,
                political and economic upheaval" that may follow the expansion
                of this technology. Stressing that India needs to make
                advancements in AI technology, Rajnath Singh said it should not
                be dominated by a country or a group, as has been the case with
                nuclear technology. "We have to use artificial intelligence (AI)
                for humanity's progress and peace. It should not be the case
                that a country or a group of countries establish their dominance
                on this technology - just like nuclear power - and the remaining
                countries are not able to enjoy the fruits of this technology,"
                the Defence minister said.
              </p>
              </Fade>

              <Fade duration={2000} direction="left">
              <p style={{ marginTop: "15px" }}>
                Rajnath Singh also noted that AI's ethics and possible dangers
                must be properly thought-out. "We cannot stop the progress of
                artificial intelligence and we should not try to stop its
                progress. But we need to be careful about it," he mentioned.
                "Since AI is a technology that can bring a massive change, we
                must be ready to face the legal, ethical, political and economic
                upheaval that may follow," Rajnath Singh noted. "We need to work
                on AI extremely carefully so that this (technology) does not go
                out of our control in the coming times," he added.{" "}
              </p>
              </Fade>

            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default ArmedForces;
