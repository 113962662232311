
import React, { useEffect } from 'react';
import "./Awards.css";
import Header from "../components/header";
import Footer from "../components/footer"
import {Fade} from "react-awesome-reveal"

const Awards = () => {


  // JSON data
  const blogs = [
    
    {
      title: "ALUMNI ACHIEVEMENT AWARD",
      date: "29",
      month: "NOVEMBER",
      thumbnail:"https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735304948/jfoascy07auvggegkwzh.png",
      content:"India’s oldest electronics industry body Electronic Industries Association of India-ELCINA recognized the Technical Director of Zuppa Geo Navigation Technologies Pvt LTD by way of their prestigiousdefence technology awards winner 2024.",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7179840077726961665/",
   
          Fade:"left"
      },
  
    {
      title: "Defennovation Award 2024",
      date: "10",
      month: "APRIL",
      thumbnail:"https://shop.zuppa.io/static/media/venkatAward.1988e69ccbe75414e881.jpeg",
       content:"India’s oldest electronics industry body Electronic Industries Association of India-ELCINA recognized the Technical Director of Zuppa Geo Navigation Technologies Pvt LTD by way of their prestigiousdefence technology awards winner 2024.",
        link: "https://www.linkedin.com/posts/sai-pattabiram-299a843b_perseverance-dedication-commitment-ugcPost-7220355070377222144-oGeI/?utm_source=share&utm_medium=member_desktop",
        Fade:"right",
      },
  
   
  
  ];
  
  
  
  const Events = [
    
    {
      title: "The Show Stopper @AIDef 2022 Generates Eyeballs",
      thumbnail:
        "https://zuppa.io/product-img/Zuppas-Ajeet-Mini-%E2%80%93-the-Show-Stopper-@-AIDef-2022-Generates-Eyeballs.jpg",
      content:
      "The first-ever Artificial Intelligence in Defence (AIDef) symposium and exhibition organised by the Ministry of Defence in New Delhi last week showcased India's stride towards achieving self-reliance in defence technologiesAt the event, 75 defence products powered by artificial intelligence were launched by the Defence Minister Rajnath Singh as part of 'Azadi Ka Amrit Mahotsav' celebrations.",
        link: "/Eyeballs",
          Fade:"left"
      },
  
  
    // {
    //   title: "Changemakers",
    //   date: "10.04.2024",
    //   month: "APRIL",
    //   thumbnail:
    //     "https://zuppa.io/product-img/Changemakers%20New%20Milestones%20for%20%20Make%20In%20India%20in%20Defense%20sector.jpg",
    //   content:
    //     "Changemakers: New Milestones for 'Make In India' in Defense sector Patent Applied for Unique MADE IN INDIA Artificial Intelligence (AI) Technology.",
  
    //     link: "https://www.linkedin.com/posts/sai-pattabiram-299a843b_perseverance-dedication-commitment-ugcPost-7220355070377222144-oGeI/?utm_source=share&utm_medium=member_desktop",
    //   },
  
      {
        title: "Armed forces get ready for future warfare!!",
        // date: "29",
        // month: "NOVEMBER",
        thumbnail:
          "https://zuppa.io/product-img/Armed%20forces%20get%20ready%20for%20future%20warfare!.jpg",
        content:"Defence Minister Rajnath Singh said on Monday that India needs to work on artificial intelligence mechanism extremely carefully Inaugurated the first-ever Artificial Intelligence in Defence (AIDef) symposium and exhibition of AI-enabled solutions, the Defence minister said that drawing lessons from the ongoing Russia-Ukraine war, the Indian Armed Forces are pushing for adoption of new technologies.",
          link: "/Armedforces",
            Fade:"right"
        },
  
        {
          title: "Ajeet Mini makes a sterling debut at Bharat Drone Mahotsav 2022",
          // date: "29",
          // month: "NOVEMBER",
          thumbnail:
            "https://zuppa.io/product-img/Ajeet%20Mini%20makes%20a%20sterling%20debut%20at%20Bharat%20Drone%20Mahotsav%202022.jpg",
          content:"Ajeet Mini India’s Aatmnirbhar answer to global brands like DJI, Parot, Skydio, Autel and the likes was unveiled by our Chief Innovation Officer Venkatesh Sai at the Bharat Drone Mahotsav 2022 on 27/5/22 to the Honourable Prime Minister Narendra Modi and Minister of Civil Aviation Jyotiraditya Scindia at Pragati Maidan.",
            link: "/Ajeetmini",
              Fade:"left"
          },
  
  
          {
            title: "We were honoured to host  multiple indegenious drone manufacturers",
            // date: "29",
            // month: "NOVEMBER",
            thumbnail:
              "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741003834/xi26slo80c1t8pa7m4yl.jpg",
            content:"We at Zuppa always believe in pushing the envelope with indegenious drones and electronic components. We were honoured to host Manik Anandh , MoD and multiple indegenious drone manufacturers and drone component manufacturers based in and around Chennai and facilitate a great interaction",
              link: "https://www.linkedin.com/posts/zuppa-drones_we-at-zuppa-always-believe-in-pushing-the-activity-7195433650795028480-Kxh0/?utm_source=share&utm_medium=member_desktop",
             Fade:"right"
            },
  
  
            {
              title: "We were honoured to host  Vice Chief of the Army Staff India",
              // date: "29",
              // month: "NOVEMBER",
              thumbnail:
                "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1741003953/iybppsrjctiyuqvqqs1k.jpg",
              content:"We had the honor of hosting Lt. Gen. N. S. Raja Subramani, PVSM, AVSM, SM, VSM, Vice Chief of the Army Staff, along with Maj. Gen. C. S. Mann, VSM, Additional Director General - Army Design Bureau, Brig. N. R. Pandey, and Lt. Col. Saurabh Kakkar at our office on 6th September 2024. ",
                link: "https://www.linkedin.com/posts/sai-pattabiram-299a843b_deeptech-atmanirbharta-ugcPost-7240332995893583872-FN6N/?utm_source=share&utm_medium=member_desktop",
             Fade:"left"
              },
  
        
     
     
          ];
  
  
  
  
  
  
  
  
  
  
  
    useEffect(() => {
      const body = document.querySelector('#root'); 
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
      
      }, []);
  
    return (
  <div>
          <Header />
         {/* Parallax Header */}
         <div className="parallax-header">
          <img className='awardHeaderImage' src='https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735299265/z2ce6nfg4ay7oyhpzgd0.webp'/>
         </div>
   <br/>
   <br/>
         <h3 className='text-center innovation_awards'>Innovation drives us, recognition fuels us, and deep technology defines us</h3>
        
        
        
         <br/>
  <div className="blog-card-container-main" style={{ marginTop: "10px" }}>
      {blogs.map((blog, index) => (
        <div className="fond-main" key={index}>
            <Fade direction={`${blog.Fade}` } duration={1800}>
          <div className="news-cards-main">
  
            <div className="news-thumbnails-main">
              <img
                className="news-left-image-main"
                src={blog.thumbnail}
                alt="Thumbnail"
              />
            </div>
            <div className="right-main">
              <h1 className="news-heading-blog-main">{blog.title}</h1>
            
              <div className="separator-main"></div>
              <p className="news-content-main">{blog.content}</p>
            </div>
            <div className="news-footer-main">
              <div className="date-container-main">
                <h6 className="date-main">{blog.date}</h6>
                <h6 className="month-main">{blog.month}</h6>
              </div>
              <div className="learn-more-container-main">
              <button
                  className="learn-more-main"
                  onClick={() => window.open(blog.link, "_blank")}
                >
                  View More
                </button>
              </div>
            </div>
          </div>
          </Fade>
        </div>
      ))}
    </div>
  <br/>
  <br/>
  <Fade direction='left' duration={1800}>
  
  
  
  <h1 className="text-center eventaward">EVENTS</h1>
  </Fade>
  <br/>
  <br/>
  <div className="blog-card-container-main" style={{ marginTop: "10px" }}>
    {Events.map((event, index) => (
      <div className="fond-main" key={index}>
          <Fade direction={`${event.Fade}` } duration={1800}>
        <div className="news-cards-main">
          <div className="news-thumbnails-main">
            <img className="news-left-image-main" src={event.thumbnail} alt="Thumbnail" />
          </div>
          <div className="right-main">
            <h1 className="news-heading-blog-main">{event.title}</h1>
            <div className="separator-main"></div>
            <p className="news-content-main">{event.content}</p>
          </div>
          <div className="news-footer-main">
            <div className="date-container-main">
              <h6 className="date-main">{event.date}</h6>
              <h6 className="month-main">{event.month}</h6>
            </div>
            <div className="learn-more-container-main">
              <button className="learn-more-main" onClick={() => window.open(event.link, "_blank")}>
              View More
              </button>
            </div>
          </div>
        </div>
        </Fade>
      </div>
    ))}
  </div>
  <Footer />
  
  </div>
    );
  }
  

export default Awards;
