import React, { useState, useEffect } from 'react';
import karthik from "../assets/images/karthik.jfif"
import praveen from "../assets/images/praveenAshok.jfif"
import Thirukumaran from "../assets/images/Thirukkumaran.jfif"
import Sivaraman from "../assets/images/Sivaraman.jfif"
import EmptyProfile from "../assets/images/profileEmpty.png"

import Fade, { Zoom } from "react-awesome-reveal";
import "./CustomerReview.css";

const CustomerReview = () => {
  const [index, setIndex] = useState(0);

  const handleSlideChange = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % customer.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const customer = [
    {
      name: "Ashok Rajan",
      image: EmptyProfile,
       description: "Recently purchased ajeet mini for my Institution funded Research work. The mini drone is excellent in working and is user friendly. So highly recommended.  Mr.R.Ashok, AP, Mech department, SRM Valliammai Engineering College",
      rating: "⭐⭐⭐⭐⭐"
    },
    {
      name: "Karthick Guru",
      image: karthik,
     role:"R&D Architect @ Nokia  Lean Six Sigma, Private Cloud, Kubernetes, Private Wireless Networks",
      description: "It is very good growing organization. The company has Multiple products and good product development team, Mechanical designers and Drone pilots are there. it is good platform for related employees and Clients",
      rating: "⭐⭐⭐⭐⭐"
    },
    {
      name: "Praveen Ashok",
      image: praveen,
        role:"Sr. Technical Program Manager @ US Ignite Systems Engineering ",
      description: "It is MSME company which serves as an OEM for products related to automotive and aerospace technology with professional and innovative ideas to meet the market. It has it's own Production, R&D and Tech support team which are powered by young passionate minds. They work on the motto of Made in India.",
      rating: "⭐⭐⭐⭐⭐"
    },
    // {
    //   name: "Shankar Garigiparthy",
    //   image: avatar,
    //   description: "After a couple of initial glitches, mainly due to the SIM card, the team addressed the problem quickly by changing the SIM card and also following up to make sure that the GPS worked well.Pretty accurate positioning and tracking via the GPS",
    //   rating: "⭐⭐⭐⭐⭐"
    // },
    {
      name: "Thirukumaran",
      image: Thirukumaran,
          role:"Head : Business Development - Enterprise XR @ RRD, Enterprise XR Expert, Ex Tech Entrepreneur",
      
      description: "A dedicated team with a get it done attitude. They are the best at what they do not just in the nation but in the region globally as well.",
      rating: "⭐⭐⭐⭐⭐"
    },
    // {
    //   name: "Janarthanan",
    //   image: avatar,
    //   description: "Small company making big things, manufacturing UAV flight controller and lot other components for defence and commercial purpose.",
    //   rating: "⭐⭐⭐⭐⭐"
    // },
    {
      name: "Sivaraman",
      image: Sivaraman,
              role:"Defence Technocrat, TedX Speaker, Post Doc in Nanotech",
      description: "Small company making big things, manufacturing UAV flight controller and lot other components for defence and commercial purpose.",
      rating: "⭐⭐⭐⭐⭐"
    },

  ];

  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner col-md-8 offset-md-3">
        {customer.map((review, idx) => (
          <div key={idx} className={`carousel-item ${idx === index ? 'active' : ''}`}>
            <div className="container">
              <div className="row flex-row flex-nowrap overflow-auto">
                <div className="col-md-6">
                  <div className="review-card mr-3">
                    <div className="media">
                      <Zoom>
                        <img src={review.image} className="mr-3 review-avatar" alt="Customer Avatar" />
                      </Zoom>
                      <div className="media-body">
                        <Fade>
                          <h3 className="mt-2 review-name">{review.name}</h3>
                        </Fade>
                        <h5>{review.role}</h5>
                        <p className='customerPara'>{review.description}</p>
                        <div className="rating">{review.rating}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <a className="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev" onClick={() => handleSlideChange((index - 1 + customer.length) % customer.length)}>
        <span className="carousel-control-prev-icon" aria-hidden="true" style={{color:"900"}}></span>
      </a>
      <a className="carousel-control-next" href="#carouselExample" role="button" data-slide="next" onClick={() => handleSlideChange((index + 1) % customer.length)}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
  );
}

export default CustomerReview;
