// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-container{
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    margin: 50px auto;
    gap: 30px;
}



.hello-world {
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .hover-animation:hover {
  color: rgb(9, 9, 80);
    transform: scale(1.1); /* Example animation: scale up on hover */
  }

.employee-header{
    text-align: center;
    margin: 11px auto;
}
.card-container{
padding: 10px;

}
.card-container{
   color: rgb(29, 0, 109);
   font-size: 14px;
    
    }
.personlogo{
    margin-left: 90%;
    margin-top: -80px;
}
.card-name{
    color: rgb(12, 75, 0);
    
}
.paratag {
    color:black;
    font-weight: 600;
 }

`, "",{"version":3,"sources":["webpack://./src/pages/Review.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,aAAa;IACb,iBAAiB;IACjB,SAAS;AACb;;;;AAIA;IACI,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;EACA,oBAAoB;IAClB,qBAAqB,EAAE,yCAAyC;EAClE;;AAEF;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;AACA,aAAa;;AAEb;AACA;GACG,sBAAsB;GACtB,eAAe;;IAEd;AACJ;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,qBAAqB;;AAEzB;AACA;IACI,WAAW;IACX,gBAAgB;CACnB","sourcesContent":[".review-container{\r\n    display: grid;\r\n    grid-template-columns: auto auto auto auto;\r\n    padding: 10px;\r\n    margin: 50px auto;\r\n    gap: 30px;\r\n}\r\n\r\n\r\n\r\n.hello-world {\r\n    text-align: center;\r\n    transition: all 0.3s ease;\r\n  }\r\n  \r\n  .hover-animation:hover {\r\n  color: rgb(9, 9, 80);\r\n    transform: scale(1.1); /* Example animation: scale up on hover */\r\n  }\r\n\r\n.employee-header{\r\n    text-align: center;\r\n    margin: 11px auto;\r\n}\r\n.card-container{\r\npadding: 10px;\r\n\r\n}\r\n.card-container{\r\n   color: rgb(29, 0, 109);\r\n   font-size: 14px;\r\n    \r\n    }\r\n.personlogo{\r\n    margin-left: 90%;\r\n    margin-top: -80px;\r\n}\r\n.card-name{\r\n    color: rgb(12, 75, 0);\r\n    \r\n}\r\n.paratag {\r\n    color:black;\r\n    font-weight: 600;\r\n }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
