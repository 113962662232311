// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mro-lab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.responsive-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}


.back-button {
  margin-top: 10px;
  align-self: center; /* This centers the button horizontally */
}

@media (max-width: 768px) {

  .back-button {
    width: 100%;
    max-width: 300px;
    font-size: 1rem;
  }

}

@media (max-width: 480px) {
  .back-button {
    font-size: 0.9rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MroLab/MroLab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;;AAGA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;;AAGA;EACE,gBAAgB;EAChB,kBAAkB,EAAE,yCAAyC;AAC/D;;AAEA;;EAEE;IACE,WAAW;IACX,gBAAgB;IAChB,eAAe;EACjB;;AAEF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".mro-lab-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n\r\n.responsive-image {\r\n  max-width: 100%;\r\n  height: auto;\r\n  margin-bottom: 20px;\r\n  border: 1px solid #ddd;\r\n  border-radius: 8px;\r\n}\r\n\r\n\r\n.back-button {\r\n  margin-top: 10px;\r\n  align-self: center; /* This centers the button horizontally */\r\n}\r\n\r\n@media (max-width: 768px) {\r\n\r\n  .back-button {\r\n    width: 100%;\r\n    max-width: 300px;\r\n    font-size: 1rem;\r\n  }\r\n\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  .back-button {\r\n    font-size: 0.9rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
