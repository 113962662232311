// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iiot h3{
    font-Family: 'Open Sans, sans-serif';
    color: rgb(9, 3, 37);
}
.iiot P{
    font-Family: 'Open Sans, sans-serif';
    font-weight: 800;

}

.iiot-content{
    background-color: rgb(204, 189, 170);
    border: 1px solid transparent; /* Start with a transparent border */
    animation: multicolorBorderAnimation 5s infinite;
    border-radius: 20px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
}

@keyframes multicolorBorderAnimation {
    0% {
      border-color: rgb(245, 131, 0); 
    }
    25% {
      border-color: rgb(156, 120, 0); 
    }
    50% {
      border-color: rgb(24, 24, 63); 
    }
    75% {
      border-color: rgb(204, 119, 0); 
    }
    100% {
      border-color: rgb(55, 48, 97); 
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/EdgeProducts/IIOT.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oBAAoB;AACxB;AACA;IACI,oCAAoC;IACpC,gBAAgB;;AAEpB;;AAEA;IACI,oCAAoC;IACpC,6BAA6B,EAAE,oCAAoC;IACnE,gDAAgD;IAChD,mBAAmB;IACnB,sCAAsC;IACtC,uCAAuC;AAC3C;;AAEA;IACI;MACE,8BAA8B;IAChC;IACA;MACE,8BAA8B;IAChC;IACA;MACE,6BAA6B;IAC/B;IACA;MACE,8BAA8B;IAChC;IACA;MACE,6BAA6B;IAC/B;EACF","sourcesContent":[".iiot h3{\r\n    font-Family: 'Open Sans, sans-serif';\r\n    color: rgb(9, 3, 37);\r\n}\r\n.iiot P{\r\n    font-Family: 'Open Sans, sans-serif';\r\n    font-weight: 800;\r\n\r\n}\r\n\r\n.iiot-content{\r\n    background-color: rgb(204, 189, 170);\r\n    border: 1px solid transparent; /* Start with a transparent border */\r\n    animation: multicolorBorderAnimation 5s infinite;\r\n    border-radius: 20px;\r\n    box-shadow: 0 1px 2px rgba(0,0,0,0.15);\r\n    transition: box-shadow 0.3s ease-in-out;\r\n}\r\n\r\n@keyframes multicolorBorderAnimation {\r\n    0% {\r\n      border-color: rgb(245, 131, 0); \r\n    }\r\n    25% {\r\n      border-color: rgb(156, 120, 0); \r\n    }\r\n    50% {\r\n      border-color: rgb(24, 24, 63); \r\n    }\r\n    75% {\r\n      border-color: rgb(204, 119, 0); \r\n    }\r\n    100% {\r\n      border-color: rgb(55, 48, 97); \r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
