import React from 'react'
import Header from "../components/header";
import Footer from "../components/footer";

function ShippingPolicy() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="about-header text-center">
          <h3>SHIPPING POLICY</h3>
        </div>
      </div>
      <br></br>
      <br></br>
      <div
        style={{
          backgroundColor: "orange",
          color: "white",
          width: "72.5%",
          margin: "auto",
          padding: "30px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center vh-80">
          <div
            style={{
              backgroundColor: "#0f143b", // Example color for the nested box
              padding: "50px",
              borderRadius: "8px",
              width: "100%",
              color:'white'
            }}
          >

<p> Thank you for choosing to shop with us at Zuppa.io! We want to make sure your shopping experience is as seamless as possible, which includes providing clear information about our shipping policies.</p>

<h4 style={{color:"orange"}}>Shipping Rates and Timelines</h4>
<p>Domestic Shipping Within INDIA We offer flat-rate shipping for domestic orders. Shipping costs and delivery times may vary based on the destination and the weight of the package. Please refer to the checkout page for accurate shipping rates and estimated delivery times.</p>

<p>International Shipping: We also offer international shipping to select countries. International shipping rates and delivery times vary depending on the destination and customs processing. Please note that customers are responsible for any customs duties, taxes, or fees imposed by their country's customs regulations.</p>

<h4 style={{color:"orange"}}>Order Processing Time</h4>
<p>Orders are typically processed within 1-2 business days from the time of purchase, excluding weekends and holidays. Once your order has been processed, you will receive a confirmation email with tracking information and you will get the product within 7-10 days </p>

<h4 style={{color:"orange"}}>Shipping Carriers</h4>
<p>We partner with reputable shipping carriers to ensure reliable and timely delivery of your orders. Our primary shipping partners include DTDC, Delivery Courier, and Blue Dart. The selection of the carrier depends on the shipping method chosen at checkout and the destination of the package.</p>


<h4 style={{color:"orange"}}>Tracking Information</h4>
<p>Once your order has been shipped, you will receive a shipping confirmation email containing tracking information. This allows you to track your package in real-time and monitor its progress until it reaches your doorstep.</p>


<h4 style={{color:"orange"}}>Shipping Delays</h4>
<p>While we strive to deliver your orders within the estimated timeframes, please note that unforeseen circumstances such as weather delays, natural disasters, or peak holiday seasons may cause delays in shipping. We appreciate your patience and understanding in such situations.</p>


<h4 style={{color:"orange"}}>Delivery Issues</h4>
<p>If you encounter any issues with the delivery of your order, please contact our customer service team at <a
    href="mailto:askme@zuppa.io">askme@zuppa.io</a>. with your order number and details of the problem. We will do our best to assist you and resolve any issues promptly.</p>

<h4 style={{color:"orange"}}>Shipping Restrictions</h4>
<p>Some items may be subject to shipping restrictions due to size, weight, or regulatory constraints. We apologize for any inconvenience this may cause and appreciate your understanding..</p>

<h4 style={{color:"orange"}}>Changes to Shipping Policy</h4>
<p>We reserve the right to update or modify our shipping policy at any time without prior notice. Any changes to the shipping policy will be reflected on this page.</p>
<p>We reserve the right to update or modify
    our return policy at any time without prior notice. Any changes to the return
    policy will be reflected on this page.</p>

<p>If you have any further questions or concerns regarding our shipping policy, please don't hesitate to contact us at <a
    href="mailto:askme@zuppa.io">askme@zuppa.io</a>. Thank you for shopping with us!. Thank you for shopping with
    us!</p>


      <h5 >Zuppa.io</h5>
      <h5  >https://zuppa.io/</h5>
      <h5 ><span style={{color:"orange"}}>Mobile : </span> +91 9952081655</h5>



          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
}

export default ShippingPolicy;
