// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jobs-skills-container {
  display: flex;
  justify-content: center; /* Horizontally center the image */
  align-items: center; /* Vertically center the image */ /* Set the height to full viewport height to center vertically */
}

.jobs-skills-container img {
  max-width: 100%;
  height: auto; /* Ensure the image maintains its aspect ratio */
}
`, "",{"version":3,"sources":["webpack://./src/components/JobOpportunity/JobSkill.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,kCAAkC;EAC3D,mBAAmB,EAAE,gCAAgC,EAAE,gEAAgE;AACzH;;AAEA;EACE,eAAe;EACf,YAAY,EAAE,gDAAgD;AAChE","sourcesContent":[".jobs-skills-container {\r\n  display: flex;\r\n  justify-content: center; /* Horizontally center the image */\r\n  align-items: center; /* Vertically center the image */ /* Set the height to full viewport height to center vertically */\r\n}\r\n\r\n.jobs-skills-container img {\r\n  max-width: 100%;\r\n  height: auto; /* Ensure the image maintains its aspect ratio */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
