import React, { useEffect } from "react";
import "./Technology.css";
import Header from "../components/header";
import Footer from "../components/footer";
import { Fade } from "react-awesome-reveal";
import GamesIcon from "@mui/icons-material/Games";


const Technology = () => {
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
    <div className="technology-main">
      <Header />
      <div className="container technical-para">
        <div className="tech-header text-center ">
          <h2>TECHNOLOGY</h2>
  
          <h4>
            SYSTEM OF DISSEMINATED PARALLEL CONTROL COMPUTING IN REAL TIME{" "}
          </h4>
        </div>

        <Fade
          className="technology-container-one"
          duration={1800}
          direction="left"
        >
          <div className=" d-flex justify-content-between row align-items-center ">
            <div className="col-lg-5 mb-lg-3">
              <img
                className="img-fluid"
                src="https://media.licdn.com/dms/image/D4D12AQF_w7GFjpbt4g/article-cover_image-shrink_600_2000/0/1686981310860?e=2147483647&v=beta&t=yc_cWzw5xYxM-1PonUMctSl3nWgET57KK0gaLtEZlbY"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="tech-para col-12  p-3 ">
                <h5>HUMAN INTELLIGENCE : PARALLEL PROCESSING</h5>
               <Fade direction="right" duration={1200}>
                <p>
                  When we drive a car, we don't focus only on the car and
                  driving it; we also look for obstacles on the road, movement
                  of other vehicles, avoid crashing into pedestrians or animals
                  crossing the road, listen to music, carry on a conversation
                  with our passengers, and continue navigating our car to reach
                  the destination we started off towards .
                </p>
                </Fade>
                <Fade direction="right" duration={2100}>
                <p>
                  We do all this simultaneously while navigating through a
                  series of turns and bends and arrive at our planned
                  destination .
                </p>
                </Fade>
                <Fade direction="up" duration={1800}>
                
                <p>
                  Parallel processing is our ability to deal with multiple
                  stimuli simultaneously to control vehicles .
                </p>
                </Fade>
                <Fade direction="down" duration={1800}>
                
                <p>
                  Our ability to process all these different kinds of stimuli at
                  once through our reflex and cognitive nerve systems enables
                  our brain to direct our hands and legs to act in unison while
                  performing the act of driving .
                </p>
                </Fade>
              </div>
            </div>
          </div>
        </Fade>

        <br />
        <Fade
          className="technology-container-two"
          duration={1800}
          direction="right"
        >
          <div className="  d-flex justify-content-between row align-items-center">
            <div className="col-lg-5 order-lg-5 mb-3 mb-lg-3">
              <img
                className="img-fluid"
                src="https://readitquik.com/wp-content/uploads/2022/02/ai-1.gif"
                alt=""
              />
            </div>
            <div className="col-md-6 order-lg-1">
              <div className="tech-para-one">
                <h5>ZUPPA’S A. I: DPCC</h5>
                <Fade direction="up" duration={1200}>
                <p>
                  ZUPPA’s “SYSTEM OF DISSEMINATED PARALLEL CONTROL COMPUTING IN
                  REAL TIME (DPCC)” is a Bio Mimicry of the “Human Intelligence
                  system of parallel processing .
                </p>
                </Fade>
                <Fade direction="down" duration={1400}>
                <p>
                  Humans process multiple stimuli in Parallel when controlling a
                  vehicle be it on the ground,in the air or water .
                </p>
                </Fade>
                <Fade direction="down" duration={1600}>
                <p>
                  DPCC is a control computing architecture that performs in a
                  way similar to Human intelligence to control vehicles like
                  Drones in the air,UGV’s on the ground or USV’s in water.{" "}
                </p>
                </Fade>
                <Fade direction="up" duration={2000}>
                <p>
                  DPCC processes data from sensors like IMU’s , Accelerometer ,
                  Gyro, magnetometer , barometer , GPS to control and navigate
                  the vehicle and make them unmanned or driverless .
                </p>
                </Fade>
                <Fade direction="up" duration={2100}>
                <p>
                  ZUPPA’s NavGati Motherboard for unmanned systems and Drishya
                  for Vision guidance & navigation are all set to lead 5th
                  Generation technologies in the emerging industries of Drones,
                  Defence , ADAS , IoT and connected mobility
                </p>
                </Fade>
              </div>
            </div>
          </div>
        </Fade>
        <Fade
          className="technology-container-three"
          duration={1800}
          direction="up"
        >
          <div className=" d-flex justify-content-between row align-items-center">
            <div className="col-md-6">
              <img
                className="tech-image img-fluid"
                src="https://zuppa.io/product-img/techno.jpg"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <div className="tech-para ">
                <h5>ZUPPA AI - THE TECHNOLOGY</h5>
                <b>UNMANNED SYSTEMS</b>

<Fade direction="right" duration={1200}>

                <p>
                  NavGati Autopilot: is a motherboard that uses data from
                  sensors like IMU, Accelerometer, Gyro, magnetometer, barometer
                  GPS to control a vehicle along X, Y & Z axis electronically in
                  exactly the same way a human would...
                </p>
                </Fade>
                <b>VISION GUIDANCE SYSTEMS</b>
                <Fade direction="left" duration={1200}>
                <p>
                  Drishaya Vision: is a motherboard that uses live camera feed
                  much like we Humans use our eyes for driving or piloting a
                  vehicle in real time.
                </p>
                </Fade>
                <Fade direction="right" duration={1200}>
                <p>
                  DPCC also processes video feeds from Multiple cameras to
                  create 360 Deg Vision Based guidance and navigation systems .
                </p>

                </Fade>
                <b>IOT & CONNECTED MOBILITY</b>
                <Fade direction="left" duration={1200}>
                <p>
                  EDGE Data Gateway: is a motherboard that acquires and
                  transmits data to cloud servers using wireless technologies.
                </p>
                </Fade>
              </div>
            </div>
          </div>
        </Fade>
      </div>

   
      <Footer />
    </div>
  );
};

export default Technology;
