import React, { useState, useContext } from "react";
import "../pages/auth/Login.css";
import { useNavigate, useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Header from '../components/header'


const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {id} = useParams()
  const {token} = useParams()

  const postLoginMethod = () => {
    
    const newLoginData = {
      password: password,
      id: id,
      token:token
    };

    fetch("https://shop.zuppa.io:4000/resetpassword/:id/:token", {
      method: "POST",
      body: JSON.stringify(newLoginData),
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => data.json())
      .then((f) => alert(f.message));
      navigate("/Login")
  };



  return (
    <div>
    <Header/>
      <div className="login-container">
        <div className="wrapper">
          <div action="" className="loginpara">
            <h1>Reset Password </h1>

            <div className="input-box">
            <input
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter Your Password"
            />
            {showPassword ? (
              < VisibilityIcon fontSize="large"  color="action" className="icon" onClick={handleTogglePasswordVisibility} />
            ) : (
              <VisibilityOffIcon fontSize="large" color="primary" className="icon" onClick={handleTogglePasswordVisibility} />
            )}
          </div>

            <button type="submit" onClick={postLoginMethod}>
              Submit
            </button>

            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;