


import React from 'react';
import mapImage from '../../assets/CompanyMap/screenshot.png'; 
import "./Company.css"
import Button from "@mui/material/Button"
import { useNavigate } from 'react-router-dom';


const Company = () => {

  const navigate = useNavigate()


  const homePage = ()=>{
    navigate("/")
  }



  return (
    <div>
    <br/>
    <h2 className='about-header-company'>PROPRIETARY AUTOPILOT COMPANIES</h2>
      <div>
      <br/>
      <h2 className='companyhead-company'>There are only 7 companies in the world, with Proprietary Autopilot systems.</h2>
      <img src={mapImage} className='autopilot-company'/>
      </div>
    
    </div>
  );
}

export default Company;
