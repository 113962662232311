// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .gpstracker h3{
    font-Family: 'Open Sans, sans-serif';
    color: rgb(83, 50, 1);
}
.gpstracker P{
    font-Family: 'Open Sans, sans-serif';
    font-weight: 800;
}

.gpsTrack-content{
background-color: rgb(255, 225, 185);
    border: 1px solid transparent; 
    animation: multicolorBorderAnimation 5s infinite;
    border-radius: 20px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
}
.gpsTrack-content:hover{
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
 
}


@keyframes multicolorBorderAnimation {
    0% {
      border-color: rgb(245, 131, 0); 
    }
    25% {
      border-color: rgb(156, 120, 0); 
    }
    50% {
      border-color: rgb(24, 24, 63); 
    }
    75% {
      border-color: rgb(204, 119, 0); 
    }
    100% {
      border-color: rgb(55, 48, 97); 
    }
  }
   
 `, "",{"version":3,"sources":["webpack://./src/components/EdgeProducts/GpsTracker.css"],"names":[],"mappings":"CAAC;IACG,oCAAoC;IACpC,qBAAqB;AACzB;AACA;IACI,oCAAoC;IACpC,gBAAgB;AACpB;;AAEA;AACA,oCAAoC;IAChC,6BAA6B;IAC7B,gDAAgD;IAChD,mBAAmB;IACnB,sCAAsC;IACtC,uCAAuC;AAC3C;AACA;IACI,sCAAsC;;AAE1C;;;AAGA;IACI;MACE,8BAA8B;IAChC;IACA;MACE,8BAA8B;IAChC;IACA;MACE,6BAA6B;IAC/B;IACA;MACE,8BAA8B;IAChC;IACA;MACE,6BAA6B;IAC/B;EACF","sourcesContent":[" .gpstracker h3{\r\n    font-Family: 'Open Sans, sans-serif';\r\n    color: rgb(83, 50, 1);\r\n}\r\n.gpstracker P{\r\n    font-Family: 'Open Sans, sans-serif';\r\n    font-weight: 800;\r\n}\r\n\r\n.gpsTrack-content{\r\nbackground-color: rgb(255, 225, 185);\r\n    border: 1px solid transparent; \r\n    animation: multicolorBorderAnimation 5s infinite;\r\n    border-radius: 20px;\r\n    box-shadow: 0 1px 2px rgba(0,0,0,0.15);\r\n    transition: box-shadow 0.3s ease-in-out;\r\n}\r\n.gpsTrack-content:hover{\r\n    box-shadow: 0 5px 15px rgba(0,0,0,0.3);\r\n \r\n}\r\n\r\n\r\n@keyframes multicolorBorderAnimation {\r\n    0% {\r\n      border-color: rgb(245, 131, 0); \r\n    }\r\n    25% {\r\n      border-color: rgb(156, 120, 0); \r\n    }\r\n    50% {\r\n      border-color: rgb(24, 24, 63); \r\n    }\r\n    75% {\r\n      border-color: rgb(204, 119, 0); \r\n    }\r\n    100% {\r\n      border-color: rgb(55, 48, 97); \r\n    }\r\n  }\r\n   \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
