import React from 'react';
import './Drone.css';
import DroneGif from "../../assets/drone.mp4";
import { useNavigate } from 'react-router-dom';

const Drone = () => {
  const navigate = useNavigate();

  const homePage = () => {
    navigate("/");
  };

  return (
    <div className="drone-container">
      <h2 className='drone-header'>WHAT MAKES A DRONE?</h2>
      <div className="drone-content">
        <div className="drone-left">
          <div className="drone-item">
            <div className="DRONESicon airframe-icon" ></div>
            <h4>Airframe</h4>
            <h6>Frame</h6>
          </div>
          <div className="drone-item">
            <div className="DRONESicon power-icon"></div>
            <h4>Power System</h4>
            <h6>Battery</h6>
            <h6>Motor</h6>
            <h6>Propellers</h6>
          </div>
          <div className="drone-item">
            <div className="DRONESicon payload-icon"></div>
            <h4>Payload</h4>
            <h6>Any additional weight like Camera</h6>
          </div>
        </div>
        <div className="drone-center"  style={{margin:"0px"}}>
          <video className="drone-video" autoPlay muted loop style={{margin:"0px"}}>
            <source src={DroneGif} type="video/mp4" style={{margin:"0px"}}/>
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="drone-right">
          <div className="drone-item">
            <div className="DRONESicon autopilot-icon"></div>
            <h4>Autopilot</h4>
            <h6>Flight Controller</h6>
            <h6>Sensors for Navigation</h6>
            <h6>Communication System</h6>
            <h6>CPU (Central Processing Unit)</h6>
          </div>
        
        </div>
        <br/>
      </div>
    </div>
  );
};

export default Drone;
