// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-right{
    background-color: rgb(250, 233, 196);
    font-Family: 'Open Sans, sans-serif';
}
.contact-left{
    font-Family: 'Open Sans, sans-serif';
}`, "",{"version":3,"sources":["webpack://./src/components/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC","sourcesContent":[".contact-right{\r\n    background-color: rgb(250, 233, 196);\r\n    font-Family: 'Open Sans, sans-serif';\r\n}\r\n.contact-left{\r\n    font-Family: 'Open Sans, sans-serif';\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
