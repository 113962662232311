import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "react-slideshow-image/dist/styles.css";
import "./FpvPage.css";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { Fade} from "react-awesome-reveal";
import mouseHover from "../assets/Autopilot/mousehover.gif";
import { useNavigate } from "react-router-dom";


function FpvPage() {
  const fadeImages = [ "newdrone5.jpg", "newdrone1.jpg"]; // Replace with your image paths
const navigate = useNavigate()
  const fadeProperties = {
    duration: 1000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: true,
  };
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header />

      <div className="container">
        <div className="about-header text-center">
          <h3>   AJEET MINI SCOUT FPV </h3>
        </div>

        <Carousel>
          {fadeImages.map((image, index) => (
            <Carousel.Item>
              <img src={image} alt={`slide-${index}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>


      <div
        style={{
          backgroundColor: "#0f143b",
          color: "white",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center vh-50">
          
        </div>
    
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
         <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '80%' }}>
              <p className="fw-bold ">Experience immersive first-person view (FPV) operations with the AJEET Mini FPV drone.  This compact, high-performance UAV delivers precision, agility, and real-time responsiveness, making it indispensable for military and industrial applications.</p>
            </Col>
          </Row>
        </div>
        <Fade className="container-one" duration={2000} direction="left">
       <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '600px' }}>
              <p className="fw-bold ">The AJEET FPV Drone delivers an immersive flight experience with high-definition visuals and intuitive motion controls.</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '600px' }}>
              <p className="fw-bold ">
              Ideal for tactical operations like surveillance, reconnaissance, and precision targeting.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '600px' }}>
              <p className="fw-bold ">
              offering unparalleled performance in challenging environments with real-time data transmission.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '600px' }}>
              <p className="fw-bold ">
              60% Indigenous Design and Manufacturing.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '600px' }}>
              <p className="fw-bold ">
              Day Camera(1080P) with 3-axis stabilizer
              </p>
            </Col>
          </Row>


      
            <Row className="justify-content-center fs-7 feature-content">
            <Col md={8} className="text-start mx-auto" style={{ maxWidth: '600px' }}>
              <p className="fw-bold ">
              32 GB (SD Card) Storage.
              </p>
              <br />
            </Col>
          </Row>
        </Fade>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "orange",
          color: "black",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
          <div className="text-center">
            <p className="text-center display-1 fw-bold text-light Tech-spec-text mt-5">
              Technical Specification
            </p>
          </div>
        </div>

        <Fade className="container-one" duration={2000} direction="right">
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              UAV Weight with Battery: Maximum 1.8Kgs

              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Endurance (Ideal Wind & MSL Conditions): 25 Minutes
                </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
            <Col md={8}>
              <p className="fw-bold">
              Range:3000 Meters One-Way
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Maximum Speed:25 Km/h-Auto and 100 Km/h-Manual
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Battery Charger:50W, 1-hour Charge Time
                </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Wind Resistance:36 Km/h
                </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Auto Pilot: NavGatiTM (Proprietary) - PCB & Firmware</p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Sensors:  Ground Facing LIDAR (TerrainFollow), Barometer, Magnetic Compass, Accelerometer</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Flight modes: Waypoint Navigation mode, Manual control mode, Hover, Position Hold</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Package: Soft Bag that houses all the sub-systems which allows the complete system to be carried and operated on the field by the crew</p>
            </Col>
          </Row>

      
       
        </Fade>
      </div>
{/* 
      <br />
      <br />
      <div className="text-center">
        <video autoPlay loop muted style={{ minWidth: "100%" }}>
          <source
            src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355960/xatlc73gwycu83ryj0kv.mp4"
            type="video/mp4"
          />
        </video>
      </div> */}
     
           <div className="row">
         
         <div className="col-lg-6">
        
         <a className="button-box" target="_blank" href="https://mkp.gem.gov.in/unmanned-aerial-vehicle-payload-systems-surveillance/scout-fpv/p-5116877-63401960585-cat.html">
                         <button className="gem-portal-fpv">
                           {/* <p className="buy-title text-center">Buy</p>                 */}
                           <img  className="gem-portal-image-fpv"  src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1737959121/li7t14kf6xrgjeihp44v.png" alt="Handshake" />
                         </button>
                         </a>
         </div>
         <div className="col-lg-6">
           
         <div className="button-box" onClick={()=> navigate("/DroneShop")}>
                         <button className="buy-button">
                           <p className="buy-title text-center">Buy</p>                
                           <img src={mouseHover} alt="Handshake" />
                         </button>
                       </div>
         </div>
         </div>

              <br />
              <br />
      <Footer />
    </div>
  );
}

export default FpvPage;
