import React from "react";
// import ReactDOM from 'react-dom/client';
import ReactDOM from "react-dom";
import "./index.scss";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import About from "./pages/About";
import Career from "./pages/Career";
import ShopNow from "./pages/ShopNow";
import NewsAndEvents from "./pages/NewsAndEvents";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import { CartProvider } from "./context/cartContext";
import { UserProvider } from "./context/userContext";
import Cart from "../src/components/Cart";
import Review from "../src/pages/Review";
import Technology from "./pages/Technology";
import CareerForm from "./pages/CareerForm";
import Drishya from "./pages/Drishya";

import Navigati from "./pages/Navigati";
import Edge from "./pages/Edge";
import Privacy_policy from "./components/Privacy_policy";
import ReturnPolicy from "./components/ReturnPolicy";
import ShippingPolicy from "./components/ShippingPolicy";
import Termsandcondition from "./components/Termsandcondition";
import Profile from "./components/Profile";
import EyeBalls from "./components/EyeBalls";

import ArmedForces from "./components/ArmedForces";
import AjeetMini from "./components/AjeetMini";
import Navgati_autopilot from "./pages/Navgati_autopilot";
import Zuppatrainer from "./pages/Zuppatrainer";
import Zuppascout from "./pages/Zuppascout";
import Error from "./components/Error";
import Forgotpassword from "./components/Forgotpassword";
import ResetPassword from "./components/ResetPassword";
import Cancellation from "./components/Cancellation";
import ProductSlider from "./components/ProductSlider";
import ContatctUs from "./components/ContactUs";
import GpsTracker from "./components/EdgeProducts/GpsTracker";
import ColdChain from "./components/EdgeProducts/ColdChain";
import IIOT from "./components/EdgeProducts/IIOT";
import Thechasm from "./pages/Thechasm";
import Fascinatingjourney from "./pages/Fascinatingjourney";
import Partner from "./components/Partners/Partner";
import Download from "./pages/Download";
import Drones from "./components/AllCategory/Drones"
import AutoPilot from "./components/AllCategory/AutoPilot"
import Software from "./components/Software";
import DroneLabHome from "./Drone lab/DroneLabHome";
import Introduction from "./components/Indroduction/Introduction"
import Industry from "./components/Industry/Industry"
import JobSkill from "./components/JobOpportunity/JobSkill";
import MakeDrones from "./components/MakeDrone/Drone";
import SensorManagement from "./components/SensorManagements/SensorManagements"
import WhoZuppa from "./components/Who-is-Zuppa/Zuppa";
import MroLabs from "./components/MroLab/MroLab";
import Innovation from "./components/InnovationLab/InnovationLab";
import ZuppaTrainerPlus from "./pages/ZuppaTrainerPlus";
import Defense from "./pages/Defense";



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/About",
    element: <About />,
  },

  {
    path: "/Navigati",
    element: <Navigati />,
  },
  {
    path: "/Technology",
    element: <Technology />,
  },
  {
    path: "/ShopNow",
    element: <ShopNow />,
  },
  {
    path: "/Career",
    element: <Career />,
  },
  {
    path: "/Download",
    element: <Download />,
  },
  {
    path: "/CareerForm",
    element: <CareerForm />,
  },
  {
    path: "/Review",
    element: <Review />,
  },
  {
    path: "/NewsAndEvents",
    element: <NewsAndEvents />,
  },

  {
    path: "/dronelabs",
    element: <DroneLabHome />,
  },
  {
    path: "/IntroductionDrones",
    element: <Introduction/>,
  },
  {
    path: "/Growth",
    element: <Industry/>,
  },
  {
    path: "/Jobs-skils",
    element: <JobSkill/>,
  },

  {
    path: "/MakeDrones",
    element: <MakeDrones/>,
  },
  {
    path: "/SensorManagement",
    element: <SensorManagement/>,
  },
  {
    path: "/WhoZuppa",
    element: <WhoZuppa/>,
  },
  {
    path: "/Contact",
    element: <ContatctUs/>,
  },
  {
    path: "/Profile",
    element: <Profile />,
  },
  {
    path: "/Privacy_policy",
    element: <Privacy_policy />,
  },

  {
    path: "/Cancellation",
    element: <Cancellation />,
  },

  {
    path: "/Navgati_autopilot",
    element: <Navgati_autopilot />,
  },
  {
    path: "/Zuppatrainer",
    element: <Zuppatrainer />,
  },
  {
    path: "/Zuppatrainerplus",
    element: <ZuppaTrainerPlus />,
  },
  {
    path: "/Zuppascout",
    element: <Zuppascout />,
  },

  {
    path: "/ReturnPolicy",
    element: <ReturnPolicy />,
  },
  {
    path: "/ShippingPolicy",
    element: <ShippingPolicy />,
  },

  {
    path: "/Termsandcondition",
    element: <Termsandcondition />,
  },



  {
    path: "/Drishya",
    element: <Drishya />,
  },

  {
    path: "/Edge",
    element: <Edge />,
  },

  {
    path: "/Eyeballs",
    element: <EyeBalls />,
  },


  {
    path: "/Defense",
    element: <Defense />,
  },







  {
    path: "/ProductSlider",
    element: <ProductSlider />,
  },
  {
    path: "/Armedforces",
    element: <ArmedForces />,
  },
  {
    path: "/Ajeetmini",
    element: <AjeetMini />,
  },
  {
    path: "/Partner",
    element: <Partner />,
  },

  {
    path: "/Login",
    element: <Login />,
  },
  {
    path: "/Register",
    element: <Register />,
  },
  {
    path: "/Forgotpass",
    element: <Forgotpassword />,
  },
  {
    path: "/resetpasword/:id/:token",
    element: <ResetPassword />,
  },

// ***************************** AllGategory *************************
{
  path: "/DroneShop",
  element: <Drones />,
},

{
  path: "/AutoPilotShop",
  element: <AutoPilot />,
},
{
  path: "/Software",
  element: <Software />,
},

{
  path: "/MRO",
  element: <MroLabs />,
},

{
  path: "/Innovation",
  element: <Innovation />,
},


















  // *******************  home page products   ******************************//
  {
    path: "/GpsTraker",
    element: <GpsTracker />,
  },
  {
    path: "/ColdChain",
    element: <ColdChain />,
  },
  {
    path: "/iiot",
    element: <IIOT />,
  },

  // *******************  BLOGS: News & Events...  ******************************//

  {
    path: "/Thechasm",
    element: <Thechasm />,
  },

  {
    path: "/Fascinatingjourney",
    element: <Fascinatingjourney />,
  },

  {
    path: "*",
    element: <Error />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <CartProvider>
        <Cart />
  
          <RouterProvider router={router} />
  
      </CartProvider>
    </UserProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
