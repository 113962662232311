import React from "react";
import { Fade } from "react-awesome-reveal";
import Drishya1 from "../assets/images/drishya1.png";
import Drishya2 from "../assets/images/drishya2.png";
import "./Drishya.css"; // Import custom CSS for styling
import Header from "../components/header"
import Footer from "../components/footer"


const Drishya = () => {
  return (
    <div>
      <Header/>
              <div className="about-header text-center" >
          <h3>DRISHYA</h3>
          <h4>MOTHERBOARD FOR UNMANNED SYSTEMS</h4>
        </div>
      <div className="container">


        <div className="row align-items-center newspaper-section">
          <div className="col-lg-6">
            <h4 className="droshyaheadingline">SEE THRU ARMOUR (STA)</h4>
            <h5 className="droshyaheadingline">Application Of DPCC Technology In Image Processing</h5>

            <p>
            🔸ZUPPA's core DPCC technology has been applied to image processing
              for this product.
            </p>
            <p>
            🔸Development of the STA in less than six months was entirely due to
              ZUPPA's End to End Control of its core DPCC technology and proven
              SOP for product development.
            </p>
            <p>
            🔸The application of the DPCC technology to process images from 6
              RGB and 6 Thermal cameras, identify and process only specific
              relevant images to the terminal / VR glass of two independent
              users is a unique feature of this product.
            </p>
          </div>
          <div className="col-lg-6 ">
            <Fade duration={2000} direction="left">
              <img src={Drishya1} alt="Image A"  />
            </Fade>
          </div>
        </div>

        <h1 className="drishyaheading">
        🔸 Technical Specification 🔸</h1>

        <div className="row newspaper-section">
          <div className="col-lg-6">
            <p>🔸 Diagonal Dimension: 252 x 252 x 140 (Height) mm</p>
            <p>🔸 All Up Flying Weight: 1300 gms</p>
            <p>🔸 Power System: Powerful 2000 watts (500 watts per motor)</p>
            <p>🔸 ABS Shell + Carbon Fiber Chassis = Strong Crash resistance</p>
            <p>🔸 Drone + Lower maintenance.</p>
            <p>🔸 Endurance: 5 Mins</p>
          </div>

          <div className="col-lg-6">
            <p>🔸 Maximum Operating Range: 0.5 Kms</p>
            <p>🔸 Controller Frequency: 2.4Ghz</p>
            <p>🔸 Video Frequency: 8 Ghz</p>
            <p>🔸 GPS and GLONASS</p>
            <p>🔸 Battery 6S 4200 Mah</p>
            <p>🔸 Positional Accuracy: Horizontal: +/- 2 m, Vertical: +/- 0.5 m</p>
          </div>
        </div>

        <div className="row align-items-center newspaper-section">
          <div className="col-lg-6">
            <Fade duration={2000} direction="right">
              <img src={Drishya2} alt="Image B" />
            </Fade>
          </div>

          <div className="col-lg-6">
            <h3 className="droshyaheadingline">ANTI Drone Visual 360 Radar</h3>
            <h6 className="droshyaheadingline">
              Visual Sighting remains the most effective and reliable system of
              identifying rogue Drones with identifying them being a challenge.
            </h6>
            <p>
             🔷 The Visual 360 Radar is yet another application of ZUPPA's
              proprietary DPCC technology to image processing.
            </p>
            <p>
            🔷The System is designed to be a Bank of Block Cameras positioned in
              such a way that the Field of vision of each camera overlaps with
              that of the camera's on either side of it, in this way the system
              acquires 360 degrees image acquisition capabilities.
            </p>
          </div>
        </div>

        <h1 className="mt-3 drishyaheading">
        🔷 Technical Specification 🔷</h1>

        <div className="row newspaper-section">
          <div className="col-lg-6">
            <p>🔷 Diagonal Dimension: 252 x 252 x 140 (Height) mm</p>
            <p>🔷 All Up Flying Weight: 1300 gms</p>
            <p>🔷 Power System: Powerful 2000 watts (500 watts per motor)</p>
            <p>🔷 ABS Shell + Carbon Fiber Chassis = Strong Crash resistance</p>
            <p>🔷 Drone + Lower maintenance.</p>
            <p>🔷 Endurance: 5 Mins</p>
          </div>

          <div className="col-lg-6">
            <p>🔷 Maximum Operating Range: 0.5 Kms</p>
            <p>🔷 Controller Frequency: 2.4Ghz</p>
            <p>🔷 Video Frequency: 8 Ghz</p>
            <p>🔷GPS and GLONASS</p>
            <p>🔷 Battery 6S 4200 Mah</p>
            <p>🔷 Positional Accuracy: Horizontal: +/- 2 m, Vertical: +/- 0.5 m</p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Drishya;
