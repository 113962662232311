import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./Edge.css";
import GPStracker from "../assets/images/GPS_Tracker.png";
import IOT from "../assets/images/iiot.jpg";
import tracker from "../assets/images/tracker.jpg";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

export default function Edge() {
  const navigate=useNavigate()

 
  const [isZoomed1, setIsZoomed1] = useState(false);

  const handleMouseEnter1 = () => {
    setIsZoomed1(true);
  };

  const handleMouseLeave1 = () => {
    setIsZoomed1(false);
  };

const [isZoomed2, setIsZoomed2] = useState(false);

  const handleMouseEnter2 = () => {
    setIsZoomed2(true);
  };

  const handleMouseLeave2 = () => {
    setIsZoomed2(false);
  };

 const [isZoomed3, setIsZoomed3] = useState(false);

  const handleMouseEnter3 = () => {
    setIsZoomed3(true);
  };

  const handleMouseLeave3 = () => {
    setIsZoomed3(false);
  };


  const trakerGPS =()=>{
    navigate('/GpsTraker')
  }
  const ColdChain =()=>{
    navigate('/ColdChain')
  }
  const iiot =()=>{
    navigate('/iiot')
  }

  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);


  return (
    <>
      <Header />

      <div className="container">
        <div className="about-header text-center">
          <h4>EDGE</h4>
          <h4>MOTHERBOARD FOR UNMANNED SYSTEMS</h4>
        </div>
      </div>
     
  
        <div className="row justify-content-center">
          <div className="col-md-5">
           <div
                className={`image-box border d-flex flex-column align-items-center ${
                  isZoomed1 ? "zoomed" : ""
                }`}
                onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}
                style={{
                  marginTop: "50px",
                  backgroundColor: "#EEB238FF",

                  color: "black",
                }}
              >
                <h3 className="tracker-heading">GPS Tracker</h3>
                <br></br>
                <br></br>

                <img
                  src={tracker}
                  alt="Image"
                  style={{ maxWidth: "60%", maxHeight: "70%" }}
                />

                <br></br>
                <br></br>

                <p className="tracker-paratag text-center">
                  Wireless Communication Frequency Identification & Management
                </p>
             <Button variant="contained" color="success" onClick={trakerGPS}>view</Button>
             <br/>
              </div>
          
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-5">
          <div
                className={`image-box border d-flex flex-column align-items-center ${
                  isZoomed2 ? "zoomed" : ""
                }`}
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
                style={{
                  marginTop: "170px",
                  backgroundColor: "#EEB238FF",
                  color: "Black",
                }}
              >
                <h3 className="tracker-heading">Cold Chain GPS</h3>
                <br></br>
                <br></br>

                <img  src={GPStracker} alt="Image"
                     style={{ maxWidth: "60%", maxHeight: "70%",backgroundColor:"black" }}
               />
                <br></br>
                <br></br>

                <p className="tracker-paratag text-center">
                <b> Atmanirbhar</b> Silicon Chip is a key requirement for 5th
                  Generation Technologies
                </p>
                <Button variant="contained" color="success" onClick={ColdChain}>view</Button>
            <br/>
              </div>
            
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div
              className={`image-box border d-flex flex-column align-items-center ${
                isZoomed3 ? "zoomed" : ""
              }`}
              onMouseEnter={handleMouseEnter3}
              onMouseLeave={handleMouseLeave3}
              style={{ marginTop: "170px", backgroundColor: "#EEB238FF" }}
            >
              <h3 className="tracker-heading">IIOT</h3>
              <br></br>
              <br></br>

              <img
                src={IOT}
                alt="Image"
                style={{ maxWidth: "60%", maxHeight: "70%" }}
                
              />

              <br></br>
              <br></br>

              <p className="tracker-paratag text-center">
                Internet Of Military Things IoMT
              </p>
              <Button variant="contained" color="success" onClick={iiot}>view</Button>
           <br/>
            </div>
           
          </div>
         
        </div>
    
      <br></br>
    
      <Footer />
    </>
  );
}
