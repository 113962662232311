// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container {
  padding: 20px;
}




.Industry-Growth {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  animation: fadeIn 2s ease-in-out;
}
.growthImage{
  margin-top: -6%;
  height: 450px;
  width: 650px;
}



@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


@media (max-width: 991px) {
  h3 {
    font-size: 1.5rem;
    text-align: center; 
  }
  .growthImage{
    margin-top: -6%;
    height: 380px;
 
  }
  
}

@media (max-width: 767px) {
  h3 {
    font-size: 1.2rem;
  }
  .growthImage{
    margin-top: -6%;
    height: 250px;

  }
  
}
`, "",{"version":3,"sources":["webpack://./src/components/Industry/Industry.css"],"names":[],"mappings":";AACA;EACE,aAAa;AACf;;;;;AAKA;EACE,eAAe;EACf,cAAc;EACd,cAAc;EACd,gCAAgC;AAClC;AACA;EACE,eAAe;EACf,aAAa;EACb,YAAY;AACd;;;;AAIA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;;AAGA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,UAAU;IACV,mBAAmB;EACrB;AACF;;;AAGA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,aAAa;;EAEf;;AAEF;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,aAAa;;EAEf;;AAEF","sourcesContent":["\r\n.container {\r\n  padding: 20px;\r\n}\r\n\r\n\r\n\r\n\r\n.Industry-Growth {\r\n  max-width: 100%;\r\n  display: block;\r\n  margin: 0 auto;\r\n  animation: fadeIn 2s ease-in-out;\r\n}\r\n.growthImage{\r\n  margin-top: -6%;\r\n  height: 450px;\r\n  width: 650px;\r\n}\r\n\r\n\r\n\r\n@keyframes slideIn {\r\n  from {\r\n    opacity: 0;\r\n    transform: translateX(-50px);\r\n  }\r\n  to {\r\n    opacity: 1;\r\n    transform: translateX(0);\r\n  }\r\n}\r\n\r\n\r\n@keyframes fadeIn {\r\n  from {\r\n    opacity: 0;\r\n    transform: scale(0.8);\r\n  }\r\n  to {\r\n    opacity: 1;\r\n    transform: scale(1);\r\n  }\r\n}\r\n\r\n\r\n@media (max-width: 991px) {\r\n  h3 {\r\n    font-size: 1.5rem;\r\n    text-align: center; \r\n  }\r\n  .growthImage{\r\n    margin-top: -6%;\r\n    height: 380px;\r\n \r\n  }\r\n  \r\n}\r\n\r\n@media (max-width: 767px) {\r\n  h3 {\r\n    font-size: 1.2rem;\r\n  }\r\n  .growthImage{\r\n    margin-top: -6%;\r\n    height: 250px;\r\n\r\n  }\r\n  \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
