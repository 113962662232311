// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.innovation-lab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .responsive-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .back-button {
    margin-top: 10px;
    align-self: center; /* This centers the button horizontally */
  }
  
  @media (max-width: 768px) {
    .innovation-lab-container {
      padding: 10px;
    }
  
    .back-button {
      width: 100%;
      max-width: 300px;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .back-button {
      font-size: 0.9rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/InnovationLab/InnovationLab.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB,EAAE,yCAAyC;EAC/D;;EAEA;IACE;MACE,aAAa;IACf;;IAEA;MACE,WAAW;MACX,gBAAgB;MAChB,eAAe;IACjB;EACF;;EAEA;IACE;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".innovation-lab-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .responsive-image {\r\n    max-width: 100%;\r\n    height: auto;\r\n    margin-bottom: 20px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 8px;\r\n  }\r\n  \r\n  .back-button {\r\n    margin-top: 10px;\r\n    align-self: center; /* This centers the button horizontally */\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .innovation-lab-container {\r\n      padding: 10px;\r\n    }\r\n  \r\n    .back-button {\r\n      width: 100%;\r\n      max-width: 300px;\r\n      font-size: 1rem;\r\n    }\r\n  }\r\n  \r\n  @media (max-width: 480px) {\r\n    .back-button {\r\n      font-size: 0.9rem;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
