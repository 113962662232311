// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#cart{
    margin: 89px;
    height: 550px;
    margin-left:-12px;
    }
    .cart_head{
        color: rgb(7, 3, 53);
        font-family: 'Ubuntu',sans-serif;
        letter-spacing: 0.2rem;
        background-color: #ff9500e0;
    }
    
.cart_foot{
    background-color: #ff9500e0;
}`, "",{"version":3,"sources":["webpack://./src/components/Cart.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB;IACA;QACI,oBAAoB;QACpB,gCAAgC;QAChC,sBAAsB;QACtB,2BAA2B;IAC/B;;AAEJ;IACI,2BAA2B;AAC/B","sourcesContent":["\r\n#cart{\r\n    margin: 89px;\r\n    height: 550px;\r\n    margin-left:-12px;\r\n    }\r\n    .cart_head{\r\n        color: rgb(7, 3, 53);\r\n        font-family: 'Ubuntu',sans-serif;\r\n        letter-spacing: 0.2rem;\r\n        background-color: #ff9500e0;\r\n    }\r\n    \r\n.cart_foot{\r\n    background-color: #ff9500e0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
