import React, { useState, useContext } from "react";
import "../pages/auth/Login.css";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../components/header";



const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const postLoginMethod = () => {
    
    const newLoginData = {
      email: email,
    };

    fetch("http://localhost:4000/forgotpassword", {
      method: "POST",
      body: JSON.stringify(newLoginData),
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => data.json())
      .then((f) =>alert(f.message));
      navigate("/Login")
  };



  return (
    <div>
    <Header/>
      <div className="login-container">
        <div className="wrapper">
          <div action="" className="loginpara">
            <h1>Forgot Password ?</h1>

            <div className="input-box">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter Your Email Id"
                required=""
              />

              <EmailIcon className="icon" />
            </div>

            <button type="submit" onClick={postLoginMethod}>
              Send
            </button>

            <div className="register-link">
              <p style={{ color: "black", fontWeight: "800" }}>
                Check Your Email{" "}          <EmailIcon style={{color:"blue"}}/>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;