import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dialog.css";
import Ship from "../assets/PopUp/shipImge1.gif";
import { Fade, Zoom } from "react-awesome-reveal";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Dialog = ({ closePopup }) => {
  const dialogRef = useRef(null);
  const [showContent, setShowContent] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const [showGIFs, setShowGIFs] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }

    const gifsTimeout = setTimeout(() => {
      setShowGIFs(false);
      setShowContent(true);
    }, 1500);

    const backgroundTimeout = setTimeout(() => {
      setShowBackground(true);
    }, 1600);

    return () => {
      clearTimeout(gifsTimeout);
      clearTimeout(backgroundTimeout);
    };
  }, []);

  const contactPage = () => {
    navigate("/Contact");
  };

  const shopPage = () => {
    navigate("/ShopNow");
  };

  return (
    <div className="container mt-5">
      <dialog
        ref={dialogRef}
        className={`dialog ${showBackground ? "flag-background" : ""}`}
      >
        {showGIFs && (
          <div className="dialog-gif-sequence">
            <img src={Ship} alt="Ship GIF" className="dialog-gif ship-gif" />
            <img
              src="https://ugokawaii.com/wp-content/uploads/2022/06/cross.gif"
              alt="Cancel GIF"
              className="dialog-gif cancel-gif"
              style={{ height: "80px", width: "90px" }}
            />
          </div>
        )}

        {showContent && (
          <Fade direction="down">
            <div>
              <div className="d-flex justify-content-between mb-3">
                <button
                  onClick={closePopup}
                  className="btn btn-danger"
                  style={{
                    position: 'absolute',
                    top: '-15px',
                    right: '-15px',
                  }} >
                  <CloseIcon />
                </button>
              </div>
              <Zoom>
                <div className="dialog-content">
                  <div className="row">
                    <h3 className="text-center headingTag mt-3">
                      Independence From Imports
                    </h3>
                    <h5 className="bharat-drone text-center">Bharat Drone</h5>
                    <div className="col-lg-6 text-center">
                      <img
                        src="https://dcassetcdn.com/design_img/3300826/467059/467059_18103171_3300826_cc76b4bf_image.jpg"
                        style={{ height: "60%" }}
                      />
                    </div>

                    <div className="col-lg-6 text-center">
                      <img
                        src="https://media.tenor.com/_yndIq235YwAAAAj/terrabyt-terrabyt-offer.gif"
                        style={{ height: "60%" }}
                      />
                    </div>
                    <div className="col-lg-6 text-center" >
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={contactPage}
                        fullWidth
                      >
                        Contact
                      </Button>
                    </div>
                    <div className="col-lg-6 text-center">
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={shopPage}
                        className="shop-now-button"
                        fullWidth
                      >
                        Shop Now
                      </Button>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </Fade>
        )}
      </dialog>
    </div>
  );
};

export default Dialog;
