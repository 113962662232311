// import * as React from "react";
// import { useContext } from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";
// import MenuItem from "@mui/material/MenuItem";
// import { useNavigate } from "react-router-dom";
// import PersonIcon from "@mui/icons-material/Person";
// import cartContext from "../context/cartContext";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import Badge from "@mui/material/Badge";
// import headerlogo from "../assets/images/headerzuppa.gif";
// import userContext from "../context/userContext";


// const pages = [
//   {
//     name: "Home",
//     nav: "/",
    
//   },
//   // {
//   //   name: "About",
//   //   nav: "/About",
   
//   // },
//   {
//     name: "Technology",
//     nav: "/Technology",
//   },
//   {
//     name: "Accolades",
//     nav: "/NewsAndEvents",
//   },
//   // {
//   //   name: "Career",
//   //   nav: "/Career",
//   // },

//    {
//     name: "Shop",
//     nav: "/ShopNow",
//   },
//   {
//     name: "Contact",
//     nav: "/Contact",
//   },
//   // {
//   //   name: "Download",
//   //   nav: "/Download",
//   // },
// ];

// const settings = [
//   {
//     name: "Profile",
//     nav: "/Profile",
//   },
// ];

// function ResponsiveAppBar() {

//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [anchorElNav, setAnchorElNav] = React.useState(null);
//   const [anchorElUser, setAnchorElUser] = React.useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };
//   const { cartItems, toggleCart } = React.useContext(cartContext);
//   const cartQuantity = cartItems.length;
//   const navigate = useNavigate();

//   const authToken = localStorage.getItem("zuppa");
//   const { user } = useContext(userContext);



//   const logOut = () => {
//     localStorage.removeItem("zuppa");
//     localStorage.removeItem("username");
//     localStorage.removeItem("id");
//     window.location.href = "/Login";
//   };

//   const logIn = () => {
//     navigate("/Login");
//   };


 


//   return (
// <AppBar position="fixed" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
//   <Container>
//     <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//       <div style={{marginLeft:"-40px"}}>
//         <img src={headerlogo} alt="Zuppa" style={{ width: "100px" }} />
//       </div>

//       <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, justifyContent: 'flex-end' }}>
//         <IconButton
//           size="large"
//           aria-label="menu"
//           aria-controls="menu-appbar"
//           aria-haspopup="true"
//           onClick={handleOpenNavMenu}
//         >
//           {cartQuantity > 0 ? (
//             <Badge color="error" variant="dot">
//               <MenuIcon />
//             </Badge>
//           ) : (
//             <MenuIcon color="warning" />
//           )}
//         </IconButton>

//         <Menu
//           id="menu-appbar"
//           anchorEl={anchorElNav}
//           anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//           keepMounted
//           transformOrigin={{ vertical: "top", horizontal: "left" }}
//           open={Boolean(anchorElNav)}
//           onClose={handleCloseNavMenu}
//           sx={{ display: { xs: "block", md: "none" } }}
//         >
//           {pages.map((page, index) => (
//             <MenuItem
//               key={index}
//               onClick={() => { handleCloseNavMenu(); navigate(page.nav); }}
//             >
//               <Typography textAlign="center">{page.name}</Typography>
//             </MenuItem>
//           ))}

//           <MenuItem>
//             <IconButton aria-label="cart">
//               {cartQuantity > 0 ? (
//                 <Badge badgeContent={cartQuantity} color="error">
//                   <ShoppingCartIcon
//                     className="shopping"
//                     onClick={() => toggleCart(true)}
//                     fontSize="large"
//                     style={{ color: "green" }}
//                   />
//                 </Badge>
//               ) : (
//                 <ShoppingCartIcon
//                   onClick={() => toggleCart(true)}
//                   fontSize="large"
//                   style={{ color: "#357a38" }}
//                 />
//               )}
//             </IconButton>
//           </MenuItem>
//         </Menu>
//       </Box>

//       <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, gap: "30px",  }}>
//         {pages.map((page, index) => (
//           <Button
//             style={{ textAlign: "center", color: "white", fontWeight: "900" }}
//             key={index}
//             onClick={() => { handleCloseNavMenu(); navigate(page.nav); }}
//             sx={{ my: 2, display: "block" }}
//           >
//             {page.name}
//           </Button>
//         ))}
//         <ul style={{ marginLeft: "-40px", marginTop: "20px" }}>
//           <li>
//             <div title="Cart" className="cart_icon" onClick={() => toggleCart(true)}>
//               <IconButton>
//                 {cartQuantity > 0 ? (
//                   <Badge badgeContent={cartQuantity} color="error">
//                     <ShoppingCartIcon
//                       fontSize="large"
//                       style={{ color: "#357a38", marginLeft: "-5px" }}
//                     />
//                   </Badge>
//                 ) : (
//                   <ShoppingCartIcon
//                     fontSize="large"
//                     style={{ color: "#357a38", marginLeft: "-5px" }}
//                   />
//                 )}
//               </IconButton>
//             </div>
//           </li>
//         </ul>
//       </Box>

//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         {/* <PersonIcon fontSize="large" style={{ color: "white" }} /> */}

//         <Menu
//           sx={{ mt: "45px" }}
//           id="menu-appbar"
//           anchorEl={anchorElUser}
//           anchorOrigin={{ vertical: "top", horizontal: "right" }}
//           keepMounted
//           transformOrigin={{ vertical: "top", horizontal: "right" }}
//           open={Boolean(anchorElUser)}
//           onClose={handleCloseUserMenu}
//         >
//           {settings.map((setting, index) => (
//             <MenuItem
//               key={index}
//               onClick={() => { handleCloseUserMenu(); navigate(setting.nav); }}
//             >
//               <Typography textAlign="center">{setting.name}</Typography>
//             </MenuItem>
//           ))}
//         </Menu>

//         {authToken && (
//           <p style={{ fontSize: "large", fontFamily: "Helvetica", color: "white", padding: "20px 20px 0 15px" }}>
//             {localStorage.getItem("username")}
//           </p>
//         )}

//         <Box sx={{ flexGrow: 1, textAlign: 'right',marginLeft:"30px" }}>
//           {authToken ? (
//             <Button variant="outlined" color="error" onClick={logOut}>
//               LogOut
//             </Button>
//           ) : (
//             <Button variant="contained" onClick={logIn}>
//               Login
//             </Button>
//           )}
//         </Box>
//       </Box>
//     </Toolbar>
//   </Container>
// </AppBar>

//   );
// }
// export default ResponsiveAppBar;










// import * as React from "react";
// import { useContext } from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";
// import MenuItem from "@mui/material/MenuItem";
// import { useNavigate } from "react-router-dom";
// import cartContext from "../context/cartContext";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import Badge from "@mui/material/Badge";
// import headerlogo from "../assets/images/headerzuppa.gif";
// import userContext from "../context/userContext";


// const pages = [
//   {
//     name: "Home",
//     nav: "/",
    
//   },
//   // {
//   //   name: "About",
//   //   nav: "/About",
   
//   // },
//   {
//     name: "Technology",
//     nav: "/Technology",
//   },
//   {
//     name: "Accolades",
//     nav: "/NewsAndEvents",
//   },
//   // {
//   //   name: "Career",
//   //   nav: "/Career",
//   // },

//    {
//     name: "Shop",
//     nav: "/ShopNow",
//   },
//   {
//     name: "Contact",
//     nav: "/Contact",
//   },
//   // {
//   //   name: "Download",
//   //   nav: "/Download",
//   // },
// ];

// const settings = [
//   {
//     name: "Profile",
//     nav: "/Profile",
//   },
// ];

// function ResponsiveAppBar() {

//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [anchorElNav, setAnchorElNav] = React.useState(null);
//   const [anchorElUser, setAnchorElUser] = React.useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };
//   const { cartItems, toggleCart } = React.useContext(cartContext);
//   const cartQuantity = cartItems.length;
//   const navigate = useNavigate();

//   const authToken = localStorage.getItem("zuppa");
//   const { user } = useContext(userContext);



//   const logOut = () => {
//     localStorage.removeItem("zuppa");
//     localStorage.removeItem("username");
//     localStorage.removeItem("id");
//     window.location.href = "/Login";
//   };

//   const logIn = () => {
//     navigate("/Login");
//   };


 


//   return (
// <AppBar position="fixed" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
//   <Container>
//     <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//       <div  style={{marginLeft:"-50px"}}>
//         <img src={headerlogo} alt="Zuppa" style={{ width: "100px" }} />
//       </div>

//       <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, justifyContent: 'flex-end' }}>
//         <IconButton
//           size="large"
//           aria-label="menu"
//           aria-controls="menu-appbar"
//           aria-haspopup="true"
//           onClick={handleOpenNavMenu}
//         >
//           {cartQuantity > 0 ? (
//             <Badge color="error" variant="dot">
//               <MenuIcon />
//             </Badge>
//           ) : (
//             <MenuIcon color="warning" />
//           )}
//         </IconButton>

//         <Menu
//           id="menu-appbar"
//           anchorEl={anchorElNav}
//           anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//           keepMounted
//           transformOrigin={{ vertical: "top", horizontal: "left" }}
//           open={Boolean(anchorElNav)}
//           onClose={handleCloseNavMenu}
//           sx={{ display: { xs: "block", md: "none" } }}
//         >
//           {pages.map((page, index) => (
//             <MenuItem
//               key={index}
//               onClick={() => { handleCloseNavMenu(); navigate(page.nav); }}
//             >
//               <Typography textAlign="center">{page.name}</Typography>
//             </MenuItem>
//           ))}

//           <MenuItem>
//             <IconButton aria-label="cart">
//               {cartQuantity > 0 ? (
//                 <Badge badgeContent={cartQuantity} color="error">
//                   <ShoppingCartIcon
//                     className="shopping"
//                     onClick={() => toggleCart(true)}
//                     fontSize="large"
//                     style={{ color: "green" }}
//                   />
//                 </Badge>
//               ) : (
//                 <ShoppingCartIcon
//                   onClick={() => toggleCart(true)}
//                   fontSize="large"
//                   style={{ color: "#357a38" }}
//                 />
//               )}
//             </IconButton>
//           </MenuItem>
//         </Menu>
//       </Box>

//       <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, gap: "30px",  }}>
//         {pages.map((page, index) => (
//           <Button
//             style={{ textAlign: "center", color: "white", fontWeight: "900" }}
//             key={index}
//             onClick={() => { handleCloseNavMenu(); navigate(page.nav); }}
//             sx={{ my: 2, display: "block" }}
//           >
//             {page.name}
//           </Button>
//         ))}
//         <ul style={{ marginLeft: "-40px", marginTop: "20px" }}>
//           <li>
//             <div title="Cart" className="cart_icon" onClick={() => toggleCart(true)}>
//               <IconButton>
//                 {cartQuantity > 0 ? (
//                   <Badge badgeContent={cartQuantity} color="error">
//                     <ShoppingCartIcon
//                       fontSize="large"
//                       style={{ color: "#357a38", marginLeft: "-5px" }}
//                     />
//                   </Badge>
//                 ) : (
//                   <ShoppingCartIcon
//                     fontSize="large"
//                     style={{ color: "#357a38", marginLeft: "-5px" }}
//                   />
//                 )}
//               </IconButton>
//             </div>
//           </li>
//         </ul>
//       </Box>

//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
    
//         <Menu
//           sx={{ mt: "45px" }}
//           id="menu-appbar"
//           anchorEl={anchorElUser}
//           anchorOrigin={{ vertical: "top", horizontal: "right" }}
//           keepMounted
//           transformOrigin={{ vertical: "top", horizontal: "right" }}
//           open={Boolean(anchorElUser)}
//           onClose={handleCloseUserMenu}
//         >
//           {settings.map((setting, index) => (
//             <MenuItem
//               key={index}
//               onClick={() => { handleCloseUserMenu(); navigate(setting.nav); }}
//             >
//               <Typography textAlign="center">{setting.name}</Typography>
           
//             </MenuItem>
          
//           ))}
//         </Menu>

//         {authToken && (
//           <p style={{ fontSize: "large", fontFamily: "Helvetica", color: "white", padding: "20px 20px 0 15px" }}>
//             {localStorage.getItem("username")}
//           </p>
//         )}

//         <Box sx={{ flexGrow: 1, textAlign: 'right',marginLeft:"30px" }}>
//           {authToken ? (
//             <Button variant="outlined" color="error" onClick={logOut} >
//               LogOut
//             </Button>
//           ) : (
//             <Button variant="contained" onClick={logIn} >
//               Login
//             </Button>
//           )}
//         </Box>
//       </Box>
//     </Toolbar>
//   </Container>
// </AppBar>

//   );
// }
// export default ResponsiveAppBar;





import * as React from "react";
import { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import cartContext from "../context/cartContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import headerlogo from "../assets/images/headerzuppa.gif";
import userContext from "../context/userContext";

const pages = [
  { name: "Home", nav: "/" },
  { name: "Technology", nav: "/Technology" },
  { name: "Accolades", nav: "/NewsAndEvents" },
  { name: "Shop", nav: "/ShopNow" },
  { name: "Contact", nav: "/Contact" },
  { name: "DroneLabs", nav: "/dronelabs" },
];

const settings = [{ name: "Profile", nav: "/Profile" }];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { cartItems, toggleCart } = React.useContext(cartContext);
  const cartQuantity = cartItems.length;
  const navigate = useNavigate();
  const authToken = localStorage.getItem("zuppa");
  const { user } = useContext(userContext);

  const logOut = () => {
    localStorage.removeItem("zuppa");
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    window.location.href = "/Login";
  };

  const logIn = () => {
    navigate("/Login");
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
      <Container maxWidth="lg">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setAnchorElNav(true)}>
            <img src={headerlogo} alt="Zuppa" style={{ width: "100px" }} />
          </IconButton>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorElNav(event.currentTarget)}
            >
              {cartQuantity > 0 ? (
                <Badge color="error" variant="dot">
                  <MenuIcon />
                </Badge>
              ) : (
                <MenuIcon color="warning" />
              )}
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={() => { setAnchorElNav(null); navigate(page.nav); }}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem>
                <IconButton aria-label="cart" 
				// onClick={() => toggleCart(true)}
				onClick={() => navigate("/Contact")}
				>
                  {cartQuantity > 0 ? (
                    <Badge badgeContent={cartQuantity} color="error">
                      <ShoppingCartIcon fontSize="large" style={{ color: "#357a38" }} />
                    </Badge>
                  ) : (
                    <ShoppingCartIcon fontSize="large" style={{ color: "#357a38" }} />
                  )}
                </IconButton>
              </MenuItem>
            </Menu>
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: 'center', gap: "30px" }}>
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={() => navigate(page.nav)}
                sx={{ color: 'white', fontWeight: 'bold' }}
              >
                {page.name}
              </Button>
            ))}
            <IconButton
			// onClick={() => toggleCart(true)}
			onClick={() => navigate("/Contact")}
			>
              {cartQuantity > 0 ? (
                <Badge badgeContent={cartQuantity} color="error">
                  <ShoppingCartIcon fontSize="large" style={{ color: "#357a38" }} />
                </Badge>
              ) : (
                <ShoppingCartIcon fontSize="large" style={{ color: "#357a38" }} />
              )}
            </IconButton>
          </Box>

          {/* User Menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              {settings.map((setting, index) => (
                <MenuItem key={index} onClick={() => { setAnchorElUser(null); navigate(setting.nav); }}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>

            {authToken ? (
              <Typography variant="body1" sx={{ color: 'white', mx: 2 }}>
                {localStorage.getItem("username")}
              </Typography>
            ) : null}

            <Button
              variant={authToken ? "outlined" : "contained"}
              color={authToken ? "error" : "primary"}
              onClick={authToken ? logOut : logIn}
            >
              {authToken ? "LogOut" : "Login"}
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
