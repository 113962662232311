import React from 'react'
import { useNavigate } from 'react-router-dom';

const Error = () => {
    const navigate=useNavigate();
  const registerHandle = () => {
    navigate("/");
  }
  return (
  
    <div style={{ minHeight: "85vh", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
      <img src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" alt="error" style={{ width: "40%" }} />
      {/* <h1 className="mb-3">404 ERROR </h1> */}
      <h3> 404 PAGE NOT FOUND</h3>
      <a href="# " onClick={registerHandle}>
                <h5  className="btn btn-primary" style={{ fontSize: 18 }} > Back To Home Page</h5>
                </a>
     
    </div>

  )
}

export default Error