import React, { useEffect } from "react";
import Header from "../header";
import gpstraking from "../../assets/images/gpsTracking.png";
import iiotpic from "../../assets/images/iiotlap.png";
import tracker from "../../assets/images/tracker.jpg";
import "./GpsTracker.css";
import { Fade } from "react-awesome-reveal";
import Footer from "../footer";

const GpsTracker = () => {
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header />
      <div className="container">
        <div className="about-header text-center">
          <h4>GPS TRACKER</h4>
          <h5>Wireless Communication Frequency Identification & Management</h5>
        </div>

        <div className="row" >
          <div className="col-md-6">
            <img
              className="gpsTrack-content"
              src={iiotpic}
              alt=""
              style={{ height: "300px", width: "350px" }}
            />
          </div>
          <div className="col-md-6 gpstracker mt-4 ">
            <Fade direction="left">
              {" "}
              <h3>GPS TRACKER</h3>
            </Fade>
            <br />
            <p> AIS 140V Comppant Hardware and Communincation protocols</p>
            <p>Multiple Server Connection upto 6 Server</p>
            <p>AIS Standard Comppant Data Packet Configuration</p>
            <p>Multiple Emergercy Button Integration</p>
            <p>OTA Configuration Using Server and SMS</p>
            <p>Future Regulation and Customer Requirement Configurable</p>
          </div>
        </div>
        <br />
        <div className="row mt-4">
          <div className="col-md-6 gpstracker">
            <Fade direction="right">
              <h3>SPECIFICATION</h3>
            </Fade>
            <br />
            <p>Input Voltage Source: 12V/24V DC</p>
            <p> Internal Battery: +3V 1050mah pthium Ion Battery</p>
            <p>Casing: IP-65 Rugged Casing , Weather and water proof</p>
            <p>Processor: Automative Grade 16-Bit</p>
            <p> Flash Logging Capabipty: AIS140 40,000logs</p>
            <p>GPS positional accuracy: 1.5 to 2.5m</p>
            <p>Temperature Range: -40deg - 85deg Celcius</p>
          </div>
          <div className="col-md-6">
            <img
              className="gpsTrack-content img-fluid"
              src={tracker}
              alt=""
              style={{ height: "350px", width: "450px" }}
            />
          </div>
        </div>
        <br />
      </div>

      <br />
    
      <Footer/>
    </div>
  );
};

export default GpsTracker;
