import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import mouseHover from "../assets/Autopilot/mousehover.gif";
import imageOne from "../assets/Autopilot/picture1.png";
import imageTwo from "../assets/Autopilot/Autopilot2.png";
import { Zoom, Slide, JackInTheBox } from "react-awesome-reveal";
import "./Navgati_autopilot.css";
import { useNavigate } from "react-router-dom";



const Navgati_autopilot = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
    setShowButton(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  useEffect(() => {
    if (!isHovered) {
      setShowButton(true);
    }
  }, [isHovered]);




  const AutopilotBrochure = () => {
    // You can use a direct link to the PDF file or dynamically create one
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/Zuppa Autopilot.pdf`; // Update with your actual PDF path
    link.download = 'Autopilot Brochure.pdf'; // The name of the file that will be downloaded
    link.click();
  };
  return (
    <>
    <div>
      <Header />
      <div className="container" style={{ marginTop: "100px" }}>
        <h1 className="text-center about-header">Navgati Autopilot</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            {!isHovered ? (
              <>
                <Zoom>
                  <h4 className="section-heading">Features</h4>
                </Zoom>
                <Slide duration={1200}>
                  <p className="feature-text">Fully Indian Patented Technology</p>
                </Slide>
                <Slide duration={1400}>
                  <p className="feature-text">Designed & Made In India</p>
                </Slide>
                <Slide duration={1600}>
                  <p className="feature-text"> End 2 End Personalised Tech Support </p>
                </Slide>
                <Slide duration={1800}>
                  <p className="feature-text">Ready Documentation for DGCA</p>
                </Slide>
              </>
            ) : (
              <>
                <Zoom>
                  <h4 className="section-heading">Firmware</h4>
                </Zoom>
                <JackInTheBox>
                  <p className="feature-text">Proven Anti Hack Firmware</p>
                </JackInTheBox>
                <JackInTheBox>
                  <p className="feature-text">Consistent performance</p>
                </JackInTheBox>
                <JackInTheBox>
                  <p className="feature-text">SDK for APP Development</p>
                </JackInTheBox>
              </>
            )}
          </div>
          <div
            className="col-lg-4 col-md-12 text-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={isHovered ? imageTwo : imageOne}
              className="main-image"
              alt="Autopilot"
            />
            {showButton && (
              <>
              <div className="button-box">
                <button className="custom-button">
                  <p className="title">Click Here</p>
                  <img src={mouseHover} alt="Handshake" />
                </button>
              </div>
              <br/>
              </>
            )}
          </div>
          <div className="col-lg-4 col-md-6">
            {!isHovered ? (
              <>
                <Zoom>
                  <h4 className="section-heading">Ease of Usage</h4>
                </Zoom>
                <Slide duration={1200} direction="right">
                  <p className="usage-text">
                    Easily expandable with external hardware
                  </p>
                </Slide>
                <Slide duration={1400} direction="right">
                  <p className="usage-text">Plug & Fly with minimal tuning</p>
                </Slide>
                <Slide duration={1600} direction="right">
                  <p className="usage-text">
                    Supports both Mavlink & ZUPPA protocols
                  </p>
                </Slide>
                <Slide duration={1800} direction="right">
                  <p className="usage-text">Multiple GCS compatible</p>
                </Slide>
              </>
            ) : (
              <>
                <Zoom>
                  <h4 className="section-heading">Hardware</h4>
                </Zoom>
                <JackInTheBox>
                  <p className="usage-text">
                    Tested and Proven Firmware ensures consistent drone
                    performance
                  </p>
                </JackInTheBox>
                <JackInTheBox>
                  <p className="usage-text">
                    Hardware Tested and proven across India from sea level to
                    over 15,000 ft
                  </p>
                </JackInTheBox>
                <JackInTheBox>
                  <p className="usage-text">SDK for Developers to build applications</p>
                </JackInTheBox>
              </>
            )}
          </div>
        </div>
          </div>
      <br />
      <br />
      <button className="autopilot-brochure" onClick={AutopilotBrochure}>
      Autopilot Brochure
    </button>

      <br />
      <br />
      <br />
      <br />
      <br />

      
      <div className="text-center">
        <video autoPlay loop muted style={{minWidth:"100%"}}>
          <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355951/istn0nqu77jeojodko17.mp4" type="video/mp4" />
        </video>
      </div>



      <br />
           <div className="button-box" onClick={()=> navigate("/AutoPilotShop")}>
                <button className="buy-button">
                  <p className="buy-title text-center">Buy</p>
                  <img src={mouseHover} alt="Handshake" />
                </button>
              </div>

              <br />
              <br />
      <Footer />
    </div>

    </>
  );
};

export default Navgati_autopilot;
