import React from "react";
import "./SensorManagements.css";
import DroneGif from "../../assets/drone.mp4";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header"
import Footer from "../footer";

function SensorManagements() {
  
  const navigate = useNavigate()


  const homePage = ()=>{
    navigate("/")
  }
    return (
      <>

      <div className=" text-center" style={{marginBottom:"20px"}}>
          <h3>SENSOR MANAGEMENT</h3>
        </div>

        <div className="drone-content">
          <div className="drone-left">
            <div className="drone-item">
              <div className="icon powersystem-icon"></div>
              <h4>Power System</h4>
              <h6>EMF Generated</h6>
              <h6>by High Current</h6>
              <h6>Flow Directly</h6>
              <h6>Impacts sensors</h6>
            </div>
          </div>
          <div className="drone-center">
            <video className="drone-video" autoPlay muted loop>
              <source src={DroneGif} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="drone-right">
            <div className="drone-item">
              <div className="icon autopilot-icon"></div>
              <h4>Autopilot</h4>
              <h6>Inputs from sensors like</h6>
              <h6>Barometer, Magnetometer,</h6>
              <h6>GPS, Accelerometers, IMU</h6>
              <h6>Gyro are the sensor</h6>
              <h6>Organs of the Autopilot</h6>
            </div>
          </div>
        </div>

        {/* Content 2 Section */}
        <div className="content2">
        
          <p style={{fontWeight:"bold"}}>Critical Conflict To Be Understood and Managed In Drones at every stage from </p>
          <p style={{fontWeight:"bold"}}>Product Development, Manufacturing, Maintenance, Repairs & Operations </p>
        </div>


      
        </>
      );
}

export default SensorManagements;
