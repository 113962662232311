import React from "react";
import "./Partner.css";
import Universys from "../../assets/images/PartnersLogo/Universys.png";
import Droneacharya from "../../assets/images/PartnersLogo/Droneacharya.png";
import DroneTechLab from "../../assets/images/PartnersLogo/DroneTechLab.jfif";
import TAS from "../../assets/images/PartnersLogo/TAS.png";
import Footer from "../footer"
import Header from "../header"


const Partner = () => {
  return (
<>
<Header/>
<div className="container mt-5">
      <h1 className="text-center mb-5 about-header mt-5">Our Partners</h1>
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <img src={Universys} alt="Partner" style={{height:"130px",width:"270px"}}/>
          <p className="mt-4 text-start card-paragraph">
            <b>F</b>ounded in 2006, after many years of experience in the IT industry,
            Universys was established as a group of separate bodies working in
            close association. In the year 2015, the different facets, Royal
            Universys, Webnet P Ltd., Kovai Digitech and Universys Energy P Ltd.
            were consolidated into a single entity and were incorporated as
            Universys Technologies Pvt Ltd.
            {" "}
            <a href="https://www.universys.in/" target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </p>
        </div>

        <div className="col-lg-6 col-md-6">
          <img src={TAS} alt="Partner" style={{height:"135px",width:"270px"}}/>
          <p className="mt-3 text-start card-paragraph">
           <b>T</b>hrottle Aerospace Systems (TAS) is a pioneer in the Indian drone
            industry, with the distinction of being the first DGCA approved
            drone maker for civil drones and having a license to manufacture
            military drones from MoD. TAS is driven by a vision to create
            innovative products and solutions that can transform the future of
            mobility in various sectors.{" "}
           
            <a href="https://throttle.aero/" target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </p>
        </div>

        <div className="col-lg-6 col-md-6">
          <img src={DroneTechLab} alt="Partner" />
          <p className="mt-3 text-start card-paragraph">
            <b>R</b>chobbytech Solutions Private Limited, is a company started by a
            team of technocrats a couple of years ago, and is prominently making
            its way to the top and leading the markets. The base of our company
            is in Kolkata (West Bengal, India), The company is backed by a team of experts which is
            highly experienced in their respective domains and is also well
            versed with the objectives of our organization. And owing to their
            support and streamline functioning we are able to greatly fulfill
            the demand and satisfy our customers.{" "}
           
            <a href="https://www.dronestechlabs.com/" target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </p>
        </div>

        <div className="col-lg-6 col-md-6">
          <img src={Droneacharya} alt="Partner" style={{height:"200px"}}/>
          <p className="mt-3 text-start card-paragraph">
           <b> E</b>stablished in 2017, DroneAcharya Aerial Innovations is a
            distinguished Deep Tech Data Science Company, standing at the
            forefront of innovation in Defence, Space, Drones, and Information
            Technology. Our core team comprises seasoned professionals from
            prestigious backgrounds, including the Indian Army, ISRO (Indian
            Space Research Organization), and global pioneers in DroneTech.
            Driven by a mission to shape aerial excellence, we focus on providing comprehensive drone solutions across diverse sectors.
            {" "}
            <a href="https://droneacharya.com/" target="_blank" rel="noopener noreferrer"> 
              Link
            </a>
          </p>
        </div>
        <br/>
      </div>
    </div>
    <Footer/>
</>
  );
};

export default Partner;
