import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";
import ProfileLogo from "../assets/images/profile_gif.gif";
import Avatar from '@mui/material/Avatar';
import { Container, Row, Col } from "react-bootstrap";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import './Profile.css'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';



import Typography from "@mui/material/Typography";

const Profile = () => {
  const [profileDetail, setProfileDetail] = useState("");
  const navigate = useNavigate();

  const id = localStorage.getItem("id");

  const getData = () => {};

  useEffect(() => {
    fetch(`http://localhost:4000/profileget/${id}`, {
      method: "GET",
    })
      .then((data) => data.json())
      .then((f) => setProfileDetail(f));
  }, []);

  console.log(profileDetail);
  const getDataprofile = () => {
    navigate("/");
  };

  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
    <>
      <Header />
<div className="container">
<div  className="profile-container">
   
     <Card className="profile-wrapper">
          <CardContent >
            <Typography
              style={{ marginLeft: "40%" }}
              gutterBottom
              variant="h5"
              component="div"
            >        <Avatar  sx={{ backgroundColor: "orange" }} src="/broken-image.jpg" />
          
       
            </Typography>
          </CardContent>
          <PersonIcon/>
          <TextField  
            id="standard-basic"
            variant="standard"
            className="form-control mr-2 mb-4"
            value={profileDetail.username}
            readOnly

      
          />
      <EmailIcon/>
          <TextField
            id="standard-basic"
            variant="standard"
            className="form-control mr-2 mb-4"
            value={profileDetail.email}
            readOnly
          />
  
          <div className="col-auto">
            <button className="btn btn-success mr-2" onClick={getDataprofile}>
              Save
            </button>
          </div>
        </Card> 


     </div>
</div>

      <Footer />
    </>
  );
};

export default Profile;
