// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .autopilot-page{
    font-family: 'Open Sans, sans-serif'; 
} */`, "",{"version":3,"sources":["webpack://./src/components/AllCategory/AutoPilot.css"],"names":[],"mappings":"AAAA;;GAEG","sourcesContent":["/* .autopilot-page{\r\n    font-family: 'Open Sans, sans-serif'; \r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
