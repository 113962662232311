// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coldChain h3{
    font-Family: 'Open Sans, sans-serif';
    color: rgb(83, 50, 1);
}
.coldChain P{
    font-Family: 'Open Sans, sans-serif';
    font-weight: 800;
 
}

.coldChain-content{
  background-color: rgb(243, 202, 142);
    border: 1px solid transparent; 
  animation: multicolorBorderAnimation 5s infinite;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.coldChain-content:hover{
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}



@keyframes multicolorBorderAnimation {
    0% {
      border-color: rgb(24, 13, 54); 
    }
    25% {
      border-color: rgb(255, 196, 0); 
    }
    50% {
      border-color: rgb(38, 38, 75); 
    }
    75% {
      border-color: rgb(255, 145, 0); 
    }
    100% {
      border-color: rgb(30, 21, 66); /* Purple */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/EdgeProducts/ColdChain.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,qBAAqB;AACzB;AACA;IACI,oCAAoC;IACpC,gBAAgB;;AAEpB;;AAEA;EACE,oCAAoC;IAClC,6BAA6B;EAC/B,gDAAgD;EAChD,mBAAmB;EACnB,sCAAsC;EACtC,uCAAuC;AACzC;;AAEA;EACE,sCAAsC;AACxC;;;;AAIA;IACI;MACE,6BAA6B;IAC/B;IACA;MACE,8BAA8B;IAChC;IACA;MACE,6BAA6B;IAC/B;IACA;MACE,8BAA8B;IAChC;IACA;MACE,6BAA6B,EAAE,WAAW;IAC5C;EACF","sourcesContent":[".coldChain h3{\r\n    font-Family: 'Open Sans, sans-serif';\r\n    color: rgb(83, 50, 1);\r\n}\r\n.coldChain P{\r\n    font-Family: 'Open Sans, sans-serif';\r\n    font-weight: 800;\r\n \r\n}\r\n\r\n.coldChain-content{\r\n  background-color: rgb(243, 202, 142);\r\n    border: 1px solid transparent; \r\n  animation: multicolorBorderAnimation 5s infinite;\r\n  border-radius: 20px;\r\n  box-shadow: 0 1px 2px rgba(0,0,0,0.15);\r\n  transition: box-shadow 0.3s ease-in-out;\r\n}\r\n\r\n.coldChain-content:hover{\r\n  box-shadow: 0 5px 15px rgba(0,0,0,0.3);\r\n}\r\n\r\n\r\n\r\n@keyframes multicolorBorderAnimation {\r\n    0% {\r\n      border-color: rgb(24, 13, 54); \r\n    }\r\n    25% {\r\n      border-color: rgb(255, 196, 0); \r\n    }\r\n    50% {\r\n      border-color: rgb(38, 38, 75); \r\n    }\r\n    75% {\r\n      border-color: rgb(255, 145, 0); \r\n    }\r\n    100% {\r\n      border-color: rgb(30, 21, 66); /* Purple */\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
