import React, { createContext, useReducer,useState } from 'react';

/* user Context */
const userContext = createContext();



/* user-Provider Component */
const UserProvider = ({ children }) => {

    const [user,setUser]=useState("");
  
    const login = (userName) => {
        setUser(userName);
      };
    
      const logout = () => {
        setUser(null);
      };

   
    const values = {
       login,logout,user
    };

    return (
        <userContext.Provider value={values}>
            {children}
        </userContext.Provider>
    );

};


export default userContext;
export { UserProvider };