import React from 'react';
import Header from "../../components/header";
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import '../InnovationLab/InnovationLab.css';

const InnovationLab = () => {
  const navigate = useNavigate();
  
  return (
    <>
   
      <div className="innovation-lab-container">
        <img
          src="https://res.cloudinary.com/do7bct4kg/image/upload/v1724308998/agaxbka9swop5mtk8a1r.jpg"
          alt="Innovation Lab Modules"
          className="responsive-image"
        />
       
      </div>
   
    </>
  );
};

export default InnovationLab;
