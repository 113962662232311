// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ajeetmini-para{
color:rgb(0, 0, 0);
font-weight: 600;
font-size: 16px;
font-family: Georgia, serif;

}
.ajeetheader{
    font-family: 'Courier New', monospace
}`, "",{"version":3,"sources":["webpack://./src/components/AjeetMini.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,2BAA2B;;AAE3B;AACA;IACI;AACJ","sourcesContent":[".Ajeetmini-para{\r\ncolor:rgb(0, 0, 0);\r\nfont-weight: 600;\r\nfont-size: 16px;\r\nfont-family: Georgia, serif;\r\n\r\n}\r\n.ajeetheader{\r\n    font-family: 'Courier New', monospace\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
