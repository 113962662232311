import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Header from "../components/header";
import Footer from "../components/footer";


function Privacy_policy() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="about-header text-center">
          <h3>CANCELLATION POLICY</h3>
        </div>
      </div>
      <br></br>
      <br></br>
      <div

        style={{
          backgroundColor: "orange",
          color: "white",
          width: "72.5%",
          margin: "auto",
          padding: "30px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center vh-80">
          <div
            style={{
              backgroundColor: "#0f143b", // Example color for the nested box
              padding: "50px",
              borderRadius: "8px",
              width: "100%",
              color:'white'
            }}
          >
  

  <ul>
    <h1 style={{color:"orange"}}>Cancellation Policy</h1>
    <p>Last updated: April 24, 2024</p>
    <p>Thank you for shopping at zuppa.io.</p>
    <p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and Cancellation. This Cancellation and Refund Policy has been created with the help of the <a href="https://www.privacypolicies.com/return-refund-policy-generator/" target="_blank">Cancellation and Refund Policy Generator</a>.</p>
    <p>The following terms are applicable for any products that You purchased with Us.</p>
    <h2 >Interpretation and Definitions</h2>
    <h3 style={{color:"orange"}}>Interpretation</h3>
    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
    <h3 style={{color:"orange"}}>Definitions</h3>
    <p>For the purposes of this Cancellation and Refund Policy:</p>
    <ul>
    <li>
    <p><strong style={{color:"orange"}}>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Zuppa Geo Navigation Private Limited, Polyhose Tower No.86, West Wing, 4th Floor Anna Salai, Guindy, Chennai, TamilNadu-600032.</p>
    </li>
    <li>
    <p><strong style={{color:"orange"}}>Goods</strong> refer to the items offered for sale on the Service.</p>
    </li>
    <li>
    <p><strong style={{color:"orange"}}>Orders</strong> mean a request by You to purchase Goods from Us.</p>
    </li>
    <li>
    <p><strong style={{color:"orange"}}>Service</strong> refers to the Website.</p>
    </li>
    <li>
    <p><strong style={{color:"orange"}}>Website</strong> refers to zuppa.io, accessible from <a href="https://zuppa.io/" rel="external nofollow noopener" target="_blank">https://zuppa.io/</a></p>
    </li>
    <li>
    <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
    </li>
    </ul>
    <h2 style={{color:"orange"}}>Your Order Cancellation Rights</h2>
    <p>You are entitled to cancel Your Order within 7 days without giving any reason for doing so.</p>
    <p>The deadline for cancelling an Order is 7 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>
    <p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
    <ul>
    <li>By email: askme@zuppa.io</li>
    </ul>
    <p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
    <h2 style={{color:"orange"}}>Conditions for Returns</h2>
    <p>In order for the Goods to be eligible for a return, please make sure that:</p>
    <ul>
    <li>The Goods were purchased in the last 7 days</li>
    <li>The Goods are in the original packaging</li>
    </ul>
    <p>The following Goods cannot be Cancellationed:</p>
    <ul>
    <li>The supply of Goods made to Your specifications or clearly personalized.</li>
    <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
    <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
    <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
    </ul>
    <p>We reserve the right to refuse Cancellations of any merchandise that does not meet the above Cancellation conditions in our sole discretion.</p>
    <p>Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</p>
    <h2 style={{color:"orange"}}>Cancellation Goods</h2>
    <p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
    <p>Polyhose Tower No.86, West Wing, 4th Floor Anna Salai, Guindy, Chennai, TamilNadu-600032</p>
    <p>We cannot be held responsible for Goods damaged or lost in Cancellation shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received Cancellation delivery.</p>
    <h2 style={{color:"orange"}}>Gifts</h2>
    <p>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your Cancellation. Once the Cancellationed product is received, a gift certificate will be mailed to You.</p>
    <p>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</p>
    <h3 style={{color:"orange"}}>Contact Us</h3>
    <p>If you have any questions about our Cancellations and Refunds Policy, please contact us:</p>
    <ul>
    <li>By email: askme@zuppa.io</li>
    </ul>
</ul>


          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default Privacy_policy;
