import React from 'react'
import Header from "../components/header";
import Footer from "../components/footer";

function ReturnPolicy() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="about-header text-center"  >
          <h3>RETURN POLICY</h3>
        </div>
      </div>
      <br></br>
      <br></br>
      <div
        style={{
          backgroundColor: "orange",
          color: "white",
          width: "72.5%",
          margin: "auto",
          padding: "30px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center vh-80">
          <div
            style={{
              backgroundColor: "#0f143b", // Example color for the nested box
              padding: "50px",
              borderRadius: "8px",
              width: "100%",
              color:'white'
            }}
          >

<p> At Zuppa.io, we want you to be
  completely satisfied with your purchase. If for any reason you are not entirely
  happy with your order, we're here to help with our straightforward return
  policy.</p>
<h4 style={{color:"orange"}}>Eligibility for Returns</h4>
<p>We accept returns for most items within
  30 days of delivery. To be eligible for a return, your item must be unused, in
  the same condition that you received it, and in its original packaging.</p>

<p>Certain products such as perishable
  goods, personalized items, and intimate or sanitary goods are exempt from being
  returned for hygiene reasons. Additionally, items marked as final sale are not
  eligible for return.</p>




<h4 style={{color:"orange"}}>Initiating a Return</h4>
<p>To
  initiate a return, please contact our customer service team at <a
  href="mailto:askme@zuppa.io">askme@zuppa.io</a> within the specified return
  period. Please provide your order number and details of the item(s) you wish to
  return. </p>

  <p>Once
    your return request is approved, we will provide you with instructions on how
    to return the item(s) to us. </p>

<h4 style={{color:"orange"}}>Return Shipping</h4>
<p>Customers are responsible for the cost of
  return shipping unless the return is due to our error or a defective product.
  We recommend using a trackable shipping service and purchasing shipping
  insurance to ensure that your return package reaches us safely.</p>


<h4 style={{color:"orange"}}>Refund Process</h4>
<p>Once
  your return is received and inspected, we will send you an email notification
  to inform you that we have received your returned item. We will also notify you
  of the approval or rejection of your refund.</p>

  <p>If
    your return is approved, a refund will be processed to the original method of
    payment within 7 business days. Please note that the time it takes for the
    refund to reflect in your account may vary depending on your payment provider.</p>


<h4 style={{color:"orange"}}>Exchanges</h4>
<p>If you would like to
  exchange an item for a different size, or
  variant, please contact our customer service team to arrange for an exchange.
  Exchanges are subject to product availability.</p>


<h4 style={{color:"orange"}}>Damaged or Defective Items</h4>
<p>If you receive a damaged or defective
  item, please contact us immediately with photos of the damage or defect. We
  will work with you to arrange for a replacement or refund as quickly as
  possible.</p>

<h4 style={{color:"orange"}}>Changes to Return Policy</h4>
<p>We reserve the right to update or modify
  our return policy at any time without prior notice. Any changes to the return
  policy will be reflected on this page.</p>



<p>If you have any further questions or
  concerns regarding our return policy, please don't hesitate to contact us at <a
  href="mailto:askme@zuppa.io">askme@zuppa.io</a>.</p>



      <h5  >Zuppa.io</h5>
      <h5  >https://zuppa.io/</h5>
      <h5 >Mobile: +91 9952081655</h5>

          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
}

export default ReturnPolicy;
