import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./Zuppascout.css";
import "react-slideshow-image/dist/styles.css";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { Fade} from "react-awesome-reveal";
// import mouseHover from "../assets/Autopilot/mousehover.gif";
import { useNavigate } from "react-router-dom";



function ZuppaCondor() {
   const fadeImages = ["fixedWing1.png","fixedWing2.png","fixedWing3.png"]; // Replace with your image paths
  const navigate = useNavigate()
  const fadeProperties = {
    duration: 1000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: true,
  };
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header /> 
   
      <div className="container">
    
        <div className="about-header text-center">
          <h3>ZUPPA CONDOR VTOL</h3>
        </div>


        <Carousel>
          {fadeImages.map((image, index) => (
            <Carousel.Item>
              <img src={image} alt={`slide-${index}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

     


      <br />

      <div
        style={{
          backgroundColor: "orange",
          color: "black",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
          <div className="text-center">
            <p className="text-center display-1 fw-bold text-light Tech-spec-text mt-5">
              Technical Specification
            </p>
          </div>
        </div>


        <div className="container mt-4">
      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}>ZUPPA CONDOR UNMANNED V-TOL AERIAL VEHICLE</th>
            </tr>
          </thead>
          <tbody>



{/* ***** 1  to 12********** */}


            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Material</td>
              <td>Carbon Fiber Frame + ABS</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>UAV Weight with battery and max. payload</td>
              <td>{"< 6 Kg"}</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>UAV Size with Propeller</td>
              <td>2.3 m x 0.95 m </td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Endurance (upto 1000m AMSL Take-Off)</td>
              <td>{" > 125 minutes with either payload (upto 5000m AMSL Takeoff)"}</td>
            </tr>

            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Range of live transmission (LOS)</td>
              <td>Up to 20 km LOS</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Typical Cruise Speed</td>
              <td>~13 m/s </td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Propulsion</td>
              <td>Battery Powered Electric Propulsion</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Maximum operating altitude (AGL)</td>
              <td>1500m AGL (Above Ground Level)</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Maximum launch altitude (AMSL)</td>
              <td>5000m AMSL (Above Mean Sea Level)</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Functional Temperature Range</td>
              <td> -30 to 55 degree</td>
            </tr>
           

{/* ***** 16  to 17 ********** */}

            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Technical Life of UAV (Landings)</td>
              <td>Minimum 600+ landings (OEM Certification)</td>
            </tr>

            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Launch & Recovery</td>
              <td>Autonomous Vertical Take-Off & Landing (VTOL)</td>
            </tr>


{/* ***** 21********** */}
<tr>
              <td style={{backgroundColor:"white", color:"black"}}>Flight Modes</td>
              <td>1. Altitude Hold Loiter at a defined waypoint
2. Autonomous Waypoint Navigation (pre-defined as well as dynamically adjustable waypoints during flight) 
3. Remotely Piloted mode for video-based navigation (RPV Mode) 
4. Real-time Target Tracking of designated static and moving targets
5. Auto Landing</td>

            </tr>

{/* ***** 23 ********** */}


<tr>
 <td style={{backgroundColor:"white", color:"black"}}>Deployment Time</td>
              <td>{"< 6 minutes"}</td>
            </tr>

{/* ***** 25 26 27********** */}


<tr>
 <td style={{backgroundColor:"white", color:"black"}}>Failsafe features</td>
              <td>1. Auto-Return to Home and Land on Communication Failure 
2. Auto-Return to Home and Land on Low Battery 
3. Multiple GPS on-board for redundancy 
4. Auto-Return to Home and Land on Battery Imbalance 
5. Auto-Return to Home and Land on exceeding Temperature limit of the system</td>
            </tr>

            <tr>
 <td style={{backgroundColor:"white", color:"black"}}>Autopilot </td>
              <td>Indigenous NavGati Autopilot and components used.</td>
            </tr>

            <tr>
 <td style={{backgroundColor:"white", color:"black"}}>Navigation Lights (GCS) </td>
              <td>Switchable (from GCS)  Provided windows and android GCS.</td>
            </tr>

{/* *****    30  ********** */}
<tr>
 <td style={{backgroundColor:"white", color:"black"}}>Payload Options</td>
              <td>1.ZOOM CAMERA: 30X Optical Zoom (180X Hybrid Zoom)
Focal Length: 4.5±5% to 148.4±5% mm
Image Sensor: 1/2.7 Inch,
Aperture: F1.3±5% to F2.8±5%
FOV: Horizontal 58.1°
TF Recording Resolution: 4K (4096 x 2160) @ 25 fps
Video Storage Bitrate: 12 Mbps (H.265 codec)  

2. REMOVABLE AND REPLASABLE THERMAL CAMERA :  C12 WITH DAY AND THERMAL CAMERA</td>
            </tr>

          </tbody>
        </table>
      </div>

      
    </div>

    
      </div>

      <br />
      <br />

      {/* <div className="text-center">
        <video autoPlay loop muted style={{minWidth:"100%"}}>
          <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355956/simslzxy4ftwkdouscnp.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="text-center">
        <video autoPlay loop muted style={{minWidth:"100%"}}>
          <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355947/yyqnzl5uylsnf5230bdk.mp4" type="video/mp4" />
        </video>
      </div> */}

       {/* <div className="row">
  
<div className="col-lg-6">
<a className="button-box" target="_blank" href="https://mkp.gem.gov.in/unmanned-aerial-vehicle-payload-systems-surveillance/scout-uav/p-5116877-3850787369-cat.html">
                <button className="gem-portal">
                 
                  <img  className="gem-portal-image"  src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1737959121/li7t14kf6xrgjeihp44v.png" alt="Handshake" />
                </button>
              </a>
</div>
<div className="col-lg-6">
  
<div className="button-box" onClick={()=> navigate("/DroneShop")}>
                <button className="buy-button">
                  <p className="buy-title text-center">Buy</p>                
                  <img src={mouseHover} alt="Handshake" />
                </button>
              </div>
</div>
</div> */}

              <br />
              <br />
      <Footer />
    </div>
  );
}

export default ZuppaCondor;
