import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Header from '../components/header';
import Footer from "./footer";
import "./ContactUs.css"


const ContactUs = () => {
  const [submissionStatus, setSubmissionStatus] = useState('');

  const formik = useFormik({
       initialValues: {
      name: '',
      email: '',
      phone: '', 
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
	  phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits') // Phone validation
        .required('Required'),
      subject: Yup.string()
        .max(100, 'Must be 100 characters or less')
        .required('Required'),
      message: Yup.string()
        .max(500, 'Must be 500 characters or less')
        .required('Required'),
 
    }),
    onSubmit: async values => {
      try {
        const response = await fetch('https://shop.zuppa.io:4000/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        });

        if (response.ok) {
          setSubmissionStatus('Form submitted successfully!');
          formik.resetForm();
        } else {
          setSubmissionStatus('Failed to submit the form. Please try again.');
        }
      } catch (error) {
        setSubmissionStatus('An error occurred. Please try again.');
      }
    }
  });
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
  <>
  
  <div className="container mt-5">
      <Header />
      <h2 className='about-header text-center' >Contact Us</h2>
      {/* <h5 className='contact-left text-center'>We would be 
        delighted to answer any questions you may have.
         Please do not hesitate to contact us.</h5> */}

      <div className="row">
        <div className="col-lg-6 col-md-6 contact-right">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </div>
			
			<div className="mb-3">
                <label htmlFor="phone" className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-danger">{formik.errors.phone}</div>
                ) : null}
              </div>
			  
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">Subject</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                name="subject"
                {...formik.getFieldProps('subject')}
              />
              {formik.touched.subject && formik.errors.subject ? (
                <div className="text-danger">{formik.errors.subject}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="3"
                {...formik.getFieldProps('message')}
              ></textarea>
              {formik.touched.message && formik.errors.message ? (
                <div className="text-danger">{formik.errors.message}</div>
              ) : null}
            </div>
         
            <button type="submit" className="btn btn-primary">Submit</button>
            {submissionStatus && <div className="mt-3 alert alert-info">{submissionStatus}</div>}
          </form>
        </div>

        <div className="col-lg-6 col-md-6 contact-left">
          <div className="address-info">
            <h3 style={{color:"blue"}}>Address Info</h3>
            <p>Polyhose Tower No.86, West Wing, 4th Floor Anna Salai, Guindy, Chennai, Tamil Nadu-600032</p>
            <p><strong>Phone Number: </strong><a href="tel:+919952081655">+91 9952081655</a></p>
            <p><strong>Email: </strong><a href="mailto:askme@zuppa.io" style={{ textDecoration: "none" }}>askme@zuppa.io</a></p>
          
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.4845176726624!2d80.2181855!3d13.0118531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52678d4cde040b%3A0xd60e2a228e4ee474!2sPolyhose%20Towers%2C%204th%20floor%2C%20west%20wing%2C%20Anna%20Salai%2C%20Guindy%2C%20Chennai%2C%20Tamil%20Nadu%20600032!5e0!3m2!1sen!2sin!4v1620133451435!5m2!1sen!2sin"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy">

              </iframe>
          </div>
        </div>
      </div>

    </div>
    <Footer/>
  </>
  );
};

export default ContactUs;
