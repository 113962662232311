import React, { useEffect, useState } from "react";
import "./Login.css";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import * as yup from "yup";
import { useFormik } from "formik";

const Register = () => {
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const isValid = username !== "" && email !== "" && password !== "";



  const postMethod = () => {
    const newData = {
      username: username,
      email: email,
      password: password,
    };
    console.log(newData);
    fetch("https://shop.zuppa.io:4000/signup", {
      method: "POST",
      body: JSON.stringify(newData),
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => data.json())

      .then((f) => {
        if (f.message === "This user Already exists") {
          alert(f.message);
        } else {
          alert(f.message);
          navigate("/Login");
        }
      });
  };

  const loginHandle = () => {
    navigate("/Login");
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);

  return (
    <div>
      <Header />
      <div className="register-container">
        <div className="wrapper">
          <div action="">
            <h1>Register</h1>

            <div className="input-box">
              <input
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                type="name"
                placeholder="Enter Your Name"
                required=""
              />

              <PersonIcon className="loginpageicon" fontSize="large" />
            </div>
            <div className="input-box">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter Your Email Id"
                required=""
              />

              <EmailIcon className="loginpageicon" fontSize="large" />
            </div>
            <div className="input-box">
      <input
        label="Password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type={showPassword ? 'text' : 'password'}z
        placeholder="Enter Your Password"
      />
      {showPassword ? (
        < VisibilityIcon fontSize="large"  color="action" className="loginpageicon" onClick={handleTogglePasswordVisibility} />
      ) : (
        <VisibilityOffIcon fontSize="large" className="loginpageicon" onClick={handleTogglePasswordVisibility} />
      )}
    </div>
            <div className="remember-forgot">
              <label>
                <input type="checkbox" Remember me />
                Remember me
              </label>
            </div>
            <button type="submit" onClick={postMethod} disabled={!isValid}>
              Submit
            </button>

            <div className="register-link">
              <p>
                Already have an account ?{" "}click here 👇
                <a href="# " onClick={loginHandle}>
                <h5 className="register-button-tag">Login</h5>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
