import React from "react";
import "./Introduction.css";
import Video from "../../assets/video.png";
import map from "../../assets/map.png";
import binacular from "../../assets/binacular.png";
import agriculture from "../../assets/agriculture.png";
import druck from "../../assets/druckbike.png";
import AutopilotLogo from "../../assets/AutopilotLogo.png";


const Introduction = () => {
 
  return (
    <>
  <div className="intro-background-container">
      <br />
      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="intro-vertical-content">
              <h2 className="intro-about-header">INTRODUCTION TO DRONES</h2>
              <h5>UAV - Unmanned Aerial Vehicle</h5>
              <h6>Remotely controlled by a human operator</h6>
              <h6>Autonomously controlled by onboard computers</h6>
            </div>
          </div>
          <div className="col-lg-6 mt-2">
            <img src={AutopilotLogo} alt="Drone" className="intro-image" />
          </div>
        </div>

    
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="row d-flex flex-row flex-wrap">
          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-one h-100">
              <div className="card-body">
                <h5 className="p-2">Nano Drones</h5>
                <b>{"<"} 250gms</b>
              </div>
              <br/>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-two h-100">
              <div className="card-body">
                <h5 className="p-2">Micro Drones</h5>
                <b>250gms - 2kgs</b>
              </div>
              <br/>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-three h-100">
              <div className="card-body">
                <h5 className="p-2">Small Drones</h5>
                <b>2kgs - 25kgs</b>
              </div>
              <br/>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-four h-100">
              <div className="card-body">
                <h5 className="p-2">Medium Drones</h5>
                <b>25kgs - 150kgs</b>
              </div>
              <br/>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
    
            <div className="intro-card intro-card-five h-100">
        
              <div className="card-body">
       
                <h5 className="p-2">Large Drones</h5>
                <b>{">"} 150kgs</b>
            
              </div>
              <br/>
            </div>
          </div>
        </div>

        <div className="row d-flex flex-row flex-wrap">
          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-six h-100">
              <img
                style={{
                  height: "80px",
                  width: "100px",
                  marginLeft: "-10px",
                }}
                src={Video}
                alt="Recreational"
              />
              <div className="card-body">
                <h5 className="card-title text-start" style={{marginLeft:"10px"}}>Recreational</h5>
                <div className="intro-button-container">
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    N
                  </button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    Mi
                  </button>
                  <button className="intro-animated-button">S</button>
                  <button className="intro-animated-button">M</button>
                  <button className="intro-animated-button">L</button>
               
                </div>
                <br/>
              </div>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-seven h-100">
              <img
                style={{
                  height: "80px",
                  width: "100px",
                  marginLeft: "3px",
                  padding:"5px"
                }}
                src={map}
                alt="Survey & Mapping"
              />
              <div className="card-body">
                <h5 className="card-title text-start"style={{marginLeft:"10px"}}>Survey & Mapping</h5>
                <div className="intro-button-container">
                  <button className="intro-animated-button">N</button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    Mi
                  </button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    S
                  </button>
                  <button className="intro-animated-button">M</button>
                  <button className="intro-animated-button">L</button>
                </div>
                <br/>
              </div>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-eight h-100">
              <img
                style={{
                  height: "70px",
                  width: "80px",
                    padding:"5px",
                  marginLeft: "13px",
                }}
                src={binacular}
                alt="Surveillance"
              />
              <div className="card-body">
                <h5 className="card-title text-start"style={{marginLeft:"10px",marginTop:"10px"}}>Surveillance</h5>
                <div className="intro-button-container">
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    N
                  </button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    Mi
                  </button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    S
                  </button>
                  <button className="intro-animated-button">M</button>
                  <button className="intro-animated-button">L</button>
                </div>
                <br/>
              </div>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-nine h-100">
              <img
                style={{ height: "80px", width: "100px", marginLeft: "-8px" }}
                src={agriculture}
                alt="Agricultural"
              />
              <div className="card-body">
                <h5 className="card-title text-start"style={{marginLeft:"10px"}}>Agricultural</h5>
                <div className="intro-button-container">
                  <button className="intro-animated-button">N</button>
                  <button className="intro-animated-button">Mi</button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    S
                  </button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    M
                  </button>
                  <button className="intro-animated-button">L</button>
                </div>
                <br/>
              </div>
            </div>
          </div>

          <div className="col-lg flex-grow-1 mb-4">
            <div className="intro-card intro-card-ten h-100">
              <img
                style={{ height: "80px", width: "100px", marginLeft: "15px" }}
                src={druck}
                alt="Logistics"
              />
              <div className="card-body">
                <h5 className="card-title text-start"style={{marginLeft:"10px"}}>Logistics</h5>
                <div className="intro-button-container">
                  <button className="intro-animated-button">N</button>
                  <button className="intro-animated-button">Mi</button>
                  <button className="intro-animated-button">S</button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    M
                  </button>
                  <button
                    className="intro-animated-button"
                    style={{ backgroundColor: "blue", color: "white" }}
                  >
                    L
                  </button>
                </div>
                <br/>
              </div>
            </div>
          </div>
        </div>

      
      </div>
    </div>



    </>
  );
};

export default Introduction;
