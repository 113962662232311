// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    font-family: "Montserrat", sans-serif;
  }
  
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    background: linear-gradient(-45deg, #fff1e6,  #fff1e6, #ffe3cd, #fff1e6);
    /* background-color:#ffe3cd ; */

    /* background: url('https://www.zuppa.io/img/slider1.jpg'); */
  	animation: gradient 15s ease infinite;
    background-repeat: no-repeat;
    background-size: cover;
 
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;EACvC;;EAEA;;IAEE,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,wEAAwE;IACxE,+BAA+B;;IAE/B,6DAA6D;GAC9D,qCAAqC;IACpC,4BAA4B;IAC5B,sBAAsB;;EAExB;;EAEA;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,6BAA6B;IAC/B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":["html {\r\n    font-family: \"Montserrat\", sans-serif;\r\n  }\r\n  \r\n  html,\r\n  body {\r\n    width: 100%;\r\n    height: 100%;\r\n    margin: 0px;\r\n    padding: 0px;\r\n    overflow-x: hidden;\r\n    background: linear-gradient(-45deg, #fff1e6,  #fff1e6, #ffe3cd, #fff1e6);\r\n    /* background-color:#ffe3cd ; */\r\n\r\n    /* background: url('https://www.zuppa.io/img/slider1.jpg'); */\r\n  \tanimation: gradient 15s ease infinite;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n \r\n  }\r\n\r\n  @keyframes gradient {\r\n    0% {\r\n      background-position: 0% 50%;\r\n    }\r\n    50% {\r\n      background-position: 100% 50%;\r\n    }\r\n    100% {\r\n      background-position: 0% 50%;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
