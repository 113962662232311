import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import '../Drone lab/DroneLabHome.css';  // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faTools, faLaptopCode, faProjectDiagram, faClipboardCheck, faCogs, faDatabase } from '@fortawesome/free-solid-svg-icons';
import Header from "../components/header";
import Footer from "../components/footer";
import droneImage from '../assets/DroneLabs/front face drone ajeet tr.png';
import zuppaImage from '../assets/DroneLabs/ZuppaLogo.jpg'; // Import the image for Zuppa
import 'bootstrap/dist/css/bootstrap.min.css';
import localIcon1 from '../assets/DroneLabs/icons8-home-60.png'; // Replace with actual paths
import localIcon2 from '../assets/DroneLabs/icons8-increase-64.png';
import localIcon3 from '../assets/DroneLabs/icons8-skill-50.png';
import localIcon4 from '../assets/DroneLabs/icons8-drone-64 (2).png';
import localIcon5 from '../assets/DroneLabs/icons8-sensor-50.png';
import localIcon6 from '../assets/DroneLabs/icons8-operation-64 (1).png';
import localIcon7 from '../assets/DroneLabs/icons8-innovation-50.png';
import localIcon8 from '../assets/DroneLabs/Logo.png';
import ContactPopup from '../components/PopupContact/ContactPopup';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Introduction from '../components/Indroduction/Introduction';
import InnovationLab from '../components/InnovationLab/InnovationLab';
import JobSkill from '../components/JobOpportunity/JobSkill';
import SensorManagements from '../components/SensorManagements/SensorManagements';
import WhatMakeDrones from "../components/MakeDrone/Drone";
import MroLab from '../components/MroLab/MroLab';
import Industry from '../components/Industry/Industry';
import Zuppa from '../components/Who-is-Zuppa/Zuppa';
import NewsAndEvents from '../components/Accolades/NewsAndEvents';
import AutoPilot from '../components/AutopilotCompanies/Company';



const flyUp = keyframes`
  0% {
    bottom: -100px; /* Start below the screen */ 
    transform: translateX(-50%) translateY(100%);
  }
  100% {
    bottom: 50%; /* Fly to the vertical center of the screen */
    transform: translateX(-50%) translateY(50%);
  }
`;


const Drone = styled.img`
  position: absolute;
  width: 400px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  animation: ${flyUp} 2s forwards;
`;

const CircularMenuWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 80%;  /* Adjust this value to set the button below the circular menu */
  left: 50%;
  transform: translateX(-50%);
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #e45b12;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 150px;
  &:hover {
    background-color: #d94a0d;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 16px;
  }
`;

const CircularMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMenuItems, setShowMenuItems] = useState(false);
  const openShowMenuItemsHandleOpen = () => setShowMenuItems(true);
  const closeShowMenuItemsHandleClose = () => setShowMenuItems(false);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 1000); // Delay matches drone animation

    return () => clearTimeout(timer);
  }, []);

  const images = [
    { image: localIcon6, title: "MRO Lab", link: "/MRO" },
    { image: localIcon7, title: "Innovation Lab", link: "/Innovation" },
    { image: localIcon3, title: "Types of Jobs", link: "/Jobs-skils" },
    { image: localIcon5, title: "Sensor Management", link: "/SensorManagement" },
    { image: localIcon4, title: "What makes a drone", link: "/MakeDrones" },
    { image: localIcon1, title: "Introduction to Drones", link: "/IntroductionDrones" },
    { image: localIcon2, title: "Market Growth", link: "/Growth" },
    { image: localIcon8, title: "Zuppa", link: "/database2", special: true }
  ];

  const menuItems = [
    { title: "Who is Zuppa", link: "/WhoZuppa" },
    { title: "Accolades", link: "/NewsAndEvents" },
    { title: "Proprietary Autpliot companies", link: "/Navgati_autopilot" }

  ];

  const [isMro, setIsMro] = useState(false);
  const openMroHandleOpen = () => setIsMro(true);
  const closeMroHandleClose = () => setIsMro(false);

  const [isInnovation, setIsInnovation] = useState(false);
  const openInnovationHandleOpen = () => setIsInnovation(true);
  const closeInnovationHandleClose = () => setIsInnovation(false);


  const [isTypeOfJobs, setIsTypeOfJobs] = useState(false);
  const openTypeOfJobsHandleOpen = () => setIsTypeOfJobs(true);
  const closeTypeOfJobsHandleClose = () => setIsTypeOfJobs(false);

  const [isSensorManagement, setIsSensorManagement] = useState(false);
  const openSensorManagementHandleOpen = () => setIsSensorManagement(true);
  const closeSensorManagementHandleClose = () => setIsSensorManagement(false);

  const [isWhatMakesAdrone, setIsWhatMakesAdrone] = useState(false);
  const openWhatMakesAdroneHandleOpen = () => setIsWhatMakesAdrone(true);
  const closeWhatMakesAdroneHandleClose = () => setIsWhatMakesAdrone(false);


  const [isIntroductionToDrones, setIsIntroductionToDrones] = useState(false);
  const openIntroductionToDronesHandleOpen = () => setIsIntroductionToDrones(true);
  const closeIntroductionToDronesHandleClose = () => setIsIntroductionToDrones(false);


  const [isMarketGrowth, setIsMarketGrowth] = useState(false);
  const openMarketGrowthHandleOpen = () => setIsMarketGrowth(true);
  const closeMarketGrowthHandleClose = () => setIsMarketGrowth(false);



  const [isWhoIsZuppa, setIsWhoIsZuppa] = useState(false);
  const openWhoIsZuppaHandleOpen = () => setIsWhoIsZuppa(true);
  const closeWhoIsZuppaHandleClose = () => setIsWhoIsZuppa(false);


  const [isAccolades, setIsAccolades] = useState(false);
  const openAccoladesHandleOpen = () => setIsAccolades(true);
  const closeAccoladesHandleClose = () => setIsAccolades(false);

  const [isProprietaryOfCompanies, setIsProprietaryOfCompanies] = useState(false);
  const openProprietaryOfCompaniesHandleOpen = () => setIsProprietaryOfCompanies(true);
  const closeProprietaryOfCompaniesHandleClose = () => setIsProprietaryOfCompanies(false);





  const handleClick = (event, item) => {
    if (item.special) {
      event.preventDefault(); // Prevent default action
      setShowMenuItems(!showMenuItems);
      // openShowMenuItemsHandleOpen()
    }
    if (item.title == "MRO Lab") {
      openMroHandleOpen()
    } else if (item.title == "Innovation Lab") {
      openInnovationHandleOpen()
    } else if (item.title == "Types of Jobs") {
      openTypeOfJobsHandleOpen()
    } else if (item.title == "Sensor Management") {
      openSensorManagementHandleOpen()
    } else if (item.title == "What makes a drone") {
      openWhatMakesAdroneHandleOpen()
    } else if (item.title == "Introduction to Drones") {
      openIntroductionToDronesHandleOpen()
    } else if (item.title == "Market Growth") {
      openMarketGrowthHandleOpen()
    } else if (item.title == "Who is Zuppa") {
      openWhoIsZuppaHandleOpen()
    } else if (item.title == "Accolades") {
      openAccoladesHandleOpen()
    } else if (item.title == "Proprietary Autpliot companies") {
      openProprietaryOfCompaniesHandleOpen()
    }

  };



  return (
    <>
      <CircularMenuWrapper>
        <nav className="circular-menu">
          <div className={`circle ${isOpen ? 'open' : ''}`}>
            {images.map((item, index) => (
              <a
                // href={item.link}
                title={item.title}
                key={index}
                onClick={(event) => handleClick(event, item)} // Pass event
                style={{
                  left: `${50 - 35 * Math.cos(-0.5 * Math.PI - 2 * (1 / images.length) * index * Math.PI)}%`,
                  top: `${50 + 35 * Math.sin(-0.5 * Math.PI - 2 * (1 / images.length) * index * Math.PI)}%`,
                  opacity: isOpen ? 1 : 0,
                  transition: `opacity 0.5s ease ${isOpen ? index * 0.1 : 0}s`, // Staggered appearance
                }}
              >
                <img src={item.image} alt={item.title} className='Icons mt-custom' />
                <span className="title-text">{item.title}</span> {/* Display the title text */}
              </a>
            ))}
          </div>
          {showMenuItems && (
            <div className="menu-items">
               <span
      onClick={() => setShowMenuItems(false)}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
        fontSize: "20px",
        color: "red",
        paddingLeft:"5px",
        paddingRight:"5px",
      }}
    >
    X
    </span>
              <ul>
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <a onClick={(event) => handleClick(event, item)} style={{ color: "#0f143b", cursor: "pointer" }}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        <div className="menu-circle">
            <div className="three-d-text">
              <h4 className='text-center'>Zuppa </h4>
          <h4>Drone Lab</h4>
          </div>
          </div>
        </nav>
      </CircularMenuWrapper>
      {/* <Modal
        open={showMenuItems}
        onClose={closeShowMenuItemsHandleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="menu-items">

          <MroLab />

        </div>
      </Modal> */}




      <Modal
        open={isMro}
        onClose={closeMroHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">

          <MroLab />

          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeMroHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>


      <Modal
        open={isInnovation}
        onClose={closeInnovationHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          < InnovationLab />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeInnovationHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>


      <Modal
        open={isTypeOfJobs}
        onClose={closeTypeOfJobsHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          < JobSkill />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeTypeOfJobsHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>


      <Modal
        open={isSensorManagement}
        onClose={closeSensorManagementHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          <SensorManagements />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeSensorManagementHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>



      <Modal
        open={isWhatMakesAdrone}
        onClose={closeWhatMakesAdroneHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          <WhatMakeDrones />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeWhatMakesAdroneHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>

      <Modal
        open={isIntroductionToDrones}
        onClose={closeIntroductionToDronesHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          <Introduction />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeIntroductionToDronesHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>


      <Modal
        open={isMarketGrowth}
        onClose={closeMarketGrowthHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          <Industry />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeMarketGrowthHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>



      <Modal
        open={isWhoIsZuppa}
        onClose={closeWhoIsZuppaHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          <Zuppa />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeWhoIsZuppaHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>


      <Modal
        open={isAccolades}
        onClose={closeAccoladesHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          <NewsAndEvents />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeAccoladesHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>


      <Modal
        open={isProprietaryOfCompanies}
        onClose={closeProprietaryOfCompaniesHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="all-modal-container">
          <AutoPilot />


          {/* Close button at the bottom */}
          <div className="all-modal-close-button-container">
            <button className="all-modal-close-button" onClick={closeProprietaryOfCompaniesHandleClose}>
              Close
            </button>
          </div>

        </div>
      </Modal>



    </>
  );
};




function DroneLabHome() {

  const [showMenu, setShowMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMenu(true);
    }, 2000); // Show menu after the drone animation

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Header />
      <div className="Containers">

        {!showMenu && <Drone src={droneImage} alt="Drone" />}
        {showMenu && <CircularMenu />}
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
        <ButtonWrapper>
          <StyledButton onClick={handlePopupOpen}>Contact Us / Brochure</StyledButton>
         
        </ButtonWrapper>
        <ContactPopup show={showPopup} onClose={handlePopupClose} />
      </div>
      <br/>
<br/>
<br/>
      <Footer />
    </div>
  );
};

export default DroneLabHome;