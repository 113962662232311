import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel, Card, Container, Row, Col } from 'react-bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({ behavior: 'smooth' }, 500);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <h2 className="about-header text-center">Our Products</h2>
        <Row className="mt-0">
          {/* Card 1 */}
          <Col xs={12} md={4} className="mb-4 carousel-card" onClick={() => navigate('/DroneShop')}>
            <Card>
              <Carousel>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/drone-slide1.png`}
                    className="d-block w-100"
                    alt="Image 1"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/drone-slide2.png`}
                    className="d-block w-100"
                    alt="Image 2"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/drone-slide3.png`}
                    className="d-block w-100"
                    alt="Image 3"
                  />
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title className="text-center">Drones</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          {/* Card 2 */}
          <Col xs={12} md={4} className="mb-4 carousel-card" onClick={() => navigate('/AutoPilotShop')}>
            <Card>
              <Carousel>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/autopilots-slide1.png`}
                    className="d-block w-100"
                    alt="Image 1"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/autopilots-slide2.png`}
                    className="d-block w-100"
                    alt="Image 2"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/autopilots-slide3.png`}
                    className="d-block w-100"
                    alt="Image 3"
                  />
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title className="text-center">Navgati Autopilots</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          {/* Card 3 */}
          <Col xs={12} md={4} className="mb-4 carousel-card" onClick={() => navigate('/Software')}>
            <Card>
              <Carousel>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/software-slide1.png`}
                    className="d-block w-100"
                    alt="Image 1"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/software-windows.png`}
                    className="d-block w-100"
                    alt="Image 2"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={`${process.env.PUBLIC_URL}/software-android.png`}
                    className="d-block w-100"
                    alt="Image 3"
                  />
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title className="text-center">Software</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
