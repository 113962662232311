import React, { useEffect } from "react";
import "./AjeetMini.css";
import Header from "../components/header";
import Footer from "./footer";
import { Fade, Zoom } from "react-awesome-reveal";

const AjeetMini = () => {
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
    <>
      <Header />
      <div className="container">
        <div className="about-header text-center">
          <h4>
            AJEET MINI MAKES A STERLING DEBUT AT BHARAT DRONE MAHOTSAV - 2022
          </h4>
        </div>
      </div>
   

      <div className="row">
        <div
          className="col-sm-4"
          style={{ marginLeft: "15%", width: "70%", height: "auto" }}
        >
          <img
            className="img-fluid"
            src="https://zuppa.io/product-img/Ajeet%20Mini%20makes%20a%20sterling%20debut%20at%20Bharat%20Drone%20Mahotsav%202022.jpg"
            alt="Responsive image"
            style={{ marginLeft: "15%", height: "auto", width: "70%" }}
          />
        </div>
      </div>
      <br />





<div className="container">
        <div className="row">
          <div className="Ajeetmini-para">
            <div className="col-md-8 offset-md-2 Ajeetmini-para-container ">
            <Fade duration={1200} direction="left">
            <p style={{ marginTop: "15px" }}>
            New Delhi [India], June 3 (ANI/NewsVoir): Ajeet Mini India’s
            Aatmnirbhar answer to global brands like DJI, Parot, Skydio, Autel
            and the likes was unveiled by our Chief Innovation Officer Venkatesh
            Sai at the Bharat Drone Mahotsav 2022 on 27/5/22 to the Honourable
            Prime Minister Narendra Modi and Minister of Civil Aviation
            Jyotiraditya Scindia at Pragati Maidan.{" "}
          </p>
          </Fade>
          </div>
          </div>
        </div>

      </div>
     
     
     
     
     
      <Footer />
    </>
  );
};

export default AjeetMini;
