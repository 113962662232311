import React, { useEffect } from 'react';
import "./Thechasm.css";
import thechasm from "../assets/images/thechasm.png"
import Header from "../components/header";
import { Fade } from 'react-awesome-reveal';
import Footer from '../components/footer';


const Thechasm = () => {
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
<>
   <Header />
<div className="container">
       
      <div className="row">
      <h2 className='about-header text-center'>AH !! THE CHASM</h2>
        <div className="col-lg-6">
          <img src='https://zuppa.io/product-img/blog.jpg' className="animated-3d" alt="First blog" />
      </div>

        <div className="col-lg-6 Chasm">   
     <Fade direction='left' duration={2000}>    <p><b style={{fontSize:"18px"}}>Ah !! The Chasm</b> Crossing the chasm and diffusion of technology were phrases I heard of quite late in the diffusion of our proprietary globally unique technology of  SYSTEM OF DISSEMINATED PARALLEL CONTROL COMPUTING IN REAL TIME.</p>
       </Fade>
        <Fade direction='left' duration={1200}>   
        <p>That the theory of Diffusion of Innovation published way back in 1962 by Everett Rogers, a professor of communication studies <a target='_blank' href="https://en.wikipedia.org/wiki/Diffusion_of_innovations">WIKIPEDIA</a>  and the marketing book Crossing The Chasm " by Geoffrey Moore both became validations of what we @Zuppa GeoNavTech have been going thru .</p>
        
        </Fade>
     </div>
<br/>
   
        <div className="col-lg-6 Chasm" style={{marginTop:"50px"}}>   
        <Fade direction='left'>  <p>The theory of diffusion of technology explains all the pain the we have endured, the challenges we have faced , the feeling of hopelessness , despair that has even led to suicidal thoughts all fall into place the moment one simply has a look at the theory of diffusion of innovation.</p></Fade> 
        <Fade direction='right'> 
          <p>We consider it to be both a privilege and a matter of pride being the only Indian Entrepreneurs to have created a “ Deeptech” computing technology when the word Deeptech was not heard off in the startup circles .</p>
          </Fade>

       </div>
       <div className="col-lg-6" style={{marginTop:"50px"}}>   
          <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Diffusion_of_ideas.svg/330px-Diffusion_of_ideas.svg.png' className="imageTwo" alt="Second blog" />
      </div>
      </div>
      <br/>
      <br/>
      <br/>

    </div>
    <Footer />
</>
  );
}

export default Thechasm;
