

import React from "react";
import "./footer.css";
import logo from "../assets/images/ZuppaLogo.jpg";
import LinkIcon from "@mui/icons-material/Link";

const Footer = () => {
   return (
    <div class="pg-footer">
      <footer class="footer">
        <svg
          class="footer-wave-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
        >
          <path
            class="footer-wave-path"
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <div class="footer-content">
          <div class="footer-content-column">
            <div class="footer-logo">
              <span class="hidden-link-text">LOGO</span>
             
              <img src={logo} alt="Logo" style={{ borderRadius: "5%" }} />
            </div>

            <div class="footer-menu">
              <h2 class="footer-menu-name" style={{ color: "orange" }}>
            
                Get Started
              </h2>
              <p class="footer-call-to-action-description">
            
              {/* Zuppa provides DGCA-approved indigenous drones and autopilot systems for defence and commercial applications, promoting self-reliance with high-quality, made-in-India technology. */}
                {/* <br /> */}
                Better known as ZUPPA,controls the entire product development Value Chain from Chip Level PCB Design to Firmware to Manufacturing . Hence ensures complete security of all products from Hacking as well as destination of Data.
              </p>
            </div>
          </div>
          <div class="footer-content-column">
            <div class="footer-menu">
              <h2
                class="footer-menu-name"
                style={{ fontSize: "25px", color: "orange" }}
              >
                Site Map
              </h2>
              <ul id="menu-company" class="footer-menu-list">
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/About"> 🔸 About Us</a>
                </li>

                <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                  <a href="/Navigati"> 🔸 Navgati Based</a>
                </li>
                <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                  <a href="/Drishya"> 🔸 Drishya Based</a>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Edge"> 🔸 Edge Based</a>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Partner"> 🔸 Our Partners</a>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Career"> 🔸 Career</a>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Download"> 🔸 Download</a>
                </li>

{/*                 
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="#">🔸 Contact Us</a>
                </li> */}

              </ul>

            </div>
          </div>
          <div class="footer-content-column">
            <div class="footer-menu">
              {/* <h2 class="footer-menu-name" style={{ color: "orange" }}>
                {" "}
                WEEKLY EVENTS
              </h2>
              <ul id="menu-quick-links" class="footer-menu-list">
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <a href="/Eyeballs">
                    The Show Stopper @AIDef 2022 Generates Eyeballs
                  </a>
                </li>
           
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <a href="/Defensesector">
                    {" "}
                    Changemakers: New Milestones for ‘Make In India’ in Defense
                    sector.
                  </a>
                </li>
          
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Armedforces">
                    {" "}
                    Armed forces get ready for future warfare!!
                  </a>
                </li>
            
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Ajeetmini"> Ajeet Mini makes a sterling debut</a>
                </li>
              </ul> */}


              
<h2
                class="footer-menu-name"
                style={{ fontSize: "25px", color: "orange" }}
              >
                Our Policies
              </h2>
              <ul id="menu-company" class="footer-menu-list">
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Privacy_policy">🔸 Privacy Policy</a>
                </li>

                <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                  <a href="/Termsandcondition">🔸 Terms and Condition</a>
                </li>

                <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                  <a href="/ShippingPolicy">🔸 Shipping Policy</a>
                </li>

                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/ReturnPolicy">🔸 Return Policy </a>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/Cancellation">🔸 Cancellation Policy </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer-content-column">
            <div class="footer-call-to-action">
              <h2
                class="footer-call-to-action-title"
                style={{ color: "orange",marginTop:"-30px" }} 
              >
                {" "}
                Find us
              </h2>
              <p class="footer-call-to-action-description">
                {" "}
                PolyHose Tower No:86, West Wing, 4th Floor, AnnaSalai, Guindy
                Chennai-600032
              </p>
              <a
                class="footer-call-to-action-button button"
                href="https://maps.app.goo.gl/T2MiAQ9u2ywNV7Hz9"
                style={{ height: "40px" }}
                target="_blank"
              >
                <img
                  style={{
                    height: "40px",
                    width: "30px",
                    marginLeft: "-25px",
                    marginTop: "-30px",
                  }}
                  src="https://media4.giphy.com/media/SWWLF8WluVJ5vJjMln/giphy.gif?cid=6c09b952p4mza1qt7fp9o5da8e3sgb47h621zkrxnrok2k7x&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=s"
                  alt=""
                />{" "}
                <p style={{marginLeft:"20px", marginTop:"-10px"}}>Location</p>
              </a>
      
            </div>
          
       


<div className="footer-call-to-action">
  <h2
    className="footer-call-to-action-title"
    style={{ color: "orange", marginTop: "-25px" }}
  >
    You Call Us
  </h2>

  <p className="footer-call-to-action-link-wrapper">
    Sales Support:{" "}
    <a
      className="footer-call-to-action-link"
      href="tel:9952081655"
      target="_self"
    >
      9952081655
    </a>
  </p>

<br/>

  <h2
    className="footer-call-to-action-title"
    style={{ color: "orange",marginTop:"-20px" }} 
  >
 EMail
  </h2>

  <p className="footer-call-to-action-link-wrapper">
    Sales Support:{" "}
    <a
      className="footer-call-to-action-link"
      href="mailto:askme@zuppa.io"
      target="_self"
    >
      askme@zuppa.io
    </a>
  </p>

  <p className="footer-call-to-action-link-wrapper">
    Customer Support:{" "}
    <a
      className="footer-call-to-action-link"
      href="mailto:support@zuppa.io"
      target="_self"
    >
      support@zuppa.io
    </a>
  </p>
</div>

    



          </div>
<br/>
<br/>
<br/>
<br/>


          <div class="footer-social-links">
            {" "}
            <svg
              class="footer-social-amoeba-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 236 54"
            >
              <path
                class="footer-social-amoeba-path"
                d="M223.06,43.32c-.77-7.2,1.87-28.47-20-32.53C187.78,8,180.41,18,178.32,20.7s-5.63,10.1-4.07,16.7-.13,15.23-4.06,15.91-8.75-2.9-6.89-7S167.41,36,167.15,33a18.93,18.93,0,0,0-2.64-8.53c-3.44-5.5-8-11.19-19.12-11.19a21.64,21.64,0,0,0-18.31,9.18c-2.08,2.7-5.66,9.6-4.07,16.69s.64,14.32-6.11,13.9S108.35,46.5,112,36.54s-1.89-21.24-4-23.94S96.34,0,85.23,0,57.46,8.84,56.49,24.56s6.92,20.79,7,24.59c.07,2.75-6.43,4.16-12.92,2.38s-4-10.75-3.46-12.38c1.85-6.6-2-14-4.08-16.69a21.62,21.62,0,0,0-18.3-9.18C13.62,13.28,9.06,19,5.62,24.47A18.81,18.81,0,0,0,3,33a21.85,21.85,0,0,0,1.58,9.08,16.58,16.58,0,0,1,1.06,5A6.75,6.75,0,0,1,0,54H236C235.47,54,223.83,50.52,223.06,43.32Z"
              ></path>
            </svg>
            <a
              class="footer-social-link email"
              href="mailto:askme@zuppa.io"
              target="_blank"
            >
              <span class="hidden-link-text">Email</span>

              <svg
                class="footer-social-icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 26 26"
                style={{
                  width: "32px",
                  height: "32px",
                  marginLeft: "60px",
                }}
              >
                <path
                  fill="#FF3D00"
                  className="footer-social-icon-path"
                  d="M20.25 4.5H3.75A2.25 2.25 0 0 0 1.5 6.75v10.5A2.25 2.25 0 0 0 3.75 19.5h16.5A2.25 2.25 0 0 0 22.5 17.25V6.75A2.25 2.25 0 0 0 20.25 4.5Zm0 1.5a.75.75 0 0 1 .75.75v1.968l-7.875 4.875a1.5 1.5 0 0 1-1.65 0L2.25 8.718V6.75a.75.75 0 0 1 .75-.75zm-16.5 12A.75.75 0 0 1 4.5 18l3.375 2.109a1.5 1.5 0 0 0 1.65 0L16.5 18a.75.75 0 0 1 .75.75v.75H3.75v-.75zm16.5 0v.75a.75.75 0 0 1-.75.75h-.75v-1.5h.75a.75.75 0 0 1 .75.75Z"
                ></path>
              </svg>
            </a>
            <a
              class="footer-social-link youtube"
              href="https://www.youtube.com/@zuppageonavtech636"
              target="_blank"
            >
              <span class="hidden-link-text">Youtube</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="35"
                height="40"
                viewBox="7 10 48 48"
              >
                <path
                  fill="#FF3D00"
                  d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
                ></path>
                <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
              </svg>
            </a>
            <a
              class="footer-social-link linkedin"
              href="https://www.linkedin.com/company/zuppa-drones/mycompany/"
              target="_blank"
            >
              <span class="hidden-link-text">Linkedin</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="50px"
                y="25px"
                width="40"
                height="50"
                viewBox="7 10 50 50"
              >
                <path
                  fill="#0078d4"
                  d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5 V37z"
                ></path>
                <path
                  d="M30,37V26.901c0-1.689-0.819-2.698-2.192-2.698c-0.815,0-1.414,0.459-1.779,1.364 c-0.017,0.064-0.041,0.325-0.031,1.114L26,37h-7V18h7v1.061C27.022,18.356,28.275,18,29.738,18c4.547,0,7.261,3.093,7.261,8.274 L37,37H30z M11,37V18h3.457C12.454,18,11,16.528,11,14.499C11,12.472,12.478,11,14.514,11c2.012,0,3.445,1.431,3.486,3.479  C18,16.523,16.521,18,14.485,18H18v19H11z"
                  opacity=".05"
                ></path>
                <path
                  d="M30.5,36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198c-1.295,0-1.935,0.912-2.243,1.677 c-0.082,0.199-0.071,0.989-0.067,1.326L25.5,36.5h-6v-18h6v1.638c0.795-0.823,2.075-1.638,4.238-1.638  c4.233,0,6.761,2.906,6.761,7.774L36.5,36.5H30.5z M11.5,36.5v-18h6v18H11.5z M14.457,17.5c-1.713,0-2.957-1.262-2.957-3.001  c0-1.738,1.268-2.999,3.014-2.999c1.724,0,2.951,1.229,2.986,2.989c0,1.749-1.268,3.011-3.015,3.011H14.457z"
                  opacity=".07"
                ></path>
                <path
                  fill="#fff"
                  d="M12,19h5v17h-5V19z M14.485,17h-0.028C12.965,17,12,15.888,12,14.499C12,13.08,12.995,12,14.514,12  c1.521,0,2.458,1.08,2.486,2.499C17,15.887,16.035,17,14.485,17z M36,36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698 c-1.501,0-2.313,1.012-2.707,1.99C24.957,25.543,25,26.511,25,27v9h-5V19h5v2.616C25.721,20.5,26.85,19,29.738,19 c3.578,0,6.261,2.25,6.261,7.274L36,36L36,36z"
                ></path>
              </svg>
            </a>
            <div>
              <a
                className="footer-social-link github"
                href="https://zuppa.io"
                target="_blank"
              >
                <span className="hidden-link-text">zuppa</span>
                <LinkIcon fontSize="large" />
              </a>
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          <div class="footer-copyright-wrapper">
            <p class="footer-copyright-text">
              <a class="footer-copyright-link" href="#" target="_self">
                {" "}
                ©2024. | Zuppa | All rights reserved.{" "}
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
