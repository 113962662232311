import React from 'react';
import image1 from "../../assets/Picture1.png"
import image2 from "../../assets/Picture2.png"
import image3 from "../../assets/Picture3.png"
import image4 from "../../assets/Picture4.png"
import './Zuppa.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import whozuppa from '../../assets/whozuppa.png';

const Zuppa = () => {
  
  const navigate = useNavigate()


  const homePage = ()=>{
    navigate("/")
  }
  return (

    <>
   
    
    

        <div className="about-header text-center">
          <h3>WHO IS ZUPPA</h3>

        </div>
      <div className="zuppa-content">
        <div className="zuppa-left">
          <div className="zuppa-logo">
            <img src={whozuppa}  alt="Zuppa Logo" className="logo-image" />
            <p className="zuppa-description">A Deep Tech company in Control & Communication systems for UAV</p>
          </div>
        </div>
        <br/>
        <div className="zuppa-right">
     
          <div className="zuppa-item">
         <span className="star">⭐</span>
            <p>Only <strong>Indian</strong> Company with patented <strong>proprietary</strong> Computing Architecture for Drone <strong>Autopilots</strong></p>
          </div>
      
          <div className="zuppa-item">
            <span className="star">⭐</span>
            <p>Only <strong>Manufacturer</strong> of Cyber secure <strong>Drones & Electronic stacks</strong> in India</p>
          </div>
         
          <div className="zuppa-item">
            <span className="star">⭐</span>
            <p><strong>Single Vendor</strong> of Made in India Drone Autopilots to <strong>DRDO*</strong> and <strong>DPSUs*</strong></p>
          </div>
         
          <div className="zuppa-item">
            <span className="star">⭐</span>
            <p>India’s first <strong>Aerospace International Quality</strong> certified manufacturer of <strong>Drones & Electronics</strong></p>
          </div>
          <div className="zuppa-footer">
          <p>*DRDO - Defence Research and Development Organisation</p>
          <p>*DPSU - Defence Public Sector Undertakings</p>
        </div>
       
          </div>
         
      </div>
    
    

    
    </>
  );
}

export default Zuppa;
