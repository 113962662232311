import "../App.css";
import covervideo from "../assets/images/AJEET DRONES.mp4";
import React, { useState, useEffect } from "react";
import "./Main.css";
import { useSpring, animated } from "react-spring";
import edgepic from "../assets/images/edge.png";
import drishyapic from "../assets/images/drishya.png";
import navgatipic from "../assets/images/navigati.png";



function Main() {
  const images = ["066.png", "077.png"]; // Replace with your image paths

  const [currentMain, setCurrentMain] = useState(0);

  const points = [
    { x: 400, y: 100 },
    { x: 700, y: 300 },
    { x: 400, y: 200 },
    { x: 100, y: 100 },
    { x: 400, y: 100 },
    { x: 300, y: 800 },
    { x: 400, y: 100 },
    { x: 700, y: 500 },
  ];

  const TEXTS = [
    "Unmanned Aerial Vehicle-UAV(Drones) \n Unmanned Ground Vehicle - UGV \n Unmanned Surface Vehicle \n Driverless Automobiles",
    "See Through Armour (STA) Defence Tanks \n Vision assist systems for Automobiles (ADAS) N 360 DEGREE VISUAL Drone Radar \n Obstacle avoidance system for Drones",
    "Vehicle Location Telematics Device \n Drone Tracking System \n Internet Of Things (IOT) Cold Chain Monitoring",
  ];
  const demoimages = [navgatipic, drishyapic, edgepic];

  const HEADINGS = [
    "NavGati Autopilot for Unmanned Systems",
    "Drishya \n Vision Guidance Systems",
    "EDGE \n Wireless Connectivity Gateway",
  ];

  const gradients = [
    "linear-gradient(to bottom right, white, #4b0082)", // Green to Dark Green
    "linear-gradient(to bottom right, #2196F3, #4b0082)", // Blue to Dark Blue
    "linear-gradient(to bottom right,white, #2196F3)", // Orange to Dark Orange
  ];

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setCurrentMain((prevMain) => (prevMain + 1) % images.length);
    }, 3000);
    // Adjust the delay (in milliseconds) between slides as needed

    return () => clearTimeout(intervalId);
  }, [currentMain, images.length]);

  //dronemoving
  const props = useSpring({
    from: { x: points[0].x, y: points[0].y },
    to: async (next) => {
      for (let i = 1; i < points.length; i++) {
        await next({ x: points[i].x, y: points[i].y });
      }
    },
    loop: { reverse: true },
    config: { duration: 4000 },
  });

  const TypingAnimation = () => {
    return (
      <div className="typing-animation">
        {TEXTS.map((text, Main) => (
          <div
            key={Main}
            className="colorful-box"
            style={{ backgroundImage: gradients[Main % gradients.length] }}
          >
            <h2 className="header-board">{HEADINGS[Main]}</h2>
            <br />
            <img src={demoimages[Main]} alt={`Image4 ${Main}`} />
            <br />
            <span className="text">
              {text.split("\n").map((line, Main) => (
                <div key={Main}>{line}</div>
              ))}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="main-page">
      <div className="my-0 py-2">
        <div className="news-ticker">
          <div
            className="ticker-content"
            style={{ height: "40px", marginTop: "12px" }}
          >
            <span>The Show Stopper @AIDEF 2022 Generates Eyeballs </span>
            <span>
              Changemakers: New Milestones for 'Make In India' in Defense sector
            </span>
            <span>Armed forces get ready for future warefare!! </span>
            <span>Ajeet Mini makes a sterling debut</span>
          </div>
        </div>

        <div className="text-center">
          <video autoPlay loop muted>
            <source src={covervideo} type="video/mp4" />
          </video>
        </div>
        <br />
        <div className="image-text-slider">
        <div className="text-slider">
          <div className="motherboards">
            <h3>GENERATION 5 MOTHERBOARDS MADE IN INDIA</h3>

            <p>
              Intels Computing Architectures like i3, i5, i7 or QUALCOMM's s4,
              s6, s7, s8 have revolutionised our lives by powering devices like
              laptops, Mobile phones
            </p>

            <p>
              Presenting ZUPPA's "made in india dpcc Computing Architectures"
              that is powering future ready devices for 5th Generation
              industries like Drones, Unmanned Systems, ADAS, IOT and Connected
              Mobility
            </p>
          </div>
        </div>

        <img
          className="image-slider-image"
          src={process.env.PUBLIC_URL + "/" + images[currentMain]}
          alt={`Slide ${currentMain + 1}`}
        />
      </div> 
 


        <div class="container mt-5">
          <div class="animated-title text-center">
            <div class="text-top">
              <div>
                <span>
                  <h2>Zuppa</h2>
                </span>
              </div>
            </div>
            <div class="text-bottom">
              <div>
                <h3>PRODUCTS</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="product-card-container">
          <br />
          <TypingAnimation />
        </div>

        <br></br>
        <br></br>
        <div className="blogs-container">
          <div className="blogs-heading">
            <h1 className="TitleTyping">BLOGS : News & Events...</h1> <br></br>
          </div>

          <div className="image-container d-flex justify-content-center">
            <div className="image-wrapper">
              <img src="blog2.jpg" alt="Image3" className="image m-0" />
              <div className="image-content">
                <h2>AH ! THE CHASM</h2>
                <br />
                <br />
                <br />
                <p>
                  Crossing the chasm and diffusion of techonology were prases i
                  heard of quite late in the diffusion of our proprietary
                  globally unique technology of "SYSTEM OF DISSEMINATED PARALLEL
                  CONTROL COMPUTING IN REAL TIME"
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src="saravanaraju.jpg" alt="Image2" className="image m-0" />
              <div className="image-content">
                <h2>The FASCINATING JOURNEY</h2>
                <p>
                  This is the journey of Saravana Raju who halls from a fishing
                  hamlet in chennai to being one among the experience drone
                  pilot today taining future drone pilots at CASR RPTO MIT .
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="news-ticker1">
          <div className="ticker-content1">
            <span>
              {" "}
              <img src="Zuppaclogo1.png" alt="scout"></img>
            </span>
            <span>
              {" "}
              <img src="Zuppaclogo2.png" alt="scout"></img>
            </span>
            <span>
              {" "}
              <img src="Zuppa c logo 3.png" alt="scout"></img>
            </span>
            <span>
              {" "}
              <img src="Zuppa c logo 4.png" alt="scout"></img>
            </span>
            <span>
              {" "}
              <img src="Zuppa c logo 5.png" alt="scout"></img>
            </span>
            <span>
              {" "}
              <img src="design.png" alt="scout"></img>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
