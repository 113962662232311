import React, { useEffect, useState } from "react";
import "./NewsAndEvents.css";
import { Fade, Zoom } from "react-awesome-reveal";
import {useNavigate} from "react-router-dom"
import conferance from "../../assets/images/conferance.png"
import venkataward from "../../assets/images/venkatAward.jpeg"

function NewsAndEvents() {
const navigate = useNavigate("")

const EyeBalls =()=>{
navigate("/Eyeballs")
}

const Defensesector =()=>{
  navigate("/Defensesector")
  }
  
const ArmedForce = ()=>{
  navigate("/Armedforces")
}

const AjeetMini = ()=>{
  navigate("/Ajeetmini")
}
useEffect(() => {
  const body = document.querySelector('#root'); 
    body.scrollIntoView({
        behavior: 'smooth'
    }, 500)
  
  }, []);
  return (
    <>
     
      
        <div className="text-center">
          <h6>Accolades</h6>
        </div>
        <Fade className="newsevent-container-one" duration={1900} direction="left">
          <div className="d-flex justify-content-between row align-items-center">
            <div className="col-lg-5 mb-lg-3">
              <img
                className="img-fluid newsevent-image"
                src="https://zuppa.io/product-img/Zuppas-Ajeet-Mini-%E2%80%93-the-Show-Stopper-@-AIDef-2022-Generates-Eyeballs.jpg"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <div className="newsevent-para-one">
               <Fade direction="left" duration={1800}>
              <Zoom>
              <h5>The Show Stopper @AIDef 2022 Generates Eyeballs</h5>
                <p>
                  The first-ever Artificial Intelligence in Defence (AIDef)
                  symposium and exhibition organised by the Ministry of Defence
                  in New Delhi last week showcased India's stride towards
                  achieving self-reliance in defence technologies{" "}
                </p>
                <p>
                  At the event, 75 defence products powered by artificial
                  intelligence were launched by the Defence Minister Rajnath
                  Singh as part of 'Azadi Ka Amrit Mahotsav' celebrations.
                   {" "}<span onClick={()=>EyeBalls()} style={{color:"DodgerBlue", cursor:"pointer"}}>see more...</span>
                </p>
              </Zoom>
               </Fade>
              </div>
            </div>
          </div>
        </Fade>

        <br />

        <Fade  className="newsevent-container-two" duration={1800} direction="right">
          <div className=" d-flex justify-content-between row align-items-center ">
            <div className="col-md-6">
              <div className="newsevent-para-two">
               <Fade direction="right" duration={1800}>
             <Zoom>
             <h5>
                  <b>Changemakers</b>
                </h5>
                <p>
                  Changemakers: New Milestones for 'Make In India' in Defense
                  sector
                </p>
                <p>Patent Applied for Unique MADE IN INDIA Artificial Intelligence (AI) Technology.
                {" "}<span onClick={()=>Defensesector()} style={{color:"DodgerBlue", cursor:"pointer"}}>see more...</span>
                
                </p>
             </Zoom>
               </Fade>
              </div>
            </div>
            <div className="col-lg-5 mb-lg-3">
              <img
                className="img-fluid newsevent-image"
                src="https://zuppa.io/product-img/Changemakers%20New%20Milestones%20for%20%20Make%20In%20India%20in%20Defense%20sector.jpg"
                alt=""
              />
            </div>
          </div>
        </Fade>

        <Fade  className="newsevent-container-three" duration={2000} direction="up">
          <div className=" d-flex justify-content-between  row align-items-center ">
            <div className="col-lg-5 mb-lg-3">
              <img
                className="img-fluid newsevent-image"
                src="https://zuppa.io/product-img/Armed%20forces%20get%20ready%20for%20future%20warfare!.jpg"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <div className="newsevent-para-three" style={{marginTop:"10px"}}>
               <Fade direction="down" duration={3000}>
               <h5>Armed forces get ready for future warfare!!</h5>
                <p>
                  <b>Defence Minister Rajnath Singh </b>said on Monday that
                  India needs to work on artificial intelligence mechanism
                  "extremely carefully".{" "}
                </p>
                <p>
                  Inaugurated the first-ever Artificial Intelligence in Defence
                  (AIDef) symposium and exhibition of AI-enabled solutions, the
                  Defence minister said that drawing lessons from the ongoing
                  Russia-Ukraine war, the Indian Armed Forces are pushing for
                  adoption of new technol gies.{" "}
                  {" "}<span onClick={()=>ArmedForce()} style={{color:"DodgerBlue", cursor:"pointer"}}>see more...</span>
                
                </p>
               </Fade>
              </div>
            </div>
          </div>
        </Fade>

        <Fade  className="newsevent-container-four" duration={1900} direction="down">
          <div className=" d-flex justify-content-between row align-items-center ">
          
              <div className="newsevent-para-four col-md-6">
           <Fade direction="up">
           <h5>
                  {" "}
                  Ajeet Mini makes a sterling debut at Bharat Drone Mahotsav
                  2022
                </h5>
                <p>
                  Ajeet Mini India’s Aatmnirbhar answer to global brands like
                  DJI, Parot, Skydio, Autel and the likes was unveiled by our
                  Chief Innovation Officer Venkatesh Sai at the Bharat Drone
                  Mahotsav 2022 on 27/5/22 to the Honourable{" "}
                  <b>Prime Minister Narendra Modi </b> and Minister of Civil
                 <b> Aviation Jyotiraditya Scindia at Pragati Maidan.</b>
                 </p>
                  {" "}<span onClick={()=>AjeetMini()} style={{color:"DodgerBlue", cursor:"pointer"}}>see more...</span>
             
           </Fade>
              </div>

              <div className="col-lg-5 mb-lg-1">
                <img
                  className="img-fluid newsevent-image"
                  src="https://zuppa.io/product-img/Ajeet%20Mini%20makes%20a%20sterling%20debut%20at%20Bharat%20Drone%20Mahotsav%202022.jpg"
                  alt=""
                />
              </div>
          
          </div>
        </Fade>
        <Fade className="newsevent-container-five" duration={1900} direction="left">
          <div className=" d-flex justify-content-between row align-items-center ">
            <div className="col-lg-5 mb-lg-3">
              <img
                className="img-fluid newsevent-image"
                src={conferance}
                alt=""
              />
            </div>
            <div className="col-md-6">
              <div className="newsevent-para-five">
               
          <Fade direction="left">
          <p>
               <Zoom duration={4000}> We at Zuppa always believe in pushing the envelope with indegenious drones and electronic components. We were honoured to host Manik Anandh , MoD and multiple indegenious drone manufacturers and drone component manufacturers based in and around Chennai and facilitate a great interaction .</Zoom>
        
                <a href="https://www.linkedin.com/posts/zuppa-drones_we-at-zuppa-always-believe-in-pushing-the-activity-7195433650795028480-Kxh0/?utm_source=share&utm_medium=member_desktop"target="_blank" style={{textDecoration:"none"}}>See More...</a>  
                </p>
          </Fade>
                   
              </div>
            </div>
          </div>
        </Fade>
{/* --------------------------------------zuppa awards winner 2024---------------------------------------------------- */}

  
<Fade  className="newsevent-container-six" duration={2000} direction="left">
      <div className=" d-flex justify-content-between row align-items-center ">
      <div className="col-lg-5 mb-lg-3">
            <img
              className="img-fluid newsevent-image"
              src={venkataward}
              alt=""
            />
          </div>
           <div className="col-md-6">
           <div className="newsevent-para-six">
              <h5>India’s oldest electronics industry body Electronic Industries Association of India-ELCINA recognized the Technical Director of Zuppa Geo Navigation Technologies Pvt LTD        by way of their prestigiousdefence technology awards winner 2024. </h5>
            <Fade direction="right" duration={1000}>
           
            </Fade>
        
              <a href="https://www.linkedin.com/posts/sai-pattabiram-299a843b_perseverance-dedication-commitment-ugcPost-7220355070377222144-oGeI?utm_source=share&utm_medium=member_desktop"target="_blank" style={{textDecoration:"none"}}>See More...</a>  
              
          
            </div>
           </div>
      </div>
        
        </Fade>








        <Fade  className="newsevent-container-six" duration={2000} direction="left">
      <div className=" d-flex justify-content-between row align-items-center ">
      <div className="col-lg-5 mb-lg-3">
            <img
              className="img-fluid newsevent-image"
              src="https://zuppa.io/product-img/AS9100Dcertificate.jpg"
              alt=""
            />
          </div>
           <div className="col-md-6">
           <div className="newsevent-para-six">
              <h5>AS9100D and ISO9001 certification</h5>
            <Fade direction="right" duration={1000}>
            <p>
                Zuppa got certified by TUVSUD to the American aerospace standard
                with AS9100D and ISO 9001.{" "}
              </p>
            </Fade>
          <Zoom duration={1200}>    <p>
                AS9100D certification as everyone knows makes Zuppa an
                organization that has the quality management in place that is
                standardized for international aerospace industry and supply
                chain.{" "}
              </p></Zoom>
            </div>
           </div>
      </div>
        
        </Fade>

        <Fade  className="newsevent-container-seven" duration={1900} direction="right">
          <div className=" d-flex justify-content-between row align-items-center ">
            <div className="col-md-6">
              <div className="newsevent-para-seven">
              <h5> DGCA certificate</h5>
             <Fade direction="right">
             <p>
                {" "}
                Another Certification in the bag for Zuppa. Zuppa's Ajeet
                Mini/Trainer gets it Directorate General of Civil Aviation,
                India (DGCA) type certification.
              </p>
              <p>
                These certificates affirm that a manufacturer is taking the
                necessary steps to ensure its products comply with general
                aviation regualtions and certifies it to international standards
                and gives us an oppurtunity to sell national
              </p>
             </Fade>
            </div>
            </div>
         <div className="col-lg-3 mb-lg-3">
         <img
              className="img-fluid newsevent-image"
              src="https://zuppa.io/product-img/DGCAcertificate.jpg"
              alt=""
            />
         </div>
          </div>
        </Fade>

     
     
    </>
  );
}

export default NewsAndEvents;
