
import "./CareerForm.css";
import TextField from "@mui/material/TextField";
import Header from "../components/header";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CareerForm = () => {
  const [resumeFile, setResumeFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  
  const careerValidationSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    contactNumber: yup.number().required().min(10),
    education: yup.string().required(),
    resume: yup.mixed().required(),
    portfolio: yup.string().required().url(),
    location: yup.string().required(),
    passOut: yup.number().required().min(4),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      education: "",
      resume: "",
      portfolio: "",
      location: "",
      passOut: "",
    },
    validationSchema: careerValidationSchema,
    onSubmit: async (values) => {
      setLoading(true); // Show the loading backdrop
      const formData = new FormData();
      formData.append("resume", resumeFile);
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("contactNumber", values.contactNumber);
      formData.append("education", values.education);
      formData.append("portfolio", values.portfolio);
      formData.append("location", values.location);
      formData.append("passOut", values.passOut);
      axios
        .post(`https://shop.zuppa.io:4000/careerform`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false); // Hide the loading backdrop
          setOpen(true); // Open the dialog
        })
        .catch((error) => {
          setLoading(false); // Hide the loading backdrop
          console.log(error);
        });
    },
  });

  const handleResumeChange = (e) => {
    setResumeFile(e.target.files[0]);
    formik.setFieldValue("resume", e.target.files[0].name);
  };

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate("/"); // Navigate to home after closing the dialog
  };

  return (
    <>
      <Header />
      <br />
      <div className="careerForm-container">
        <Toaster position="top-right" reverseOrder={false} />
        <h4>Fill the Form</h4>

        <form className="careerForm-form" onSubmit={formik.handleSubmit}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            placeholder="Enter course name"
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
            helperText={
              formik.touched.name && formik.errors.name ? formik.errors.name : null
            }
          />

          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            placeholder="Enter Your Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            helperText={
              formik.touched.email && formik.errors.email ? formik.errors.email : null
            }
          />

          <TextField
            id="outlined-basic"
            label="Contact Number"
            variant="outlined"
            placeholder="Enter Your Contact Number"
            value={formik.values.contactNumber}
            onChange={formik.handleChange}
            name="contactNumber"
            onBlur={formik.handleBlur}
            error={formik.touched.contactNumber && formik.errors.contactNumber}
            helperText={
              formik.touched.contactNumber && formik.errors.contactNumber
                ? formik.errors.contactNumber
                : null
            }
          />

          <TextField
            id="outlined-basic"
            label="Education"
            variant="outlined"
            placeholder="Enter Your Qualification"
            value={formik.values.education}
            onChange={formik.handleChange}
            name="education"
            onBlur={formik.handleBlur}
            error={formik.touched.education && formik.errors.education}
            helperText={
              formik.touched.education && formik.errors.education
                ? formik.errors.education
                : null
            }
          />

          <label htmlFor="resume">Upload Resume:</label>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleResumeChange}
            accept=".pdf,.doc,.docx"
          />
          {formik.touched.resume && formik.errors.resume ? (
            <div className="error">{formik.errors.resume}</div>
          ) : null}

          <TextField
            id="outlined-basic"
            label="Updated Portfolio Link"
            variant="outlined"
            placeholder="Enter Your portfolio Link"
            value={formik.values.portfolio}
            onChange={formik.handleChange}
            name="portfolio"
            onBlur={formik.handleBlur}
            error={formik.touched.portfolio && formik.errors.portfolio}
            helperText={
              formik.touched.portfolio && formik.errors.portfolio
                ? formik.errors.portfolio
                : null
            }
          />

          <TextField
            id="outlined-basic"
            label="Current Location"
            variant="outlined"
            placeholder="Enter Your Location"
            value={formik.values.location}
            onChange={formik.handleChange}
            name="location"
            onBlur={formik.handleBlur}
            error={formik.touched.location && formik.errors.location}
            helperText={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : null
            }
          />

          <TextField
            id="outlined-basic"
            label="Year of passing "
            variant="outlined"
            placeholder="Year Of passOut"
            value={formik.values.passOut}
            onChange={formik.handleChange}
            name="passOut"
            onBlur={formik.handleBlur}
            error={formik.touched.passOut && formik.errors.passOut}
            helperText={
              formik.touched.passOut && formik.errors.passOut
                ? formik.errors.passOut
                : null
            }
          />

          <Button className="button-careerForm" type="submit" variant="contained">
            Submit
          </Button>
        </form>
      </div>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <h4>Your Form is Submitted Successfully</h4>
          <h5>We Will Get Back Soon 
            <img style={{height:"50px"}} src="https://i.pinimg.com/originals/e7/b3/0e/e7b30ec77c2dfc0b6fdbb421cf7baba2.gif"/>  
          </h5>
          <Button onClick={handleClose} variant="contained" color="success">
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CareerForm;
