import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./ContactPopup.css";

const ContactPopup = ({ show, onClose }) => {
  const formik = useFormik({
    initialValues: {
      username: '',
      emailid: '',
      phoneNumber: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      emailid: Yup.string().email('Invalid email address').required('Required'),
      phoneNumber: Yup.string().required('Required')
    }),
    onSubmit: (values) => {
      console.log(values, "Form values");
      fetch('https://shop.zuppa.io:4000/api/dronelabcontact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      })
      .then(response => response.text())
      .then(() => {
        downloadPDF();
        alert('Successfully Done');
        onClose();
      })
      .catch(error => console.error('Error:', error));
    }
  });

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/zuppa.pdf`; // Accessing from the public folder
    link.download = 'zuppa.pdf'; // This should be the name you want the file to have when downloaded
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link); 
  };

  if (!show) return null;

  return (
    <div className="popup-overlay1">
      <div className="popup-content1">
        <h2 className='text-center'>Contact Us</h2>
        <form onSubmit={formik.handleSubmit}>
          <label>
            Name:
            <input 
              type="text" 
              name="username" 
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur}
              value={formik.values.username} 
              className={formik.touched.username && formik.errors.username ? 'input-error' : ''}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="error">{formik.errors.username}</div>
            ) : null}
          </label>
          <label>
            Email ID:
            <input 
              type="email" 
              name="emailid" 
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur}
              value={formik.values.emailid} 
              className={formik.touched.emailid && formik.errors.emailid ? 'input-error' : ''}
            />
            {formik.touched.emailid && formik.errors.emailid ? (
              <div className="error">{formik.errors.emailid}</div>
            ) : null}
          </label>
          <label>
            Phone Number:
            <input 
              type="tel" 
              name="phoneNumber" 
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber} 
              className={formik.touched.phoneNumber && formik.errors.phoneNumber ? 'input-error' : ''}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </label>
          <button type="submit">Submit</button>
          <br/>
          <button onClick={onClose} className="close-button1 bg-danger">Close</button>
        </form>
      </div>
    </div>
  );
}

export default ContactPopup;
