import React, { useEffect } from 'react';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.css';
import Header from './components/header';
import Footer from './components/footer';
import Main from './pages/Main';
import Home from './pages/Home';


function App() {
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);
  return (
  <nav>
  <div>
   <Header/>

   {/* <Main/> */}

 <Home/>
   <Footer/>

  </div>
  </nav>


  );
}
export default App;




  