// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animated-3d {
  background-color: #ffffff; /* White background for visibility */
  padding: 20px; /* Optional padding for spacing */
  border-radius: 10px; /* Rounded corners */
  transition: all 0.4s ease-in-out; /* Smooth transition for hover effects */
  box-shadow: 
    0px 5px 15px rgba(0, 0, 0, 0.2), /* Base shadow */
    0px 10px 20px rgba(0, 0, 0, 0.15), /* Middle shadow */
    0px 15px 30px rgba(0, 0, 0, 0.1); /* Light outer shadow */
}

/* Hover effect to enhance the 3D feel */
.animated-3d:hover {
  transform: translateY(-10px); /* Slight lift on hover */
  box-shadow: 
    0px 8px 20px rgba(0, 0, 0, 0.3), /* Enhanced shadow */
    0px 15px 25px rgba(0, 0, 0, 0.2), /* Middle layer */
    0px 20px 40px rgba(0, 0, 0, 0.15); /* Further extended outer shadow */
}

  .Chasm{
    text-align: justify;
    padding: 40px;
    gap: 10px;
    font-family: 'Times New Roman', Times, serif;
 font-weight: 600;
 font-size: 1.1rem;
  }
  .imageTwo{
    background-color: rgb(255, 255, 255);
height: 330px;
box-shadow: 
0px 5px 15px rgba(0, 0, 0, 0.2), /* Base shadow */
0px 10px 20px rgba(0, 0, 0, 0.15), /* Middle shadow */
0px 15px 30px rgba(0, 0, 0, 0.1); /* Light outer shadow */
border-radius: 10px; 
  
  }`, "",{"version":3,"sources":["webpack://./src/pages/Thechasm.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,oCAAoC;EAC/D,aAAa,EAAE,iCAAiC;EAChD,mBAAmB,EAAE,oBAAoB;EACzC,gCAAgC,EAAE,wCAAwC;EAC1E;;;oCAGkC,EAAE,uBAAuB;AAC7D;;AAEA,wCAAwC;AACxC;EACE,4BAA4B,EAAE,yBAAyB;EACvD;;;qCAGmC,EAAE,kCAAkC;AACzE;;EAEE;IACE,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,4CAA4C;CAC/C,gBAAgB;CAChB,iBAAiB;EAChB;EACA;IACE,oCAAoC;AACxC,aAAa;AACb;;;gCAGgC,EAAE,uBAAuB;AACzD,mBAAmB;;EAEjB","sourcesContent":[".animated-3d {\r\n  background-color: #ffffff; /* White background for visibility */\r\n  padding: 20px; /* Optional padding for spacing */\r\n  border-radius: 10px; /* Rounded corners */\r\n  transition: all 0.4s ease-in-out; /* Smooth transition for hover effects */\r\n  box-shadow: \r\n    0px 5px 15px rgba(0, 0, 0, 0.2), /* Base shadow */\r\n    0px 10px 20px rgba(0, 0, 0, 0.15), /* Middle shadow */\r\n    0px 15px 30px rgba(0, 0, 0, 0.1); /* Light outer shadow */\r\n}\r\n\r\n/* Hover effect to enhance the 3D feel */\r\n.animated-3d:hover {\r\n  transform: translateY(-10px); /* Slight lift on hover */\r\n  box-shadow: \r\n    0px 8px 20px rgba(0, 0, 0, 0.3), /* Enhanced shadow */\r\n    0px 15px 25px rgba(0, 0, 0, 0.2), /* Middle layer */\r\n    0px 20px 40px rgba(0, 0, 0, 0.15); /* Further extended outer shadow */\r\n}\r\n\r\n  .Chasm{\r\n    text-align: justify;\r\n    padding: 40px;\r\n    gap: 10px;\r\n    font-family: 'Times New Roman', Times, serif;\r\n font-weight: 600;\r\n font-size: 1.1rem;\r\n  }\r\n  .imageTwo{\r\n    background-color: rgb(255, 255, 255);\r\nheight: 330px;\r\nbox-shadow: \r\n0px 5px 15px rgba(0, 0, 0, 0.2), /* Base shadow */\r\n0px 10px 20px rgba(0, 0, 0, 0.15), /* Middle shadow */\r\n0px 15px 30px rgba(0, 0, 0, 0.1); /* Light outer shadow */\r\nborder-radius: 10px; \r\n  \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
