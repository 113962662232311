// Defense.js
import React from "react";
import { Carousel } from "react-bootstrap";
import './Defense.css'; // Import the CSS file for styling

const Defense = () => {
  return (
    <>

    <div className="defense-container">
      <h3 className="about-header" style={{ marginTop: "-30px" }}>Changemakers</h3>
      <br />
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <p className="defencePara">Changemakers: New Milestones for 'Make In India' in the Defense sector</p>
          <p className="defencePara">Patent Applied for Unique MADE IN INDIA Artificial Intelligence (AI) Technology.</p>
        </div>
        <div className="col-lg-6 col-md-12">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src="https://i0.wp.com/bharatflux.com/wp-content/uploads/2022/08/Indian-navy-vikrant.jpg?fit=720%2C594&ssl=1&resize=350%2C200"
                alt="Image 1"
              />
            
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src="https://news.bbcimg.co.uk/media/images/51329000/jpg/_51329339_qecarriersaca.jpg"
                alt="Image 2"
              />
             
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyIVBI7xwSETXtfvypAxRkZbtk4eFDJVm1w0nJjXTYQ_oU9EAp_5V4b4WbexygzRBmWG8&usqp=CAU"
                alt="Image 3"
              />
          
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6HkLE3wMzRa_mJddIbzP_sgLvpja8A0T7sw&s"
                alt="Image 4"
              />
             
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

 
    </div>

     {/* Video Section */}
     <div className="video-container" style={{margin:"10px auto"}}>
        <video className="responsive-video" controls style={{minWidth:"100%", margin:"0px"}}>
          <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725518727/xq64xossyxapzh0vp3ui.mp4" type="video/mp4" style={{minWidth:"100%", margin:"0px"}}/>
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default Defense;
